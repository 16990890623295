import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Github from '../scripts/Github';

class GitCommit extends Component {

	constructor(props) {
		super(props);

		this.state = {
			artifact: props.artifact
		};
	}

	render() {
		if (this.state.artifact.github_commit) {
			return (
					<div>
						<h5 className='github-artifact-text'>Repository</h5>
						<p> {this.state.artifact.github_commit_repo ? this.state.artifact.github_commit_repo.name : '-'}</p>
						<h5 className='github-artifact-text'>Commit Message</h5>
						<p> {this.state.artifact.github_commit.commit.message}</p> 
						<h5 className='github-artifact-text'>Author</h5>
						<p>{this.state.artifact.github_commit.author ? this.state.artifact.github_commit.author.login : 'No Author' }</p>
						<div> <a href={this.state.artifact.github_commit.html_url}> 
								See more on Github  
							  </a>
						</div>
					</div>
			);
		} else {
			return <div> AN OLD COMMIT </div>;
		}
	}
}


export default GitCommit;
