import React, { Component } from 'react';
import Moment from 'react-moment';
import Database from '../scripts/Database';
import { Amplitude } from '@amplitude/react-amplitude';

import Comment from '../components/Comment';
import TextEditor from '../components/TextEditor';
import { UserContext } from '../context/UserContext';
import { generatePlainTextFromRichText } from '../scripts/Utilities';


class CommentsBlock extends Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);

		this.state = {
			comments: (this.props.artifact.feedback || []).sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1)),
			editorPermissions: false,
			editorKey: 0
		};
	}

	componentDidMount() {
		this.setState({
			editorPermissions: true
		});
	}

	commentSubmitFunction = (logEvent) => {
		const { user } = this.context;
		var text = generatePlainTextFromRichText(this.state.richtextFeedback);
		var timenow = Date.now();
		var feedback_element = {
			authorID: user.id,
			authorName: user.name,
			createdAt: timenow,
			text: text,
			richtextFeedback: this.state.richtextFeedback
		};
		if (text.length > 0) {
			logEvent('add feedback comment', {
				comment_character_length: text.length,
				comment_word_length: text.split(' ').length
			});
			
			Database.addValuesToArrayInDocument(
				'artifacts',
				this.props.artifact.id,
				[feedback_element], 'feedback',
				(dr, context) => {
					this.commentSubmitCallback(feedback_element, context);
				},
				this
			);
		}
	};

	commentSubmitCallback = (data, context) => {
		console.log('comment submitted to db', data);
		// clear local storage of rich text
		sessionStorage.setItem("draftail:content", 'null');
		//it is important that you set state after the sessionstorage clear
		context.setState({
			comments: [ ...this.state.comments, data ],
			editorKey: this.state.editorKey + 1
		});
	};

	render() {
		return (
			<Amplitude
				eventProperties={(inheritedProps) => ({
					...inheritedProps,
					scope: [ ...inheritedProps.scope, 'comments' ],
					total_comments: this.state.comments ? this.state.comments.length : 0
				})}
			>
				{({ logEvent }) => (
					<>
						{this.state.comments.map((c) => {
							return <Comment comment={c} />;
						})}
						<TextEditor 
							key={this.state.editorKey.toString()}
							id='comment-box'
							initialText= ''
							readOnly = {false} // should only be able to edit if it's your comment.
							onChange={(content) => {
								this.setState({ 
									richtextFeedback: content, 
									canUpload: content !== 'null'
								});
							}}
						/>
						<button onClick={() => this.commentSubmitFunction(logEvent)}> Submit Feedback </button>
					</>
				)}
			</Amplitude>
		);
	}
}

CommentsBlock.propTypes = {
	artifact: PropTypes.object.isRequired
};

export default CommentsBlock;
