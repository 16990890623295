const ArtifactTypes = Object.freeze({
	COMMIT: { value: 'commit', label: 'Github Commit' },
	IMAGE: { value: 'image', label: 'Image' },
	TEXT: { value: 'text', label: 'Text Snippet' },
	VIDEO: { value: 'video', label: 'Youtube Link' },
	REPORT: { value: 'report', label: 'Check-in Artifact' },
	LINK: { value: 'link', label: 'Link'},
	FIGMA: { value: 'figma', label: 'Figma Link'}
});


export default ArtifactTypes;
