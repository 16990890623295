import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Database from '../scripts/Database';
import * as firebase from 'firebase';
import Github from '../scripts/Github';
import amplitude from 'amplitude-js';
import { AmplitudeProvider, Amplitude } from '@amplitude/react-amplitude';
import AMPLITUDE_KEY from '../../amplitude/index';
import kaleidoscope from '../../public/images/kaleidoscope.gif';
import Loading from '../components/Loading';
import StackGrid from 'react-stack-grid';
import TextArtifact from '../components/TextArtifact';
import portfolioMap from '../../public/portfolioMap';

class Showcase extends Component {
	constructor(props) {
		super(props);
		this.state = {
			shouldRedirect: false,
			submittedPRCheckInArtifacts: [],
			PRArtifactsMap: {},
			isDataLoaded: false,
		};
	}

	componentDidMount() {
		firebase.auth().onAuthStateChanged((firebaseUser) => {
			if (firebaseUser) {
				var isBerkeleyEmail = validBerkeleyEmailPattern.test(firebaseUser.email);
				//var isBerkeleyEmail = true; // test only
				if (isBerkeleyEmail) {
					this.checkIfNewUser(firebaseUser);
				} else {
					//logged in but NOT a valid user of our system.
					firebaseUser
						.delete()
						.then(function () {
							// User deleted.
							console.log('delete non-Berkeley user account.');
						})
						.catch(function (error) {
							// An error happened.
						});
				}
			} else {
				console.log('Index firebase user is null');
			}
		});
		var artifactIDsToRetrieve = [];
		Database.getsubmittedPRCheckInArtifacts((submittedPRCheckInArtifacts, context) => {
			submittedPRCheckInArtifacts.map((PRArtifact) =>
				PRArtifact.artifactEntries.map((artifactEntry) => artifactIDsToRetrieve.push(artifactEntry.artifactID))
			);
			Database.getDocuments(
				'artifacts',
				artifactIDsToRetrieve,
				(artifacts, context) => {
					var PRArtifactsMap = artifacts.reduce(function (map, artifact) {
						map[artifact.id] = artifact;
						return map;
					}, {});
					context.setState({ submittedPRCheckInArtifacts, PRArtifactsMap, isDataLoaded: true });
				},
				this
			);
		}, this);
	}

	checkIfNewUser(firebaseUser) {
		Database.getUserData(
			firebaseUser.uid,
			(userResponse, context) => {
				var user = userResponse;
				// if user doesn't exist in OUR database
				if (!user || !user.data) {
					// Create user object
					user = {
						id: firebaseUser.uid,
						name: firebaseUser.displayName,
						imageURL: this.saveBaseImageURL(firebaseUser.photoURL),
						email: firebaseUser.email,
						timeCreated: new Date(),
						role: 'student',
						bytesUploaded: 0,
					};
					// Create entry for user in Database
					Database.addDocument(
						'teams',
						{
							name: `${user.name}'s Personal Studio`,
							contributors: [firebaseUser.uid],
							timeCreated: new Date(),
						},
						(teamData, context) => {
							Database.addDocumentWithCustomID(
								'users',
								firebaseUser.uid,
								{
									...user,
									teams: [teamData.id],
								},
								(result, context) => {
									// maybe set shouldRedirect to true here, instead.
									context.props.history.push('/studio');
								},
								context
							);
						},
						context
					);
				} else {
					// if user already exists in database
					console.log('user already exists! redirect to studio');
					// maybe set shouldRedirect to true here, instead.
					//context.props.history.push('/studio');
					// already logged in AND are a user of our system.
					// Explicitly set flag to redirect user to a logged-in experienece
					this.setState({ shouldRedirect: true });
				}
			},
			this
		);
	}

	saveBaseImageURL(imageURL) {
		return imageURL.split('=')[0];
	}

	handleSignInClick = (logEvent) => {
		var provider = new firebase.auth.GoogleAuthProvider();
		firebase
			.auth()
			.signInWithPopup(provider)
			.then((result) => {
				// The signed-in user info.
				console.log('successfully signed in to firebase');
				if (result && result.user && result.user.uid) {
					logEvent('sign in', { user_id: result.user.uid });
				}
			})
			.catch(function (error) {
				// Handle Errors here.
				var errorCode = error.code;
				var errorMessage = error.message;
				// The email of the user's account used.
				var email = error.email;
				// The firebase.auth.AuthCredential type that was used.
				var credential = error.credential;
				console.log(errorCode);
				console.log(errorMessage);
			});
	};

	parseTitleOrTaglineText(textArtifact) {
		console.log(textArtifact); // For debugging in case issues
		var text = textArtifact.text;
		if (text == '') {
			text = JSON.parse(textArtifact.richtext)
				.blocks.map((block) => block.text)
				.join(' ');
		}
		return text;
	}

	getTeamNumberText(PRArtifactIDs) {
		return this.state.PRArtifactsMap[PRArtifactIDs[0]].text;
	}

	getTeamMembersText(PRArtifactIDs) {
		return JSON.parse(this.state.PRArtifactsMap[PRArtifactIDs[1]].richtext).blocks.map((richTextObj) => richTextObj.text);
	}

	getTitleText(PRArtifactIDs) {
		return this.parseTitleOrTaglineText(this.state.PRArtifactsMap[PRArtifactIDs[2]]);
	}

	getTaglineText(PRArtifactIDs) {
		return this.parseTitleOrTaglineText(this.state.PRArtifactsMap[PRArtifactIDs[3]]);
	}

	getDescription(PRArtifactIDs) {
		return this.state.PRArtifactsMap[PRArtifactIDs[4]];
	}

	getImage(PRArtifactIDs) {
		return this.state.PRArtifactsMap[PRArtifactIDs[5]];
	}

	renderCard(PRArtifact) {
		const PRArtifactIDs = PRArtifact.artifactEntries.map((artifactEntry) => artifactEntry.artifactID);
		console.log(PRArtifact);
		console.log(PRArtifactIDs);
		return (
			<Link to={portfolioMap[PRArtifact.id] ? `/portfolio/${portfolioMap[PRArtifact.id]}` : '/showcase'}>
				<div className='card'>
					<div className='card-content'>
						<h3>{this.getTitleText(PRArtifactIDs)}</h3>
						<h6>{this.getTaglineText(PRArtifactIDs)}</h6>
						<div className='showcase-info'>
							<img
								className='image featured'
								src={this.getImage(PRArtifactIDs).url}
								alt={this.getImage(PRArtifactIDs).annotations}
							/>
							<div className='showcase-info-text'>
								<TextArtifact artifact={this.getDescription(PRArtifactIDs)} readOnly logEvent={() => null} />
								<div>
									<h5>{`Team ${this.getTeamNumberText(PRArtifactIDs)}`}</h5>
									<p>{this.getTeamMembersText(PRArtifactIDs).join(', ')}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Link>
		);
	}

	render() {
		return (
			<div>
				<div className='signin-content'>
					<Link to='/' className='no-border'>
						<div className='logo-title'>
							<img className='image landing' src={kaleidoscope} />
							<div className='title-subtitle'>
								<h2>CS 160 Showcase</h2>
								<h6>powered by Kaleidoscope</h6>
							</div>
						</div>
					</Link>
					<button id='go to studio' onClick={() => this.props.history.push('/studio')}>
						Go to Studio ⟩
					</button>
				</div>
				{this.state.isDataLoaded && (
					<div className='showcase'>
						{this.state.submittedPRCheckInArtifacts.map((PRArtifact) => this.renderCard(PRArtifact))}
					</div>
				)}
			</div>
		);
	}
}

export default Showcase;
