import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Checkbox extends Component {
	constructor(props) {
		super(props);
		this.state = { checked: this.props.checkedByDefault };
	}

	handleChange = () => {
		const isChecked = !this.props.checked;
		this.setState({ checked: isChecked });
		this.props.onChange(this.props.id, isChecked); // calls the one that was passed in.
	};

	render() {
		return (
			<div className='checkbox'>
				<input type='checkbox' checked={this.props.checked} onChange={this.handleChange} />
				<p>{this.props.label}</p>
			</div>
		);
	}
}

Checkbox.propTypes = {
	checkedByDefault: PropTypes.bool.isRequired,
	id: PropTypes.string.isRequired,
	checked: PropTypes.bool.isRequired,
	label: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired
};
export default Checkbox;
