import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import ArtifactContent from '../components/ArtifactContent';
import Tag from '../components/Tag';
import Database from '../scripts/Database';

class Report extends Component {
	constructor(props) {
		super(props);
		this.artifactCallback = this.artifactCallback.bind(this);
		this.state = {
			artifacts: new Array(this.props.report.artifactEntries.length)
		};
	}

	componentDidMount() {
		this.props.report.artifactEntries.map((entry) => {
			Database.getDocument(
				'artifacts',
				entry.artifactID,
				(d) => {
					this.artifactCallback(d, entry.index, entry.artifactID);
				},
				this
			);
		});
	}

	artifactCallback(artifact, index, id) {
		if (artifact===null) {
			console.log("null artifact, returning from this callback in Reports.");
			return;
		}
		var newEntries = this.state.artifacts.slice();
		artifact.id = id;
		newEntries[index] = artifact;
		this.setState({
			artifacts: newEntries
		});
	}

	artifactClick(entry) {
		console.log(entry.id);
		this.props.history.push('/artifact/' + entry.id);
	}

	render() {
		return (
			<>
				{this.state.artifacts && this.state.artifacts.map((entry) => {
					return (
						<>
							<ArtifactContent 
								key={'report-'+ entry.id}
								artifact={entry} 
								hideMetadata={this.props.hideMetadata}
								hideEditBar={this.props.hideEditBar}/>
							<div className='subTags' key={'report-tags-'+entry.id}>
								{!!entry.tags && entry.tags.map((tagName) => {
									if (tagName.slice(0, 11) == 'requirement') {
										return <Tag key={'report-tag-' + tagName} tag={tagName} onClick={null} selected={false} />
									}
								})}
							</div>
						</>
					);
				})}
			</>
		);
	}
}

Report.propTypes = {
	report: PropTypes.object.isRequired,
	hideMetadata: PropTypes.bool,
	hideEditBar: PropTypes.bool
};

export default withRouter(Report);
