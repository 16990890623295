import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ArrowDropDown } from '@material-ui/icons';

import Select, { components } from 'react-select';

class Dropdown extends Component {
	onChange = (selectedOption) => {
		this.props.onChange(selectedOption);
	};

	getFormattedOptions(options) {
		if (options && options.length) {
			return options.map((option) => {
				return this.getFormattedOption(option);
			});
		} else {
			return [];
		}
	}

	getFormattedOption(option) {
		if (typeof option === 'string') {
			return { label: option, value: option };
		} else {
			return option;
		}
	}

	render() {
		const formattedOptions = this.getFormattedOptions(this.props.options);
		return (
			<div className='dropdown-select'>
				<Select
					autosize={true}
					components={{
						DropdownIndicator: DropdownIndicator,
						IndicatorSeparator: () => null
					}}
					defaultValue={
						this.getFormattedOption(this.props.defaultValue) ||
						(formattedOptions.length && formattedOptions[0]) ||
						null
					}
					isSearchable={this.props.isSearchable}
					menuPortalTarget={this.props.menuPortalTarget}
					onChange={this.onChange}
					options={formattedOptions}
					styles={selectStyles}
					isDisabled={this.props.isDisabled}
					theme={{
						controlHeight: '2.3em',
						borderRadius: 0
					}}
				/>
			</div>
		);
	}
}
const DropdownIndicator = (props) => {
	return (
		<components.DropdownIndicator {...props}>
			<ArrowDropDown />
		</components.DropdownIndicator>
	);
};

const selectStyles = {
	control: (base) => ({
		...base,
		alignContent: 'center',
		borderColor: 'rgba(160, 160, 160, 0.3)',
		minHeight: 0,

		'&:focus': {
			border: 0,
			alignContent: 'center'
		},
		'&:hover': {
			borderColor: 'rgba(160, 160, 160, 0.3)'
		}
	}),
	dropdownIndicator: (base) => ({ ...base, padding: 0, paddingRight: '0.25em' }),
	menu: (base) => ({
		...base,
		marginTop: '0.25em'
	})
};

Dropdown.propTypes = {
	options: PropTypes.arrayOf(PropTypes.string).isRequired,
	defaultValue: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	menuPortalTarget: PropTypes.node,
	isDisabled: PropTypes.bool,
	isSearchable: PropTypes.bool
};

export default Dropdown;
