import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { ChatBubble, Launch } from '@material-ui/icons';
import { Amplitude } from '@amplitude/react-amplitude';
import TextEditor from '../components/TextEditor';
import InputText from '../components/InputText';

import GitCommit from '../components/GitCommit';
import SelectButton from '../components/SelectButton';
import ArtifactContent from '../components/ArtifactContent';
import { isFauxArtifact } from '../scripts/Utilities';

class Pane extends Component {
	constructor(props) {
		super(props);

		// can be used by callbacks e.g. in Upload modal
		this.state = {
			selected: false || this.props.selected,
			isSidebarHovered: false
		};
	}

	onSidebarHover = (isMouseInside) => {
		this.setState({
			isSidebarHovered: isMouseInside
		});
	}

	usePortfolioStyles = () => {
		return this.props.portfolioMode || this.props.usePortfolioStyles || false;
	}

	renderArtifactContent(logEvent) {
		const date = new Date(this.props.artifact.creationTime);
			
		return (
			<>
				{this.renderAnnotations(logEvent)}
				<ArtifactContent 
					artifact={this.props.artifact} 
					hideMetadata={this.props.hideMetadata}
					hideEditBar={true}
					disableInteraction={this.props.usePortfolioStyles} />

				{this.usePortfolioStyles() ? null : (
					<div className={this.props.hideMetadata ? 'paneInfoTeeny' : 'paneInfo'}>
						{this.props.artifact.creator.name} · <Moment fromNow>{date.toString()}</Moment>
					</div>
				)}	
			</>
		);
	}

	renderPaneContent(logEvent) {
		const { artifact, hideMetadata } = this.props;

		let className = this.state.selected ? 'pane pane-selected' : 'pane';
		className += this.usePortfolioStyles() ? ' portfolio-mode' : '';

		return (
			<div className={className} key={artifact.id}>
				{(this.props.artifact.feedback && !this.usePortfolioStyles()) ? (
					<>
						<span className='feedbackCount'>
							<h3>{this.props.artifact.feedback.length}</h3>
						</span>
						<ChatBubble style={{ color: '#FFA500' }} />
					</>
				) : (
					<div />
				)}
				<div className={this.props.zoom < 70 ? 'paneInner paneInnerSmall' : 'paneInner'}
					style={(this.props.artifact.public && !this.usePortfolioStyles()) ? {backgroundColor:'#fffce6'} : {backgroundColor: '#ffffff'} }>
					<div className='paneInnerHeader'>
						{(this.props.hideSidebar && this.props.onToggleSelect && !this.props.portfolioMode) ? (
							<SelectButton
								onClick={(e) => {
									e.stopPropagation();
									e.preventDefault();
									logEvent('click select button on artifact pane');
									this.props.onToggleSelect(this.props.artifact, !this.state.selected);
									if (this.props.sourceIsGallery) {
										this.setState({ selected: !this.state.selected });
									}
								}}
								selected={this.state.selected} />
						) : null}

						{/* {this.props.disableClickArtifact && !this.props.hideClickIcon ? 
							<> 
								<span> </span> 
								<Link to={'/artifact/'+ this.props.artifact.id}> 
									<Launch className={'launch-detail'}/> 
								</Link>
							</>
							: <></>
						} */}
					</div>
					{ !this.props.disableClickArtifact && !isFauxArtifact(this.props.artifact) ? 
						<Link to={'/artifact/'+ this.props.artifact.id} target='_blank'> 
							{ this.renderArtifactContent(logEvent) }
						</Link>
					: 
						this.renderArtifactContent(logEvent) 
					}
				</div>
			</div>
		);
	}

	renderSidebar(logEvent) {
		const { isSidebarHovered } = this.state;
		// Get sidebar items
		const sidebarItems = this.renderSidebarItems(logEvent);
		if (this.props.hideSidebar || this.props.portfolioMode || (sidebarItems && sidebarItems.length == 0)) {
			return null;
		}
		return (
			<div
				className={'pane-sidebar' + (isSidebarHovered ? ' show' : '')}
				// style={isSidebarHovered ? {display: 'block'} : {display: 'none'}}
				onMouseEnter={() => this.onSidebarHover(true)}
				onMouseLeave={() => this.onSidebarHover(false)}
			>
				{sidebarItems}
			</div>
		);
	}

	renderSidebarItems = (logEvent) => {
		let items = [];

		// TODO: Feel free to change the order in which these are displayed!

		// FIXME: Possibly change this logic? It was being a bit wonky; disableClickArtifact was undefined.
		if (/*this.props.disableClickArtifact &&*/ !this.props.hideClickIcon && !isFauxArtifact(this.props.artifact)) {
			items.push(
				<div className='pane-sidebar-item' title="View artifact">
					<Link to={'/artifact/'+ this.props.artifact.id} target='_blank'> 
						<Launch className={'launch-detail'}/> 
					</Link>
				</div>
			);
		}

		if (this.props.onToggleSelect) {
			items.push(
				<div className='pane-sidebar-item' title={this.state.selected ? 'Hide artifact' : 'Add artifact'}>
					<SelectButton
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
							logEvent('click select button on artifact pane');
							this.props.onToggleSelect(this.props.artifact, !this.state.selected);
							if (this.props.sourceIsGallery) {
								this.setState({ selected: !this.state.selected });
							}
						}}
						selected={this.state.selected} />
				</div>
			);
		}

		if (this.props.renderAdditionalSidebarItems) {
			const additionalItems = this.props.renderAdditionalSidebarItems();
			for (let item of additionalItems) {
				items.push(item);
			}
		}

		// TODO: Add z-index controls (I'll (Jessie) will be doing this on another branch, since it'll be a bit more involved).

		return items;
	};

	renderAnnotations(logEvent) {
		const { artifact } = this.props;

		const hasAnnotations = artifact.annotations ? artifact.annotations.length > 0 : false;
		// This in particular is quite odd, but it seems that richAnnotations is often equal to "null" rather than literally being null.
		const hasRichAnnotations = artifact.richAnnotations ? artifact.richAnnotations !== "null" : false;
		if (!hasAnnotations && !hasRichAnnotations) return null;

		return (
			<div className="pane-title">
				{artifact.richAnnotations ? ( // FIXME: Given the above comment, should this intead be hasRichAnnotations?
					<TextEditor 
						id="annotations'"
						readOnly = {true}
						initialText={JSON.parse(artifact.richAnnotations)}
					/>
				) : (
					<div> {artifact.annotations} </div>
				)}
			</div>
		);
	}

	render() {
		let className = this.state.selected ? 'artifact-pane pane pane-selected' : 'artifact-pane pane';
		className += this.usePortfolioStyles() ? ' portfolio-mode' : '';
		className += this.props.usePortfolioStyles ? ' keep-hover-shadow' : '';
		return (
			<Amplitude 	
				eventProperties={(inheritedProps) => ({
					...inheritedProps,
					artifact: this.props.artifact
				})}
			>
				{({ logEvent }) => (
					<>
						<div className={className}>
							{this.renderPaneContent(logEvent)}
						</div>
						{this.renderSidebar(logEvent)}
					</>
				)}
			</Amplitude>
		)
	}
}

Pane.propTypes = {
	key: PropTypes.text,
	artifact: PropTypes.shape({
		annotations: PropTypes.string.isRequired,
		creationTime: PropTypes.number.isRequired,
		creator: PropTypes.number.isRequired,
		id: PropTypes.string.isRequired,
		tags: PropTypes.array.isRequired,
		text: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
		url: PropTypes.string.isRequired,
		with: PropTypes.array.isRequired
	}).isRequired,
	handleSelectArtifact: PropTypes.function,
	onToggleSelect: PropTypes.function,
	portfolioMode: PropTypes.bool,
	usePortfolioStyles: PropTypes.bool,
	selected: PropTypes.bool,
	selectableGrid: PropTypes.bool,
	disableClickArtifact: PropTypes.bool,
	hideClickIcon: PropTypes.bool,
	hideMetadata: PropTypes.bool,
	zoom: PropTypes.number,
	renderAdditionalSidebarItems: PropTypes.func,
	hideSidebar: PropTypes.bool
};

export default withRouter(Pane);
