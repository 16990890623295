import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Main from '../layouts/Main';
import Database from '../scripts/Database';



class AssignmentEntry extends Component {
	constructor(props) {
		super(props);
		this.state = {

		};
	}

	deleteTemplate = () => {
		confirm("Are you sure you want to delete this?")
		Database.deleteDocument('templates', this.props.template.id);
	}


	render = () => {
		return (
			<div className="assignmententry">
				<h2 className="entry-name"> {this.props.template.name} </h2> 
				<Link to={{ 
					pathname: '/create-template/' + this.props.template.id,
					state: {
							templateID : this.props.template.id
						}
					}}>
						{this.props.template.published 
							?
								<button> View Template </button>
							:
								<button> Edit Template </button>
						}
				</Link>
				<button onClick={this.deleteTemplate}> Delete Template </button>
			</div>
		)
	}

}

export default AssignmentEntry;
