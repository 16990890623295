import React, { Component } from 'react';
import Modal from 'react-modal';
import { Amplitude } from '@amplitude/react-amplitude';

import ArtifactTypes from '../data/ArtifactTypes';
import Gallery from '../components/Gallery';
import Tag from '../components/Tag';
import ArtifactContent from '../components/ArtifactContent';


import { Close } from '@material-ui/icons';

Modal.setAppElement('#root');

class TemplateRequirement extends Component {
	constructor(props) {
		super(props);

		console.log('req is editable: ', this.props.editable);

		this.state = {
			focus: true,
			modalIsOpen: false,
			artifact: null
		};
	}

	componentDidUpdate() {
		console.log('req is editable: ', this.props.editable);
	}

	openModal = () => {
		this.setState({ modalIsOpen: true });
	};

	afterOpenModal() {}

	closeModal = () => {
		this.setState({ modalIsOpen: false });
	};

	selectArtifact = (a, pane) => {
		console.log(a.id);
		if (a.type == this.props.requirement.type) {
			this.setState({ artifact: a });
			this.closeModal();
			this.props.updateArtifactList(a, this.props.requirement.index);
		} else {
			alert('must choose a ' + this.props.requirement.type + ' type artifact');
		}
	};

	descriptionInputChange = (e) => {
		this.props.updateRequirementData('description', e.target.value, this.props.index);
	};

	typeInputChange = (e) => {
		this.props.updateRequirementData('type', e.target.value, this.props.index);
	};

	tagInputChange = (e) => {
		this.props.updateRequirementData('tag', e.target.value, this.props.index);
	};

	renderPane() {
			return (
				<section id='artifact-column'>
					<div className='artifact-content'>
						<ArtifactContent artifact={this.state.artifact}/>
					</div>
				</section>
			);
	}

	deleteRequirement = () => {
		console.log(this.props.requirement.index);
		this.props.deletereq(this.props.requirement.index);
	};

	toggleEditable = () => {
		this.setState((prevState) => ({
			focus: !prevState.focus
		}));
	};

	render = () => {
		console.log(this.props.requirement.type);
		return (
			<Amplitude
					eventProperties={(inheritedProps) => ({
						...inheritedProps,
						scope: [ ...inheritedProps.scope, 'template-requirement' ]
					})}
			>
			{({ logEvent }) => (
			<div className='requirement box'>
				{this.props.source == 'template' && this.props.editable ? (
					<div className='deletebutton'>
						<button onClick={this.deleteRequirement}> Delete Requirement </button>
					</div>
				) : (
					<span> </span>
				)}
				{this.state.focus && this.props.editable ? (
					<div>
						<div>
							Description:
							<input
								className='requirementDescription'
								type='text'
								value={this.props.requirement.description}
								onChange={this.descriptionInputChange}
							/>
						</div>
						<div>
							Type:
							<select
								name='artifactType'
								className='requirementArtifactType'
								value={this.props.requirement.type}
								onChange={this.typeInputChange}
							>
								<option disabled value=''>
									-- select an option --
								</option>
								{[
									ArtifactTypes.IMAGE,
									ArtifactTypes.TEXT,
									ArtifactTypes.COMMIT,
									ArtifactTypes.LINK,
									ArtifactTypes.FIGMA,
									ArtifactTypes.VIDEO
								].map((artifactType) => {
									return <option value={artifactType.value}>{artifactType.label}</option>;
								})}
							</select>
						</div>
						<div>
							Tag:
							<input
								className='requirementTag'
								type='text'
								value={this.props.requirement.tag}
								onChange={this.tagInputChange}
							/>
						</div>
					</div>
				) : this.state.artifact ? (
					<div>
						<div>{this.renderPane()}</div>
						<div className='requirementTag'>
							<Tag onclick={null} selected={false} tag={this.props.requirement.tag} />
						</div>
					</div>
				) : (
					<div>
						<h3 className='requirementDescription'>{this.props.requirement.description}</h3>
						<div className='requirementArtifactType'>{this.props.requirement.type}</div>
						<div className='requirementTag'>
							<Tag onclick={null} selected={false} tag={this.props.requirement.tag} />
						</div>
					</div>
				)}

				{this.props.source == 'template' && this.props.editable && this.state.focus ? (
					<button onClick={this.toggleEditable}> lock </button>
				) : this.props.source == 'template' && this.props.editable && !this.state.focus ? (
					<button onClick={this.toggleEditable}> edit </button>
				) : this.props.source == 'report' ? (
					<button disabled={!this.props.team} onClick={() => {this.openModal(); logEvent('click attach artifact');}}>
						{' '}
						attach artifact{' '}
					</button>
				) : (
					<div> </div>
				)}

				<Modal
					isOpen={this.state.modalIsOpen}
					onAfterOpen={this.afterOpenModal}
					shouldCloseOnOverlayClick={true}
					onRequestClose={this.closeModal}
					contentLabel='Artifact Selection Modal'
					className='Modal'
					overlayClassName='Overlay'
				>
					<div className='closemodal'>
						<button className='clickable-icon' onClick={this.closeModal}>
							<Close />
						</button>
					</div>
					<Gallery
						team={this.props.team}
						onToggleSelect={this.selectArtifact}
						initialTypes={[ this.props.requirement.type ]}
						disableClickArtifact
						selectableGrid={true}
					/>
				</Modal>
			</div>
			)}
			</Amplitude>

		);
	};
}

TemplateRequirement.propTypes = {
	source: PropTypes.string.isRequired,
	editable: PropTypes.bool.isRequired,
	updateArtifactList: PropTypes.func,
	deletereq: PropTypes.func,
	index: PropTypes.number,
	requirement: PropTypes.object,
	updateRequirementData: PropTypes.func,
	team: PropTypes.object
};

export default TemplateRequirement;
