const SortTypes = Object.freeze({
	NEWEST: { value: 'newest', label: 'Newest First' },
	OLDEST: { value: 'oldest', label: 'Oldest First' },
	AUTHOR: { value: 'author', label: 'Author' }
});

const sortTypesDict = {};
for (let key in SortTypes) {
	const obj = SortTypes[key];
	if (obj.value !== false) {
		sortTypesDict[obj.value] = obj;
	}
}

export function sortTypeFromString(typeStr) {
	const obj = sortTypesDict[typeStr];
	// Use newest as default
	if (!obj) {
		return SortTypes.NEWEST;
	}
	return obj;
}

export default SortTypes;
