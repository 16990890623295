import React, { Component } from 'react';
import { Amplitude } from '@amplitude/react-amplitude';

import Main from '../layouts/Main';
import storage from '../../firebase/index';
import Database from '../scripts/Database';
import Github from '../scripts/Github';
import * as linkify from 'linkifyjs';

import ArtifactTypes from '../data/ArtifactTypes';
import { VALID_YOUTUBE_REGEX, VALID_FIGMA_REGEX, VALID_LINK_REGEX } from '../data/regExp';
import InputText from '../components/InputText';
import TagSelectorDropdown from '../components/TagSelectorDropdown';
import GithubUploader from '../components/GithubUploader';

import Modal from 'react-modal';
import Gallery from '../components/Gallery';
import GalleryStack from '../components/GalleryStack';
import Dropdown from '../components/Dropdown';
import { UserContext } from '../context/UserContext';
import { Close } from '@material-ui/icons';
import Loading from '../components/Loading';
import { compressImage, uploadImagesToStorage, extractTags, generatePlainTextFromRichText } from '../scripts/Utilities';
import TextEditor from '../components/TextEditor';

Modal.setAppElement('#root');

const artifactTypeOptions = [
	ArtifactTypes.IMAGE,
	ArtifactTypes.TEXT,
	ArtifactTypes.COMMIT,
	ArtifactTypes.LINK,
	ArtifactTypes.VIDEO,
	ArtifactTypes.FIGMA
];

class Upload extends Component {
	static contextType = UserContext;
	constructor(props) {
		super(props);

		const searchParams = new URLSearchParams(location.search);
		const token = searchParams.get('token');

		this.state = {
			teams: [],
			selectedTeam: null,
			selectedTeamArtifacts: [],
			selectedTeamTags: [],
			selectedTags: [],
			selectedCommits: [],
			selectedRepo: null,
			type: token ? ArtifactTypes.COMMIT : ArtifactTypes.IMAGE,
			images: [],
			imageLocalUrls: [],
			text: '',
			richtext: {},
			github: { user: '', repo: '', commitId: '' },
			link_url: '',
			annotationsValue: '',
			richAnnotations: null,
			associatedArtifacts: [],
			modalIsOpen: false,
			isDataLoaded: false,
			canUpload: false,
			uploading: false
		};
	}

	getData() {
		if (!this.state.isDataLoaded && this.context.user) {
			Database.getDocuments(
				'teams',
				this.context.user.teams,
				(teams, context) => {
					if (teams) {
						teams.sort((t1, t2) => {
							// Alphabetically sorted.
							return t1.name.localeCompare(t2.name);
						});
						var selectedTeam = teams[0];
						if (this.context.user.currentSelectedTeam) {
							var foundTeam = teams.find((t) => {
								return t.id === this.context.user.currentSelectedTeam.value;
							});
							if (foundTeam) {
								selectedTeam = foundTeam;
							}
						}
						Database.getDocumentsWhere(
							'artifacts',
							'team',
							'==',
							selectedTeam.id,
							(selectedTeamArtifacts, context) => {
								context.setState({
									teams: teams,
									selectedTeamArtifacts: selectedTeamArtifacts,
									selectedTeamTags: extractTags(selectedTeamArtifacts),
									selectedTeam: selectedTeam,
									isDataLoaded: true
								});
							},
							context
						);
					}
				},
				this
			);
		}
	}

	toggleAssociatedArtifacts = (a, selected) => {
		if (selected) {
			var ids = this.state.associatedArtifacts.map((a) => {
				return a.id;
			});
			if (!ids.includes(a.id)) {
				this.setState({
					associatedArtifacts: [ ...this.state.associatedArtifacts, a ]
				});
			}
		} else {
			this.setState({
				associatedArtifacts: this.state.associatedArtifacts.filter((artifact) => artifact.id !== a.id)
			});
		}
	};

	handleImageChange = (e) => {
		document.getElementById('loading-images').style.display = 'block';
		var names = new Set();
		if (e.target.files.length > 0) {
			var promises = [];
			for (var i = 0; i < e.target.files.length; i++) {
				var name = e.target.files[i].name;
				if (names.has(name)) {
					alert('Files cannot have same name.');
					document.getElementById('image').value = null;
					images = [];
					break;
				} else {
					names.add(name);
					var promise = new Promise((resolve, reject) => {
						compressImage(e.target.files[i], resolve, reject);
					});
					promises.push(promise);
				}
			}

			Promise.all(promises)
				.then((values) => {
					document.getElementById('loading-images').style.display = 'none';
					var files = [];
					var urls = [];
					values.map(({ file, url }) => {
						files.push(file);
						urls.push(url);
					});
					this.setState({
						images: files,
						imageLocalUrls: urls,
						canUpload: values.length !== 0
					});
				})
				.catch((result) => {
					document.getElementById('loading-images').style.display = 'none';
					alert(result.error);
				});
		}
	};

	getFields = () => {
		var tags = this.state.selectedTags;
		var uniquetags = [ ...new Set(tags) ];

		var annotations = this.state.annotationsValue;
		var richAnnotations = this.state.richAnnotations;

		var with_ = this.state.associatedArtifacts.map((artifact) => artifact.id);

		var type = this.state.type;

		var fields = {
			type: type,
			tags: uniquetags,
			annotations: annotations,
			richAnnotations: richAnnotations,
			with: with_,
			text: '',
			richtext: ''
		};

		if (type == ArtifactTypes.TEXT) {
			fields.text = generatePlainTextFromRichText(this.state.richtext);
			fields.richtext = this.state.richtext;
		} else if (type == ArtifactTypes.COMMIT) {
			fields.github_user = this.state.github.user;
			fields.github_repo = this.state.github.repo;
			fields.github_commit_id = this.state.github.commitId;
		} else if (type == ArtifactTypes.LINK || type == ArtifactTypes.VIDEO || type == ArtifactTypes.FIGMA) {
			fields.link_url = this.state.link_url;
		}

		return fields;
	};

	uploadCallback(docRef, context, resolve = null, reject = null) {
		var myID = docRef.id;

		if (resolve) {
			resolve(myID);
		} else {
			// add this artifact to the associated artifacts
			var { associatedArtifacts } = context.state;
			if (associatedArtifacts.length != 0) {
				// associate current artifact to artifact with given id
				for (const [ index, value ] of associatedArtifacts.entries()) {
					if (!value.with.includes(myID)) {
						Database.addValuesToArrayInDocument('artifacts', value.id, [myId], 'with');
					}
				}
			}

			// clear local storage of rich text
			sessionStorage.setItem("draftail:content", 'null');

			// redirect to artifact detailed page
			context.props.history.push(`/artifact/${myID}`);
		}
	}

	handleUpload = () => {
		this.setState({ uploading: true });
		var fields = this.getFields();
		var timenow = Date.now();

		if (fields.type === ArtifactTypes.TEXT) {
			var data = {
				creationTime: timenow,
				type: ArtifactTypes.TEXT.value,
				url: '',
				tags: fields.tags,
				annotations: fields.annotations,
				richAnnotations: fields.richAnnotations,
				with: fields.with,
				text: fields.text,
				richtext: fields.richtext
			};

			// add artifact to database
			this.addArtifactToDatabase(data);
		} else if (fields.type === ArtifactTypes.LINK) {
			var data = {
				creationTime: timenow,
				type: ArtifactTypes.LINK.value,
				url: '',
				linkURL: fields.link_url,
				tags: fields.tags,
				annotations: fields.annotations,
				richAnnotations: fields.richAnnotations,
				with: fields.with,
				text: fields.text,
				//richtext: fields.richtext
			};
			this.addArtifactToDatabase(data);
		} else if (fields.type === ArtifactTypes.VIDEO) {
			var data = {
				creationTime: timenow,
				type: ArtifactTypes.VIDEO.value,
				url: '',
				linkURL: fields.link_url,
				tags: fields.tags,
				annotations: fields.annotations,
				richAnnotations: fields.richAnnotations,
				with: fields.with,
				text: fields.text,
				//richtext: fields.richtext
			};
			this.addArtifactToDatabase(data);
		} else if (fields.type === ArtifactTypes.FIGMA) {
			var data = {
				creationTime: timenow,
				type: ArtifactTypes.FIGMA.value,
				url: '',
				linkURL: fields.link_url,
				tags: fields.tags,
				annotations: fields.annotations,
				richAnnotations: fields.richAnnotations,
				with: fields.with,
				text: fields.text,
				//richtext: fields.richtext
			};
			this.addArtifactToDatabase(data);
		} else if (fields.type === ArtifactTypes.IMAGE) {
			var images = this.state.images;
			// check this isn't empty
			if (images[0]) {
				var newBytes = images.map((i) => i.size).reduce((a, b) => a + b);
				Database.addToNumberValueInDocument('users', this.context.user.id, newBytes, 'bytesUploaded'); 
				uploadImagesToStorage(
					images,
					timenow,
					(url, storageFilename, resolve, reject) => {
						// step 2: add to database after upload completes
						var data = {
							creationTime: timenow,
							type: ArtifactTypes.IMAGE.value,
							url: url,
							storageFilename: storageFilename,
							tags: fields.tags,
							annotations: fields.annotations,
							richAnnotations: fields.richAnnotations,
							with: fields.with,
							text: ''
						};
						this.addArtifactToDatabase(data, resolve, reject);
					},
					(ids) => {
						// add all artifacts to the associated artifacts
						var { associatedArtifacts } = this.state;
						if (associatedArtifacts.length != 0) {
							// associate current artifact to artifact with given id
							for (const [ index, value ] of associatedArtifacts.entries()) {
								Database.addValuesToArrayInDocument('artifacts', value.id, ids, 'with'); 
							}
						}

						// redirect to artifact detailed page
						if (ids.length > 1) {
							this.props.history.push(`/studio`);
						} else {
							this.props.history.push(`/artifact/${ids[0]}`);
						}
					}
				);
			}
		} else if (fields.type === ArtifactTypes.COMMIT) {
			var commits = this.state.selectedCommits;
			// check this isn't empty
			if (commits[0]) {
				var promises = [];
				commits.map((commit) => {				
					var fields = this.getFields();
					var data = {
						creationTime: new Date(Date(commit.commit.author.date)).getTime(),
						type: ArtifactTypes.COMMIT.value,
						url: '',
						tags: fields.tags,
						annotations: fields.annotations,
						richAnnotations: fields.richAnnotations,
						with: fields.with,
						text: '',
						github_commit: commit,
						github_commit_repo: this.state.selectedRepo
					};
					// add artifact to database
					var promise = new Promise((resolve, reject) => {
						this.addArtifactToDatabase(data, resolve, reject);
					});
					promises.push(promise);
				})

				Promise.all(promises).then( (ids) => {
					// add all artifacts to the associated artifacts
					var { associatedArtifacts } = this.state;
					if (associatedArtifacts.length != 0) {
						// associate current artifact to artifact with given id
						for (const [ index, value ] of associatedArtifacts.entries()) {
							Database.addValuesToArrayInDocument('artifacts', value.id, ids, 'with'); 
						}
					}

					// redirect to artifact detailed page
					if (ids.length > 1) {
						this.props.history.push(`/studio`);
					} else {
						this.props.history.push(`/artifact/${ids[0]}`);
					}
				});
			}
		}
	};

	addArtifactToDatabase(data, resolve = null, reject = null) {
		const artifact = {
			...data,
			creator: this.context.user,
			team: this.state.selectedTeam.id
		};
		Database.addDocument(
			'artifacts',
			artifact,
			(result, context) => {
				this.uploadCallback(result, context, resolve, reject);
			},
			this
		);
	}

	closeModal = () => {
		this.setState({ modalIsOpen: false });
	};

	updateSelectedCommits = (commit, repo, selected, clear=false) => {
		if (clear) {
			this.setState({
				selectedCommits : [],
				selectedRepo: null,
				canUpload: false
			});
		} else {
			var selectedCommits = this.state.selectedCommits;
			if (selected) {
				this.setState({ 
					selectedCommits: [ ...selectedCommits, commit ],
					selectedRepo: repo,
					canUpload : true
				});
			} else {
				const newSelectedCommits = [ ...selectedCommits ];
				newSelectedCommits.splice(newSelectedCommits.indexOf(commit), 1);
				this.setState({ 
					selectedCommits: newSelectedCommits,
					selectedRepo: repo,
					canUpload : newSelectedCommits.length > 0 
				});
			}
		}	
	}

	

	renderTagSection() {
		const { selectedTeamArtifacts, selectedTeamTags, selectedTags } = this.state;

		return (
			<div className='upload-section'>
				<h3>Tag your artifact(s)</h3>
				<TagSelectorDropdown
					creatable
					options={selectedTeamTags ? Object.keys(selectedTeamTags) : []}
					value={selectedTags}
					onChange={(selectedTags) => {
						this.setState({ selectedTags: selectedTags });
					}}
					prompt={'Add tags to this artifact'}
					menuPortalTarget={document.getElementById('artifact-view')}
				/>
			</div>
		);
	}

	renderTeamSelectSection() {
		const teamOptions = this.state.teams.map((team) => {
			return { label: team.name, value: team.id };
		});
		if (teamOptions.length) {
			return (
				<div className='upload-section'>
					<h3>Select Studio Space</h3>
					<Dropdown
						options={teamOptions}
						defaultValue={{ label: this.state.selectedTeam.name, value: this.state.selectedTeam.id }}
						onChange={(selectedTeamId) => {
							Database.replaceValuesInDocument('users', this.context.user.id, {
								currentSelectedTeam: selectedTeamId
							});
							var selectedTeam = this.state.teams.find((team) => {
								return selectedTeamId.value === team.id;
							});
							Database.getDocumentsWhere(
								'artifacts',
								'team',
								'==',
								selectedTeam.id,
								(selectedTeamArtifacts, context) => {
									context.setState({
										selectedTeamArtifacts: selectedTeamArtifacts,
										selectedTeamTags: extractTags(selectedTeamArtifacts),
										selectedTeam: selectedTeam,
										isDataLoaded: true
									});
								},
								this
							);
						}}
					/>
				</div>
			);
		}
	}

	renderTypeSelectSection() {
		return (
			<div className='upload-section'>
				<h3>Select Artifact Type</h3>
				<Dropdown
					name='artifactType'
					id='artifactType'
					onChange={(selectedType) => {
						this.setState({ type: selectedType, canUpload: false });
					}}
					options={artifactTypeOptions}
					defaultValue={this.state.type}
				/>
			</div>
		);
	}

	renderUploadInputSection() {
		return (
			<div className='upload-section'>
				<h3>Add your artifact(s)</h3>
				{this.renderUploadPrompt(this.state.type)}
			</div>
		);
	}

	renderUploadPrompt(artifactType) {
		switch (artifactType) {
			case ArtifactTypes.IMAGE:
				return (
					<div>
						<div className='btn' id='file_upload'>
							<input
								id='image'
								type='file'
								accept='image/*'
								title=' '
								multiple
								style={{ display: 'none' }}
								onChange={this.handleImageChange}
							/>
							<Amplitude eventProperties={(inheritedProps) => ({ ...inheritedProps})} >
								{({ logEvent }) => (
									<button onClick={(e) => {logEvent('click image select button'); document.getElementById('image').click();}}>
										Upload an image or multiple images
									</button>
								)}
							</Amplitude>
						</div>
						<div id='loading-images' style={{ display: 'none' }}>
							<Loading />
						</div>
						<div id='preview-container'>
							{this.state.imageLocalUrls.map((url, i) => {
								return (
									<div className='preview-image-block'>
										<div>
											<img src={url} className='preview-image' alt={this.state.images[i].name} />
										</div>
										<div> {this.state.images[i].name} </div>
									</div>
								);
							})}
						</div>
					</div>
				);
			case ArtifactTypes.COMMIT:
				return (
					<div className='github-section'>
						<GithubUploader 
							updateSelectedCommits={this.updateSelectedCommits} 
							existingCommitArtifacts={this.state.selectedTeamArtifacts.filter(a => (a.type === 'commit'))}/>
					</div>
				);
			case ArtifactTypes.TEXT:
				return (
					<TextEditor 
						id='text-artifact'
						readOnly = {false}
						onChange={(content) => {
							this.setState({ 
								richtext: content, 
								canUpload: content !== 'null'
							});
						}}
					/>
				);
			case ArtifactTypes.LINK:
				return (
					<InputText
						id='link_url'
						placeholder='https://...'
						onBlur={(url) => {
							var result = linkify.find(url);
							if (result.length >= 1) {
								this.setState({ link_url: url, canUpload: url !== '' });
								return true;
							} else {
								// disable upload button
								this.setState({canUpload: false});
								// tell InputText to display error
								return false;
							}
						}}
						singleLine
					/>
				);
			case ArtifactTypes.VIDEO:
				return (
					<InputText
						id='link_url'
						placeholder='https://...'
						onBlur={(url) => {
							var result = VALID_YOUTUBE_REGEX.test(url);
							if (result) {
								this.setState({ link_url: url, canUpload: url !== '' });
								return true;
							} else {
								// disable upload button
								this.setState({canUpload: false});
								// tell InputText to display error
								return false;
							}
						}}
						singleLine
					/>
				);
			case ArtifactTypes.FIGMA:
				return (
					<InputText
						id='link_url'
						placeholder='https://...'
						onBlur={(url) => {
							var result = VALID_FIGMA_REGEX.test(url);
							if (result) {
								this.setState({ link_url: url, canUpload: url !== '' });
								return true;
							} else {
								// disable upload button
								this.setState({canUpload: false});
								// tell InputText to display error
								return false;
							}
						}}
						singleLine
					/>
				);
			default:
				return <div> To be Implemented.</div>;
		}
	}

	renderAssociatedArtifacts() {
		const { associatedArtifacts } = this.state;
		if (associatedArtifacts && associatedArtifacts.length) {
			return (
				<GalleryStack
					artifacts={associatedArtifacts.map((a) => ({ ...a, selected: true }))}
					onToggleSelect={this.toggleAssociatedArtifacts}
					scale={90}
					selectableGrid={true}
					hideMetadata={this.state.zoomValue <= 70}
				/>
			);
		}
	}

	renderAnnotationSection() {
		return (
			<div className='upload-section'>
				<h3>Annotate your artifact(s)</h3>
				<TextEditor 
					id='annotations-input'
					readOnly = {false}
					onChange={(content) => {
						this.setState({ 
							richAnnotations: content, 
							annotationsValue: generatePlainTextFromRichText(content),
							canUpload: content !== 'null'
						});
					}}
				/>
			</div>
		);
	}

	renderAssociatedArtifactsSection() {
		return (
			<div className='upload-section'>
				<h3>Group with other artifacts</h3>
				<button onClick={() => this.setState({ modalIsOpen: true })}>Choose artifacts to associate</button>
				<Modal
					isOpen={this.state.modalIsOpen}
					onAfterOpen={() => null}
					shouldCloseOnOverlayClick={true}
					onRequestClose={this.closeModal}
					contentLabel='Artifact Selection Modal'
					className='Modal'
					overlayClassName='Overlay'
				>
					<div className='closemodal'>
						<button className='clickable-icon' onClick={this.closeModal}>
							<Close />
						</button>
					</div>
					<Gallery
						team={this.state.selectedTeam}
						preSelectedArtifactIds={this.state.associatedArtifacts.map((a) => a.id)}
						onToggleSelect={this.toggleAssociatedArtifacts}
						disableClickArtifact
						selectableGrid={true}
					/>
				</Modal>
				<div className='upload-sub-section'>{this.renderAssociatedArtifacts()}</div>
			</div>
		);
	}

	renderUploadSections() {
		return (
			<div id='upload-section-container'>
				{this.renderTypeSelectSection()}
				{this.renderUploadInputSection()}
				{this.renderAnnotationSection()}
				{this.renderTagSection()}
				{this.renderAssociatedArtifactsSection()}
				<Amplitude eventProperties={(inheritedProps) => ({ ...inheritedProps})} >
					{({ logEvent }) => (
						<button 
							id={'upload-button'} 
							className={this.state.canUpload ? 'primary' : 'primary disabled'} 
							onClick={() => {logEvent('click upload artifact'); this.handleUpload();}}>
							Upload ⟩
						</button>
					)}
				</Amplitude>
			</div>
		);
	}

	render() {
		this.getData(); // Sets team tags and artifacts
		if (this.state.uploading) {
			return <Loading />;
		} else {
			return (
				<div id='upload-body'>
					<Amplitude
						eventProperties={(inheritedProps) => ({
							...inheritedProps,
							scope: [ ...inheritedProps.scope, 'Upload' ],
							type : this.state.type.value,
							numImages : this.state.images.length,
							annotationsLength : this.state.annotationsValue.length,
							numTags : this.state.selectedTags.length,
							numAssociatedArtifacts : this.state.associatedArtifacts.length,
							textArtifactLength : this.state.text.length,
							team : this.state.selectedTeam.id
						})}
					>
					{this.renderTeamSelectSection()}
					{this.state.selectedTeam ? this.renderUploadSections() : <Loading />}
					</Amplitude>
				</div>
			);
		}
	}
}

export default (props) => (
	<Main selectedSidebarItem='upload'>
		<Upload {...props} />
	</Main>
);
