import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import '../firebase/index.js';

// Main Views
import Index from './views/Index';
import Upload from './views/Upload';
import GalleryPage from './views/GalleryPage';
import Dashboard from './views/Dashboard';
import Profile from './views/Profile';
import NotFound from './views/NotFound';
import TemplateCreator from './views/TemplateCreator';
import Assignments from './views/Assignments';
import ReportCreator from './views/ReportCreator';
import ArtifactDetail from './views/ArtifactDetail';
import AdminPanel from './views/AdminPanel';
import Explore from './views/Explore';
import PortfolioCreator from './views/PortfolioCreator';
import { TestPortfolio } from './components/Portfolio';
import PortfolioPage from './views/PortfolioPage';
import Showcase from './views/Showcase';

// All of our CSS
import '../node_modules/normalize.css/normalize.css';
import './static/css/main.scss';
import '../node_modules/react-grid-layout/css/styles.css';
import '../node_modules/react-resizable/css/styles.css';

ReactDOM.render(
	<Router basename={process.env.BASE_PATH}>
		<Switch>
			<Route path='/' exact component={Index} />
			<Route path='/upload' component={Upload} />
			<Route path='/studio' exact component={GalleryPage} />
			<Route path='/profile' component={Profile} />
			<Route path='/create-template' component={TemplateCreator} />
			<Route path='/create-report' component={ReportCreator} />
			<Route path='/checkins' component={Assignments} />
			<Route path='/artifact/:id' component={ArtifactDetail} />
			<Route path='/adminpanel' component={AdminPanel} />
			<Route path='/explore' component={Explore} />
			<Route path='/create-portfolio' component={PortfolioCreator} />
			<Route path='/test-portfolio' component={TestPortfolio} />
			<Route path='/portfolio/:id' component={PortfolioPage} />
			<Route path='/showcase' component={Showcase} />
			<Route component={NotFound} status={404} />
		</Switch>
	</Router>,
	document.getElementById('root')
);
