import React, { Component, useRef } from 'react';
import Main from '../layouts/Main';
import { UserContext } from '../context/UserContext';
import { Add, Close } from '@material-ui/icons';
import { compressImage, uploadImagesToStorage, deleteImageFromStorage } from '../scripts/Utilities';
import Database from '../scripts/Database';
import Modal from 'react-modal';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';

Modal.setAppElement('#root');

// Image cropping from https://levelup.gitconnected.com/crop-images-on-upload-in-your-react-app-with-react-image-crop-5f3cd0ad2b35

class Profile extends Component {
	static contextType = UserContext;
	constructor(props) {
		super(props);
		this.state = {
			profilePictureURL: null,
			unCroppedProfilePictureURL: null,
			croppedImageUrl: null,
			croppedImage: null,
			displayError: false,
			modalIsOpen: false,
			crop: {
				unit: '%',
				width: 30,
				aspect: 1 / 1
			}
		};
	}

	handleProfileImageChange = (e) => {
		if (e.target.files && e.target.files.length === 1) {
			compressImage(
				e.target.files[0],
				(v) => {
					this.setState({ unCroppedProfilePictureURL: v.url, modalIsOpen: true });
				},
				(v) => {
					this.setState({ displayError: true });
				}
			);
		}
	};

	closeModal = () => {
		this.setState({ modalIsOpen: false });
	};

	onImageLoaded = (image) => {
		this.imageRef = image;
	};

	onCropChange = (crop) => {
		this.setState({ crop });
	};

	onCropComplete = (crop) => {
		if (this.imageRef && crop.width && crop.height) {
			this.setCroppedImg(this.imageRef, crop);
		}
	};

	setCroppedImg(image, crop) {
		const canvas = document.createElement('canvas');
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext('2d');

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);

		const reader = new FileReader();
		canvas.toBlob((blob) => {
			reader.readAsDataURL(blob);
			reader.onloadend = () => {
				this.setState({ croppedImageUrl: reader.result });
				this.dataURLtoFile(reader.result, 'cropped.jpg');
			};
		});
	}

	dataURLtoFile(dataurl, filename) {
		let arr = dataurl.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		let croppedImage = new File([ u8arr ], filename, { type: mime });
		this.setState({ croppedImage: croppedImage });
	}

	renderCropModal() {
		return (
			<Modal
				isOpen={this.state.modalIsOpen}
				onAfterOpen={() => null}
				shouldCloseOnOverlayClick
				onRequestClose={this.closeModal}
				contentLabel='Profile Image Crop Modal'
				className='Modal'
				overlayClassName='Overlay'
			>
				<div className='closemodal'>
					<button className='clickable-icon' onClick={this.closeModal}>
						<Close />
					</button>
				</div>
				<div className='modal-content'>
					<ReactCrop
						className='crop-image'
						src={this.state.unCroppedProfilePictureURL}
						crop={this.state.crop}
						onImageLoaded={this.onImageLoaded}
						onComplete={this.onCropComplete}
						onChange={this.onCropChange}
					/>
					<button
						className='primary'
						onClick={() => {
							deleteImageFromStorage(this.state.profilePictureURL);
							// ^ should be okay even the first time a user does this, even
							//   though the profile URL is not a cloud storage URL
							//   since we are initializing profile pictures with google profile image
							this.setState({ profilePictureURL: this.state.croppedImageUrl });
							this.closeModal();
							uploadImagesToStorage(
								[ this.state.croppedImage ],
								Date.now(),
								(url, storageFilename, resolve, reject) => {
									// step 2: add to database after upload completes
									Database.replaceValuesInDocument('users', this.context.user.id, {
										imageURL: url
									});
								}
							);
						}}
					>
						Save
					</button>
				</div>
			</Modal>
		);
	}

	render() {
		const { user } = this.context;
		if (!this.state.profilePictureURL && user) {
			this.setState({ profilePictureURL: user.imageURL });
		}
		return (
			user && (
				<div>
					<h1>{user.name}</h1>
					<div
						className='profile-image-wrapper'
						onClick={(e) => document.getElementById('profile-image-upload').click()}
					>
						<input
							id='profile-image-upload'
							type='file'
							accept='image/*'
							title=' '
							style={{ display: 'none' }}
							onChange={this.handleProfileImageChange}
							onClick={(event) => {
								// Allows onChange to be called, even when the same file is selected a second time
								event.target.value = null;
							}}
						/>
						<img
							id='profile-image'
							src={this.state.profilePictureURL}
							alt={`profile picture of ${user.name}`}
						/>
						<div className='profile-image-overlay'>
							<Add />
						</div>
					</div>
					{this.state.displayError && <p>There was an issue uploading your image. Sorry!</p>}
					{this.renderCropModal()}
				</div>
			)
		);
	}
}

export default (props) => (
	<Main selectedSidebarItem='profile'>
		<Profile {...props} />
	</Main>
);
