import Database from '../scripts/Database';
import storage from '../../firebase/index';
import firebase from 'firebase/app';
import ArtifactTypes from '../data/ArtifactTypes';
import moment from 'moment'


export function formatDateForLayoutName(date) {
	return moment(date).format('MM/DD/YYYY h:mm a');
}

export function replaceValuesInListOfArtifacts(id_list, data, context, resolveCallback) {
	var promises = [];
	id_list.map((id) => {
		if (id != '') {
			var p = new Promise((resolve, reject) => {
				Database.replaceValuesInDocument(
					'artifacts',
					id,
					data,
					(data, context) => {
						resolve(data);
					},
					context
				);
			});
			promises.push(p);
		}
	});

	Promise.all(promises).then(resolveCallback);
}

export function deleteImageFromStorage(fileName) {
	storage.ref(`images/${fileName}`).delete().then(console.log('image deleted'));
}

export function deleteArtifact(artifact, artifactTeam, callback = null, context = null) {

	// remove artifact from associated artifacts' with lists
	artifact.with.map((associated_id) => {
		Database.removeValuesFromArrayInDocument('artifacts', associated_id, [artifact.id], 'with');
	});

	// remove artifact from table
	Database.deleteDocument('artifacts', artifact.id, callback, context);
}

// adapted from https://zocada.com/compress-resize-images-javascript-browser/

const MAX_FILE_SIZE = 10000000;
const REDUCTION_CONSTANT = 0.95;
const REDUCED_WIDTH = 1000;

export function compressImage(orig_file, resolve, reject) {
	const fileName = orig_file.name;
	const fileSize = orig_file.size;
	console.log('orig size: ', orig_file.name, orig_file.size);

	const reader = new FileReader();
	reader.readAsDataURL(orig_file);
	reader.onload = (event) => {
		const img = new Image();
		img.src = event.target.result;
		(img.onload = () => {
			const elem = document.createElement('canvas');
			var width = img.width;

			// don't resize gifs, just reject them
			if (fileSize > MAX_FILE_SIZE && fileName.slice(-4) === '.gif') {
				reject({error: 'gif is too large; please upload an animated gif smaller than 10 MB, or save a static image file as a .jpeg or .png'});

			} else if (fileSize > MAX_FILE_SIZE) {
				width = REDUCED_WIDTH;

				const scaleFactor = width / img.width;
				const height = img.height * scaleFactor;
				elem.width = width;
				elem.height = height;
				const ctx = elem.getContext('2d');
				// img.width and img.height will contain the original dimensions
				ctx.drawImage(img, 0, 0, width, height);
				ctx.canvas.toBlob(
					(blob) => {
						const file = new File([ blob ], fileName, {
							type: 'image/jpeg',
							lastModified: Date.now()
						});
						resolve({ file: file, url: img.src });
					},
					'image/jpeg',
					REDUCTION_CONSTANT
				);
			} else {
				resolve({ file: orig_file, url: img.src });
			}
		}),
			(reader.onerror = (error) => {
				console.log(error);
				reject([]);
			});
	};
}

export function uploadImagesToStorage(images, timenow, urlCallback, uploadCallback = null) {
	var promises = [];
	images.map((image) => {
		// step 1: upload to the cloud storage
		var promise = new Promise((resolve, reject) => {
			var storageFilename = `${timenow}_${image.name}`; // Adding time to filename to prevent collisions.
			const uploadTask = storage.ref(`images/${storageFilename}`).put(image);
			uploadTask.on(
				'state_changed',
				(snapshot) => {
					// progress function ...
				},
				(error) => {
					// Error function ...
					console.log(error);
					reject(error);
				},
				() => {
					// complete function ...
					storage
						.ref('images')
						.child(storageFilename)
						.updateMetadata({cacheControl: 'public,max-age=15552000,immutable'})
						.then(
							storage
								.ref('images')
								.child(storageFilename)
								.getDownloadURL()
								.then((url) => urlCallback(url, storageFilename, resolve, reject))
						);
				}
			);
		});
		promises.push(promise);
	});
	Promise.all(promises).then(uploadCallback);
}

export function extractTags(artifacts) {
	if (!artifacts) {
		return {};
	}
	var tags = {};
	artifacts.forEach((a) => {
		a.tags.forEach((t) => {
			if (t in tags) {
				tags[t] += 1;
			} else {
				tags[t] = 1;
			}
		});
	});
	return tags;
}

export function generatePlainTextFromRichText( richtext ) {
	if (richtext != 'null') {
		richtext = JSON.parse(richtext).blocks;
		var plainstring = '';
		for (var i = 0; i < richtext.length; i++) {
			console.log(richtext[i].text);
			plainstring += richtext[i].text;
			plainstring += ' \n ';
		}
		return plainstring;
	} else {
		return '';
	}
}

export function isFauxArtifact(artifact) {
	return artifact.id.substring(0,4) === 'faux';
}
