const DefaultTags = [
	{ label: 'sketch', value: 'sketch' },
	{ label: 'wireframe', value: 'wireframe' },
	{ label: 'ideation', value: 'ideation' },
	{ label: 'critique-this', value: 'critique-this' },
	{ label: 'cycle 1', value: 'cycle 1' },
	{ label: 'inspiration', value: 'inspiration' }
];

export default DefaultTags;
