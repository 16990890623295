import React, { Component } from 'react';
import * as d3 from "d3";


class MediaVis extends Component {
	constructor(props) {
		super(props);
		this.createChart = this.createChart.bind(this)
	}

	componentDidMount() {
    	this.createChart();
	}
	componentDidUpdate() {
      	this.createChart();
      	console.log("MEDIA VIS UPDATE: ");
      	console.log(this.props.artifacts);
	}

	getMediaTypes() {
		var mediaTypes = []
		console.log(this.props.artifacts)
		this.props.artifacts.forEach((artifact) => {
			mediaTypes.push(artifact.type);
		});
		console.log(mediaTypes);
		return mediaTypes
	}

	color(d) {
		switch (d) {
			case "image":
				return "586BA4";
			case "text":
				return "F5DD90";
			case "commit":
				return "F76C5E";
			default:
				return "#222222"
		}
	}

	createChart(data) {
		data = this.getMediaTypes(data)

		const node = this.node;
		const square = 20
		const rowcount = 20
		const spacing = 2
		   
		d3.select(node)
		      .selectAll('rect')
		      .data(data)
		      .enter()
		      .append('rect')
		   
		d3.select(node)
		      .selectAll('rect')
		      .data(data)
		      .exit()
		      .remove()
		   
		d3.select(node)
		      .selectAll('rect')
		      .data(data)
		      .style('fill', (d) => this.color(d))
		      .attr('x', (d,i) => (i % 20) * (square+spacing))
		      .attr('y', (d,i) => (parseInt(i/20) * (square+spacing)) )
		      .attr('height', square)
		      .attr('width', square)
		      .append("rect:title")
  			  .text((d) => d);

	}

	render() {
		return (
			<div>
				<h1> Media Types </h1>
				<h2> // <i> insert a tag filter here so can look at different projects or tags </i> </h2>
				<svg 
					ref={node => this.node = node}
				    width={500} height={100}>
				</svg>
			</div>
		);
	}
}


export default MediaVis;
