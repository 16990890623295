import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import _ from "lodash";
import { Rnd } from "react-rnd";
import { FlipToFront, FlipToBack } from '@material-ui/icons';

import Database from '../scripts/Database';
import Pane from '../components/Pane';
import Dropdown from '../components/Dropdown';
import InputText from '../components/InputText';
import Moment from 'react-moment';
import {formatDateForLayoutName} from '../scripts/Utilities'

const DEFAULT_LAYOUT_NAME = "Default layout";

export default class GalleryGrid extends Component {

	constructor(props) {
		super(props);

		console.log("constructor for GalleryGrid was called...........");

		var [height, width] = this.calculateInitialLayoutHeight(this.props.layoutData);

		var layoutHeight, layoutWidth;
		if (this.props.portfolioMode) {
			layoutHeight = height;
			layoutWidth = width;
		} else {
			layoutHeight = Math.max(height, document.getElementById('main-content').clientHeight / (this.props.scale/100.0));
			layoutWidth = Math.max(width, document.getElementById('main-content').clientWidth / (this.props.scale/100.0));
		}

		this.state = {
			layoutOptions: [],
			fetchedLayouts: false,
			lastTeam: null,
			currentName: DEFAULT_LAYOUT_NAME, // should be pulled from query params?
			newLayoutName: '',
			zIndexMax: 1,
			zIndexMin: 1,
			selected: [],
			selectedInitialSizes: {},
			layoutHeight: layoutHeight,
			layoutWidth: layoutWidth,
			gridScale: this.props.gridScale ? [this.props.gridScale, this.props.gridScale] : [1,1],
			zControlsVisible: '', 
			lastVisibleIds: [],
			mouseDownTimes: {},
			unsavedChanges: false
		};
	}

	componentDidMount() {
        document.getElementById('main-content').addEventListener('mousedown', this.clearSelect);
    	document.getElementById('main-content').addEventListener('scroll', this.updateLayoutHeight);
    }
    
    componentWillUnmount() {
        document.getElementById('main-content').removeEventListener('mousedown', this.clearSelect);
        document.getElementById('main-content').removeEventListener('scroll', this.updateLayoutHeight);
    }

    clearSelect = (event) => {
    	if (!event.shiftKey && (
    			event.target.id == 'layout-wrapper'
    			|| event.target.id == 'main-content')) {
	    	this.setState({
	    		selected: []
	    	});
	    }
    }


	toggleSelect = (event, id) => {
	    if (event.shiftKey && event.type === "mousedown") {
	    	var index = this.state.selected.indexOf(id);
	    	var newSelected = this.state.selected;
	    	if (index > -1) {
			  	newSelected.splice(index, 1);
	    	} else {
			  	newSelected.push(id);
	    	}
	    	this.setState({
	    		selected: newSelected
	    	});
		}
	 }

	onDragStart = (e, d, id) => {
	 	// d.node.style.zIndex = this.state.zIndex + 1;
	  //   this.setState({ zIndex: this.state.zIndex + 1});
	}

	onDragStop = (e, d, id) => {
	    var data = {
	    	x: Math.max(0, d.x), 
	    	y: Math.max(0, d.y), 
	    	width: d.node.clientWidth, 
	    	height: d.node.clientHeight, 
	    	zIndex: d.node.style.zIndex,
	    };
	    var newLayoutData = this.props.layoutData;
	    newLayoutData[id] = data;

	 	this.state.selected.map((id) => {
	 		newLayoutData[id].x = Math.max(0, newLayoutData[id].x);
	 		newLayoutData[id].y = Math.max(0, newLayoutData[id].y);
	 	});
		
		this.setState({unsavedChanges: true})
		this.props.updateLayoutData(newLayoutData, null);
	}

	clearMouseDown = (id) => {
		const listener = this.state.mouseDownTimes[id].listener;
		document.querySelector('#rnd-' + id).removeEventListener('click', listener, true);
		const mouseDownTimes = {
			...this.state.mouseDownTimes,
			[id]: undefined
		};
		this.setState({
			mouseDownTimes
		});
	};


	 onDrag = (e, d, refId) => {
		var main = document.getElementById('main-content');

		// console.log(d.y, main.scrollHeight);
		// if ((d.y) >= main.scrollHeight) {
		// 	this.setState({
		// 		layoutHeight: this.state.layoutHeight + 100
		// 	});
		// }
		// console.log(d.x, d.width, main.scrollWidth);
		// if ((d.x) >= main.scrollWidth) {
		// 	this.setState({
		// 		layoutWidth: this.state.layoutWidth + 100
		// 	});
		// }

		var newLayoutData = this.props.layoutData;
	 	this.state.selected.map((id) => {
	 		newLayoutData[id].x += parseInt(d.deltaX);
	 		newLayoutData[id].y += parseInt(d.deltaY);
	 	});

		this.setState({unsavedChanges: true})
		this.props.updateLayoutData(newLayoutData, null);
	 }

	 onResizeStart = (e, dir, ref, delta, position, id) => {
	 	var initialSizes = {};
	 	this.state.selected.map((sid) => {
	 		initialSizes[sid] = { width: this.props.layoutData[sid].width, 
								height: this.props.layoutData[sid].height,
								x: this.props.layoutData[sid].x,
								y: this.props.layoutData[sid].y };
	 	});
	 	this.setState({
	 		selectedInitialSizes: initialSizes
	 	});
	 }

	 onResizeStop = (e, dir, ref, delta, position, id) => {
	 	var data = {
	 		x: position.x, 
	 		y: position.y, 
	 		width: ref.clientWidth, 
	 		height: ref.clientHeight, 
	 		zIndex: ref.style.zIndex,
	 	};
	 	var newLayoutData = this.props.layoutData;
		newLayoutData[id] = data;

		this.setState({unsavedChanges: true})
		this.props.updateLayoutData(newLayoutData, null);
	 }

	 onResize = (e, dir, ref, delta, position, refId) => {
	 	var newLayoutData = this.props.layoutData;
	 	this.state.selected.map((id) => {
	 		if (id != refId) {
			 	if (dir.includes('top')) {
			 		newLayoutData[id].y = this.state.selectedInitialSizes[id].y - parseInt(delta.height);
			 	}

			 	if (dir.includes('Left') || dir.includes('left')) {
		 			newLayoutData[id].x = this.state.selectedInitialSizes[id].x - parseInt(delta.width);
			 	}

			 	newLayoutData[id].height = this.state.selectedInitialSizes[id].height + parseInt(delta.height);
		 		newLayoutData[id].width = this.state.selectedInitialSizes[id].width + parseInt(delta.width);
		 	}
	 	});

		this.setState({unsavedChanges: true})
		this.props.updateLayoutData(newLayoutData, null);
	 }

	calculateInitialLayoutHeight = (data) => {
		// initial maximum: 
		var height = 0;
		var width = 0;
		this.props.artifacts.map((a) => {
			var entry = data[a.id];
			if (entry) { 
				var bottom = entry.y + entry.height;
				if (bottom > height) {
					height = bottom;
				}
				var left = entry.x + entry.width;
				if (left > width) {
					width = left;
				}
			}
		});
		return [height, width];
	}

	updateLayoutHeight = () => {
		if (this.props.portfolioMode) return;

		var main = document.getElementById('main-content');
		if ((main.clientHeight + main.scrollTop) >= main.scrollHeight) {
			this.setState({
				layoutHeight: this.state.layoutHeight + 500
			});
		}

		if ((main.clientWidth + main.scrollLeft) >= main.scrollWidth) {
			this.setState({
				layoutWidth: this.state.layoutWidth + 500
			});
		}
	}

	updateZIndex = (refId, up) => {
		var newLayoutData = this.props.layoutData;
		newLayoutData[refId].zIndex =  up ? this.state.zIndexMax + 1 : this.state.zIndexMin - 1; 
		this.state.selected.map((id) => {
		 	newLayoutData[id].zIndex = up ? this.state.zIndexMax + 1 : this.state.zIndexMin - 1;
	 	});

		 this.setState({unsavedChanges: true})
	 	this.props.updateLayoutData(newLayoutData, null);

		this.setState({
			zIndexMin: this.state.zIndexMin - 1,
			zIndexMax: this.state.zIndexMax + 1,
		});
	}

	indicateLayoutChanged() {
		this.setState( {unsavedChanges: true} );
	}
 
	renderPanes() {
		if (!this.props.layoutData) return; //handle case where this hasn't loaded yet

		var returndata = this.props.artifacts.map((artifact) => { //Object.keys(this.props.layoutData).map((id, i) => {
			// var artifact = this.props.artifacts.filter((a) => a.id === id)[0];
			var id = artifact.id;
			if (this.props.layoutData[id] ) { 
				return (
					< >
						<Rnd key={'rnd-' + id} id={'rnd-' + id}
						  onDrag = { (e, d, id) => {this.onDrag(e, d, id)}}
						  onDragStart={ (e, d) => {this.onDragStart(e, d, id)} }
						  onDragStop={ (e, d) => {this.onDragStop(e, d, id)} }
						  onResize={ (e, dir, ref, delta, position) => {this.onResize(e, dir, ref, delta, position, id)}}
						  onResizeStart={ (e, dir, ref, delta, position) => {this.onResizeStart(e, dir, ref, delta, position, id)} }
						  onResizeStop={ (e, dir, ref, delta, position) => {this.onResizeStop(e, dir, ref, delta, position, id)} }
						  size={{width: this.props.layoutData[id].width, height: this.props.layoutData[id].height} }
						  position={{x: this.props.layoutData[id].x, y: this.props.layoutData[id].y} }
						  style={{ zIndex: this.props.layoutData[id].zIndex }}
						  onMouseDown={(event) => {this.toggleSelect(event, id)}}
						  resizeGrid={this.state.gridScale}
						  dragGrid={this.state.gridScale}
						  scale={this.props.scale / 100}
						  onMouseEnter={(event) => { 
							  	if (this.state.selected.includes(id)) {
							  		this.setState({zControlsVisible: id}); 
							  	}
							 }}
						  onMouseLeave={(event) => { 
							  		this.setState({zControlsVisible: ''}); 
							  }}
						  disableDragging={this.props.portfolioMode}
						  enableResizing={!this.props.portfolioMode}
						>	
							<div className={ (this.state.selected.indexOf(id) > -1 && !this.props.portfolioMode) ? 'multi-selected' : ''}> 
								<Pane
									artifact={artifact}
									onToggleSelect={this.props.onToggleSelect}
									selected={artifact.selected}
									selectableGrid={this.props.selectableGrid}
									disableClickArtifact={this.props.disableClickArtifact}
									hideClickIcon={false} // TODO: Should this be extracted as a prop?
									hideMetadata={this.props.hideMetadata}
									portfolioMode={this.props.portfolioMode}
									usePortfolioStyles={this.props.usePortfolioStyles}
									renderAdditionalSidebarItems={() => this.renderPaneSidebarItems(id)}
                                />
							</div> 
						</Rnd>
	      			</>
				);
			}
		});
		
		return returndata;
	}

	renderPaneSidebarItems = (id) => {
		let items = [];
		if (!this.props.portfolioMode) {
			items.push(
				<div className='pane-sidebar-item' title='To front'>
					<button
						className='icon-button'
						onClick={() => this.updateZIndex(id, true)}
					>
						<FlipToFront style={{ fontSize: '2em' }} />
					</button>
				</div>
			);
			items.push(
				<div className='pane-sidebar-item' title='To back'>
					<button
						className='icon-button'
						onClick={() => this.updateZIndex(id, false)}
					>
						<FlipToBack style={{ fontSize: '2em' }} />
					</button>
				</div>
			);
		}
		return items;
	};

	formatLayoutName(layout) {
		var name = layout.name;
		var date = formatDateForLayoutName(layout.timeCreated);
		var result;

		if (name !== '' && name != layout.timeCreated) {
			result = name + '    (' + date + ')';
		} else {
			result = date;
		}

		return result;
	}

	getLayoutOptions = () => {
		var conditions = [ {
			 field: 'team',
			 	test: '==',
			  	condition: this.props.team.id
		 	 }
		]; 

		Database.getDocumentsWhereSnapshot('layouts', conditions, (data, context) => {
			var options = data.length ?
							data.map((l) => {return {label: this.formatLayoutName(l), value: l};})
							: [];
			options.sort((o1, o2) => {
				// Alphabetically sorted.
				return o1.label.localeCompare(o2.label);
			});

			context.setState({
				layoutOptions: options.sort((a,b) => new Date(b.value.timeCreated) - new Date(a.value.timeCreated)), //.filter((l) => l.value.type === this.props.source),
				fetchedLayouts: true,
				lastTeam: this.props.team.id,
			});
		}, 
		this);
	}
	  
	render() {
		const {artifacts} = this.props;

		if (!this.props.portfolioMode) {
			if ((!this.state.fetchedLayouts) || (this.state.lastTeam != this.props.team.id)) {
				this.getLayoutOptions();
			}
		}

		return (
			<>	
				{!this.props.portfolioMode ? 			
					<div id='layout-controls' className={'section-header'}>
						<div className={'layout-section'}>
							<span>
								<span>Current layout: </span>
								{this.state.unsavedChanges? 
									<span style={ {fontStyle:'italic'} }>{ this.state.currentName+" (edited)" }</span> :
									<span> {this.state.currentName} </span>
								}
							</span>
							<button onClick={() => {
								this.setState( {currentName : DEFAULT_LAYOUT_NAME, unsavedChanges : false} );
								this.props.resetLayoutFunction()}
								}>Reset Layout</button>
						</div>
						
						<div className={'layout-section'}> 
							<span> View old layout: </span>
							<Dropdown
								isSearchable
								isDisabled={false}
								options={this.state.layoutOptions}
								onChange={(selected) => {
									var newdata = _.clone(selected.value.layoutData);
									var [height, width] = this.calculateInitialLayoutHeight(newdata);
									this.props.updateLayoutData(newdata, selected.value.id);
									this.setState({
										layoutHeight: Math.max(height, document.getElementById('main-content').clientHeight),
										layoutWidth: Math.max(width, document.getElementById('main-content').clientWidth),
										currentName: selected.label,
										unsavedChanges: false
									});
									if (this.props.loadLayoutFunction) {
										this.props.loadLayoutFunction(newdata, selected.value.visibleArtifacts, selected.value.filters);
									}
								}}
								singleLine />
						</div>

						<div className={'layout-section'}>
							<InputText
								id='layoutName'
								placeholder={"name this layout"}
								onChange={(text) => {
									this.setState( {newLayoutName: text})
								}}
								onSave={() => {
									this.setState({ currentName: this.state.newLayoutName, unsavedChanges: false });
									this.props.save(this.props.layoutData, this.props.artifacts, this.props.team.id, this.props.user.id, this.state.newLayoutName)}
								}
								saveText={"Save " + this.props.source + " Layout"}
								disabled={this.state.layoutOptions.map((l) => l.value.name).includes(this.state.newLayoutName)}
								warning={'choose a unique layout name'}
								singleLine />
						</div>

					</div>

				: null }

				<div id='layout-wrapper' ref={this.props.layoutWrapperRef} style={{
					'height' : this.state.layoutHeight + 'px',
					'width' : this.state.layoutWidth + 'px',
					'transform': 'scale(' + this.props.scale / 100.0 + ')',
					'MozTransform': 'scale(' + this.props.scale / 100.0 + ')',
					'WebkitTransform': 'scale(' + this.props.scale / 100.0 + ')',
					'transformOrigin': '0 0',
					'MozTransformOrigin': '0 0',
					'WebkitTransformOrigin': '0 0'
				}}>

					{this.renderPanes()}
				
				</div>
			</>
		);
	}
}



GalleryGrid.propTypes = {
	artifacts: PropTypes.array.isRequired, // Each artifact has optional "selected" field (bool)
	selectableGrid: PropTypes.bool.isRequired,
	scale: PropTypes.number.isRequired,
	onToggleSelect: PropTypes.function,
	selectedArtifacts: PropTypes.array,
	disableClickArtifact: PropTypes.bool,
	hideMetadata: PropTypes.bool,
	team: PropTypes.Object,
	user: PropTypes.Object,
	portfolioMode: PropTypes.bool,
	usePortfolioStyles: PropTypes.bool,
	save: PropTypes.function,
	loadLayoutFunction: PropTypes.function,
	layoutData: PropTypes.array,
	updateLayoutData: PropTypes.function,
	resetLayoutFunction: PropTypes.function,
	source: PropTypes.string
};
