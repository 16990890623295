import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import GalleryGrid from '../components/GalleryGrid';
import Database from '../scripts/Database';
import Loading from './Loading';
import kaleidoscope from '../../public/images/kaleidoscope.gif';

export class Portfolio extends Component {
	constructor(props) {
		super(props);
		this.layoutWrapperRef = React.createRef();
		this.headerRef = React.createRef();
		this.state = {
			artifacts: {},
			zoomValue: 100,
			layout: {},
			team: null,
			isLayoutFetched: false,
			isDataLoaded: false,
			isUnmounted: false,
			containerWidth: '9999px',
			contentMode: 'scroll', // 'scroll' for x-scroll by default, 'center' for center if narrow enough
		};
	}

	getLayout(cb = () => {}) {
		if (this.state.isLayoutFetched) return;
		Database.getDocumentsWhere(
			'layouts',
			'__name__',
			'==',
			this.props.layoutId,
			(data, context) => {
				if (this.state.isUnmounted) return;
				const layout = data.length ? data[0] : {};
				context.setState(
					{
						layout,
						isLayoutFetched: true,
					},
					cb
				);
			},
			this
		);
	}

	getData(cb = () => {}) {
		// We need layout.team to be able to fetch artifacts, so return early if not yet fetched.
		if (!this.state.isLayoutFetched) {
			cb();
			return;
		}
		if (this.state.isDataLoaded) return;

		let numDone = 0; // Wait until both fetches are done to call callback
		Database.getDocumentsWhere(
			'artifacts',
			'team',
			'==',
			this.state.layout.team,
			(data, context) => {
				if (this.state.isUnmounted) return;
				context.setState(
					{
						artifacts: data,
						isDataLoaded: true,
					},
					() => {
						numDone++;
						if (numDone == 2) cb();
					}
				);
			},
			this
		);
		Database.getDocumentsWhere(
			'teams',
			'__name__',
			'==',
			this.state.layout.team,
			(data, context) => {
				if (data.length == 0) {
					console.log(`Critical error: team ${this.state.layout.team} was not found!`);
					numDone++;
					if (numDone == 2) cb();
					return;
				}
				if (this.state.isUnmounted) return;
				context.setState(
					{
						team: data[0],
					},
					() => {
						numDone++;
						if (numDone == 2) cb();
					}
				);
			},
			this
		);
	}

	addFauxArtifacts = (artifacts) => {
		if (!this.state.isLayoutFetched || this.state.team == null || artifacts == null) {
			return artifacts;
		}
		const { layout, team } = this.state;
		let fauxArtifacts = [];
		for (let id in layout.layoutData) {
			if (id.indexOf('faux-team') >= 0) {
				const fauxTeam = [
					{
						id: id,
						type: 'faux-team',
						teamId: team && team.id,
						creator: {
							name: 'System',
						},
						creationTime: Date.now(),
					},
				];
				fauxArtifacts = fauxArtifacts.concat(fauxTeam);
			}
		}
		return fauxArtifacts.concat(artifacts);
	};

	setContentMode = () => {
		const el = this.layoutWrapperRef.current;
		const headerEl = this.headerRef.current;
		if (!el || !headerEl) {
			this.setState({
				contentMode: 'scroll',
			});
			return;
		}
		const contentWidth = el.offsetWidth;
		const containerWidth = headerEl.offsetWidth;
		if (contentWidth > containerWidth) {
			this.setState({
				containerWidth: `${contentWidth}px`,
				contentMode: 'scroll',
			});
		} else {
			this.setState({
				containerWidth: `${contentWidth}px`,
				contentMode: 'center',
			});
		}
	};

	// http://jsfiddle.net/9av2mfjx/
	// https://stackoverflow.com/questions/1679507/getting-all-css-used-in-html-file
	exportPortfolio() {
		var original_html = document.querySelector('html').innerHTML;
		//get rid of header by deleting it from the dom
		document.getElementById('kaleidoscope-portfolio-header').remove();
		//save html without header
		var html = document.querySelector('html').innerHTML;
		//put header back in the browser view
		document.querySelector('html').innerHTML = original_html;

		var badCSSName = 'href="/entry.87c11fe2.css';
		var index = html.indexOf(badCSSName);
		html = html.substring(0, index) + 'href="portfolio.css"' + html.substring(index + badCSSName.length);


	    var bb = new Blob([html], { type: 'text/plain' });
  		var a = document.createElement('a');
	    a.download = 'portfolio.html';
	    a.href = window.URL.createObjectURL(bb);
	    a.textContent = 'Download ready';
	    a.style='display:none';
	    a.click();  

	    var css= [];

		for (var sheeti= 0; sheeti<document.styleSheets.length; sheeti++) {
		    var sheet= document.styleSheets[sheeti];
		    var rules= ('cssRules' in sheet)? sheet.cssRules : sheet.rules;
		    for (var rulei= 0; rulei<rules.length; rulei++) {
		        var rule= rules[rulei];
		        if ('cssText' in rule)
		            css.push(rule.cssText);
		        else
		            css.push(rule.selectorText+' {\n'+rule.style.cssText+'\n}\n');
		    }
		}
		var css_string = css.join('\n');

	    var bb_css = new Blob([css_string], { type: 'text/plain' });
  		var a1 = document.createElement('a');
	    a1.download = 'portfolio.css';
	    a1.href = window.URL.createObjectURL(bb_css);
	    a1.textContent = 'Download ready';
	    a1.style='display:none';
	    a1.click();  
	}

	componentDidMount() {
		this.getLayout(() =>
			this.getData(() => {
				this.setContentMode();
				document.title = `Kaleidoscope - ${this.state.team.name}`;
				window.addEventListener('resize', () => this.setContentMode());
			})
		);
	}

	componentWillUnmount() {
		this.setState({
			isUnmounted: true,
		});
	}

	render() {
		const { team, isDataLoaded, containerWidth, contentMode } = this.state;
		// TODO: Do we want any logging via Amplitude here?
		return (
			<div id='main-content' className='portfolio-container'>
				<div id='kaleidoscope-portfolio-header' className='portfolio-header' ref={this.headerRef}>
					{/* TODO: Choose a path to route to, this is a placeholder! */}
					<div className='portfolio-header-layers'>
						<div className='portfolio-header-layer'>
							<Link to='/' className='no-border'>
								<div className='logo-title'>
									<img className='image landing' src={kaleidoscope} />
									<div className='title-subtitle'>
										<h2>CS 160 Showcase</h2>
										<h6>powered by Kaleidoscope</h6>
									</div>
								</div>
							</Link>
						</div>
						<div className='portfolio-header-layer'>
							<h3 className='portfolio-team-title'>{team ? team.name : 'Kaleidoscope'}</h3>
						</div>
						<div className='portfolio-header-layer'>
							{this.props.viewerTeams.includes(this.state.layout.team) ? 
								<button className='export-button' onClick={this.exportPortfolio}> Export Portfolio as HTML </button>
							: null }
						</div>
					</div>
				</div>
				<div className={'portfolio-content portfolio-' + contentMode}>
					<div style={{ width: containerWidth }}>{isDataLoaded ? this.renderArtifacts() : <Loading />}</div>
				</div>
			</div>
		);
	}

	renderArtifacts() {
		const { artifacts, layout, team } = this.state;
		console.log('MOLECULE: ', layout);
		if (artifacts.length === 0) {
			return (
				<p>
					This portfolio page is empty. If you're on this team, try creating a portfolio to see your work appear here!
					If not, check back soon to see this team's finished work.
				</p>
			);
		}
		const allWithFauxArtifacts = this.addFauxArtifacts(artifacts);
		const withFauxArtifacts = this.addFauxArtifacts(layout.visibleArtifacts);

		return (
			<GalleryGrid
				portfolioMode={true}
				layoutWrapperRef={this.layoutWrapperRef}
				artifacts={withFauxArtifacts}
				allArtifacts={allWithFauxArtifacts}
				scale={this.state.zoomValue}
				team={team}
				useLayoutPassthrough={true}
				layoutData={layout.layoutData}
				selectableGrid={false}
				disableClickArtifact={true}
				hideMetadata={false}
			/>
		);
	}
}

Portfolio.propTypes = {
	layoutId: PropTypes.string.isRequired,
};

export class TestPortfolio extends Component {
	render() {
		return <Portfolio layoutId='VBIbP0kqrc7bflEEhfLD' />;
	}
}
