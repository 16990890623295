import React, { Component } from 'react';
import { Octokit } from "@octokit/core";
import { Check } from '@material-ui/icons';

import GitHubIcon from '@material-ui/icons/GitHub';

import Main from '../layouts/Main';
import Database from '../scripts/Database';
import Github from '../scripts/Github';
import Dropdown from '../components/Dropdown';
import Checkbox from '../components/Checkbox';
import Loading from '../components/Loading';

import { UserContext } from '../context/UserContext';

import dotenv from "dotenv";

dotenv.config();

var CID;
if (process.env.NODE_ENV == "production"){
	CID = '43a434f95aad3bbe8900';
} else if (process.env.NODE_ENV == "development") {
	CID = '4a14af0c9571833aa1c4';
}

class GithubUploader extends Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);

		this.state = {
			repos : [], 
			selectedRepo : null,
			token: null,
			commitsForSelectedRepo: null,
			nextRepoPage: 1,
			nextCommitPage: 1,
			githubLoginFailed: false,
			commitsFailed: false
		};
	}

	componentDidMount() {
		// you've already authenticated github before and it is on your user
		if (this.context.user.githubToken && this.context.user.githubToken.length && !this.state.token) {
			this.setState({
				token: this.context.user.githubToken
			});
			// get repos from Github and populate state
			Github.getRepos(this.context.user.githubToken, 1, [], this.reposCallback, this);

		// you've never authenticated github before, so you have to click the login button and get a token
		} else if (!this.state.token) {
			const searchParams = new URLSearchParams(location.search);
			const token = searchParams.get('token');
			console.log('token: ', token, typeof token);

			if (token === 'undefined') {
				this.setState({
					githubLoginFailed: true
				});
				//window.location.href = `https://github.com/login/oauth/authorize?scope=repo&client_id=${CID}`;
			} else if (token) {
				console.log('token is: ', token);
				// add to state
				this.setState({
					token: token 
				});
				// get repos from Github and populate state
				Github.getUser(token, (result, context) => {this.userCallback(result, context, token)}, this);
				Github.getRepos(token, 1, [], this.reposCallback, this);
			}
		}
	}

	// save token and username in user db
	userCallback = (result, context, token) => {
		Database.replaceValuesInDocument(
			'users', 
			context.context.user.id, 
			{
				githubToken : token ? token : '',
				githubUsername : result.login ? result.login : ''
			}
		);
	}

	reposCallback = (result, repos, pageNumber, context) => {
		console.log('repos from fetch: ', result);
		if (result.message == 'Bad credentials') {
			this.setState({
				githubLoginFailed: true
			});
			Database.replaceValuesInDocument(
				'users', 
				context.context.user.id, 
				{
					githubToken : '',
				}
			);
		} else if (result.length > 0 && this.state.token) {
			Github.getRepos(this.state.token, pageNumber + 1, [...repos, ...result], this.reposCallback, this);
		} else {
			this.setState({
				repos: [...repos, ...result]
			});
		}
	}

	selectRepo = (repoName) => {
		var selectedRepo = this.state.repos.filter((r) => r.name === repoName.value)[0];
		this.props.updateSelectedCommits(null, null, null, true);
		var pageNumber = 1;
		this.setState({
			selectedRepo: selectedRepo
		});
		Github.getCommits(this.state.token, selectedRepo.name, selectedRepo.owner.login, pageNumber, this.commitsCallback, this);
	}

	commitsCallback = (result, context) => {
		console.log('commits: ', result);

		if (result.message) {
			context.setState({
				commitsFailed: true
			});
		} else {
			context.setState({
				commitsForSelectedRepo: result,
				nextCommitPage: 2,
				commitsFailed: false
			});
		}
	}

	loadMoreCommits = () => {
		var pageNumber = this.state.nextCommitPage;
		if (pageNumber > -1) {
			Github.getCommits(this.state.token, this.state.selectedRepo.name, this.state.selectedRepo.owner.login, pageNumber, this.loadMoreCommitsCallback, this);
		}
	}

	loadMoreCommitsCallback = (result, context) => {
		// reached end of pages
		if (result.length == 0 || result.message) {
			context.setState({
				nextCommitPage: -1
			});
		} else {
			context.setState({
				commitsForSelectedRepo: [...context.state.commitsForSelectedRepo, ...result],
				nextCommitPage: this.state.nextCommitPage + 1
			});
		}
	}

	isArtifact = (sha) => {
		var shas = this.props.existingCommitArtifacts.map((a) => {
			if (a.github_commit) {
				return a.github_commit.sha;
			}
		});
		return shas.indexOf(sha) > -1;
	}

	render() {
		return (
			<div className='integration-section'>
				{ this.state.githubLoginFailed ? 
					<div className='login-error'> Login Failed. You may have an outdated token, or the login did not go through. Please try again. </div> 
					: null
				}

				{ !this.state.token || this.state.githubLoginFailed ? 					
						<a href={`https://github.com/login/oauth/authorize?scope=repo&client_id=${CID}`}> <button> Connect to GitHub </button> </a>
					: null
				}

				{ this.state.repos.length ? 
					<div className='repo-select'>
						<h4> Select Repository </h4>
						<Dropdown
							options={this.state.repos.map(repo => repo.name)}
							onChange={this.selectRepo}
							defaultValue={null}
						/>
						<span className='repos-count'> ({this.state.repos.length} repositories) </span> 
					</div>
				: this.state.token && !this.state.githubLoginFailed ? <Loading /> : null
				}


				{ this.state.selectedRepo ? 
					this.state.commitsFailed ?
						<div> Cannot find commits for this repoistory.  It may be empty, or deleted. </div>
					:
					<div className='commits-container'>
						<table id='commit-table'>
							<thead> 
								<tr> 
									<th> Select </th> 
									<th> Author </th> 
									<th> Commit Message </th> 
									<th> Commit ID </th> 
								</tr>
							</thead>
							<tbody>
								{ this.state.commitsForSelectedRepo &&
									this.state.commitsForSelectedRepo.map((c) => {
										return (
											<tr key={'commit-'+ c.sha}>
												<td>
													{this.isArtifact(c.sha) ? <Check /> :
														<Checkbox
															checkedByDefault={false}
		    												id={c.sha}
		 	 												onChange={(id, selected) => {
		 	 													var commit = this.state.commitsForSelectedRepo.find( commit => commit.sha == id);
		 	 													this.props.updateSelectedCommits(commit, this.state.selectedRepo, selected);
		 	 												}}
		    											/>
		    										}
												 </td>
												<td> {c.author ? c.author.login : 'no author'} </td>
												<td> {c.commit.message} </td>
												<td> <a href={c.html_url} > {c.sha} </a> </td>
											</tr>

										)
									})
								}
							</tbody>  
						</table>
						<button disabled={this.state.nextCommitPage == -1} onClick={this.loadMoreCommits}> Load More Commits </button> 
					</div>
				: 
					null
				}
			</div> 
		);
	}
}

GithubUploader.propTypes = {
	updateSelectedCommits: PropTypes.function.isRequired,
	existingCommitArtifacts: PropTypes.array.isRequired
};

export default (props) => (
		<GithubUploader {...props} />
);