import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Add } from '@material-ui/icons';

class PlusButton extends Component {
	render() {
		return (
			<button className='big-icon-button' onClick={this.props.onClick}>
				<Add style={{ fontSize: '5em' }} />
			</button>
		);
	}
}

PlusButton.propTypes = {
	onClick: PropTypes.func.isRequired
};
export default PlusButton;
