import firebase from 'firebase/app';
import { firestore } from 'firebase/firestore';

var requests = 0;
const REQUEST_LIMIT = 10000;

export default class Database {
	static checkRequestCountAndErrorIfExceeded() {
		if (requests > REQUEST_LIMIT) {
			alert('Bad DB state -- please report this issue via the feedback form, and include the URL you requested.');
			throw new Error(`Database request limit (${REQUEST_LIMIT}) exceeded.`);
		}
	}
	/************
	 * Add Data *
	 ************/

	static addDocument(collection, data, callback = null, context = null) {
		this.checkRequestCountAndErrorIfExceeded();
		var collection = firebase.firestore().collection(collection);
		collection
			.add(data)
			.then(function (docRef) {
				requests++;
				console.log('DB requests total: ', requests);
				if (callback) {
					callback(docRef, context);
				}
			})
			.catch(function (error) {
				console.error('Error adding document: ', error, data);
			});
	}

	static addDocumentWithCustomID(collection, id, data, callback = null, context = null) {
		this.checkRequestCountAndErrorIfExceeded();
		var collection = firebase.firestore().collection(collection);
		collection
			.doc(id)
			.set(data)
			.then(function (docRef) {
				requests++;
				console.log('DB requests total: ', requests);
				if (callback) {
					callback(docRef, context);
				}
			})
			.catch(function (error) {
				console.error('Error adding document: ', error);
			});
	}

	/***************
	 * Remove Data *
	 ***************/

	static deleteDocument(collection, id, callback = null, context = null) {
		this.checkRequestCountAndErrorIfExceeded();
		firebase
			.firestore()
			.collection(collection)
			.doc(id)
			.delete()
			.then(function () {
				requests++;
				console.log('DB requests total: ', requests);
				console.log('Document successfully deleted!');
				if (callback) {
					callback(context);
				}
			})
			.catch(function (error) {
				console.error('Error removing document: ', error);
			});
	}

	/***************
	 * Update Data *
	 ***************/

	// static updateDocument(collection, id, data, callback = null, context = null) {
	// 	this.checkRequestCountAndErrorIfExceeded();
	// 	firebase
	// 		.firestore()
	// 		.collection(collection)
	// 		.doc(id)
	// 		.update(data)
	// 		.then(function() {
	// 			requests++;
	// 			console.log('DB requests total: ', requests);
	// 			if (callback) {
	// 				console.log('data is updated: ', data);
	// 				callback(data, context);
	// 			}
	// 		})
	// 		.catch(function(error) {
	// 			console.log('error updating documents');
	// 			console.log(error);
	// 		});
	// }

	// static addValuesToArrayInDocument(collection, id, newDataArray, field, callback = null, context = null) {
	// 	this.checkRequestCountAndErrorIfExceeded();
	// 	try {
	// 	  firebase.firestore().runTransaction(async (t) => {
	// 	  	const ref = firebase
	// 							.firestore()
	// 							.collection(collection)
	// 							.doc(id)

	// 	    const doc = await t.get(ref);
	// 	    var array = doc.data()[field] ? doc.data()[field] : [];
	// 	    array = [...array, ...newdata];
	// 	    console.log('newfield data:', array);
	// 	    await t.update(ref, {[field]: array});
	// 	    console.log('Transaction success!');
	// 		requests++;
	// 		console.log('DB requests total: ', requests);
	// 		if (callback) {
	// 			console.log('data is updated: ', newdata);
	// 			callback(newdata, context);
	// 		}
	// 	  });
	// 	} catch (e) {
	// 	  console.log('Transaction failure:', e);
	// 	  console.log('error updating documents');
	// 	}
	// }

	static addValuesToArrayInDocument(collection, id, addDataArray, field, callback = null, context = null) {
		this.checkRequestCountAndErrorIfExceeded();
		firebase
			.firestore()
			.collection(collection)
			.doc(id)
			.update(field, firebase.firestore.FieldValue.arrayUnion.apply(null, addDataArray))
			.then(function () {
				requests++;
				console.log('DB requests total: ', requests);
				if (callback) {
					callback(addDataArray, context);
				}
			})
			.catch(function (error) {
				console.log('error updating documents');
				console.log(error);
			});
	}

	static removeValuesFromArrayInDocument(collection, id, removeDataArray, field, callback = null, context = null) {
		this.checkRequestCountAndErrorIfExceeded();
		firebase
			.firestore()
			.collection(collection)
			.doc(id)
			.update(field, firebase.firestore.FieldValue.arrayRemove.apply(null, removeDataArray))
			.then(function () {
				requests++;
				console.log('DB requests total: ', requests);
				if (callback) {
					callback(removeDataArray, context);
				}
			})
			.catch(function (error) {
				console.log('error updating documents');
				console.log(error);
			});
	}

	static replaceValuesInDocument(collection, id, newDataMap, callback = null, context = null) {
		this.checkRequestCountAndErrorIfExceeded();
		try {
			firebase.firestore().runTransaction(async (t) => {
				const ref = firebase.firestore().collection(collection).doc(id);

				const doc = await t.get(ref);
				await t.update(ref, newDataMap);
				requests++;
				console.log('DB requests total: ', requests);
				if (callback) {
					callback(newDataMap, context);
				}
			});
		} catch (e) {
			console.log('Transaction failure:', e);
			console.log('error updating documents');
		}
	}

	static addToNumberValueInDocument(collection, id, newData, field, callback = null, context = null) {
		this.checkRequestCountAndErrorIfExceeded();
		try {
			firebase.firestore().runTransaction(async (t) => {
				const ref = firebase.firestore().collection(collection).doc(id);

				const doc = await t.get(ref);
				var number = doc.data()[field] ? doc.data()[field] : 0;
				number += newData;
				await t.update(ref, { [field]: number });
				requests++;
				console.log('DB requests total: ', requests);
				if (callback) {
					callback(newdata, context);
				}
			});
		} catch (e) {
			console.log('Transaction failure:', e);
			console.log('error updating documents');
		}
	}

	/***************
	 * Clear Data *
	 ***************/

	static setDocument(collection, id, data, callback = null, context = null) {
		this.checkRequestCountAndErrorIfExceeded();
		firebase
			.firestore()
			.collection(collection)
			.doc(id)
			.set(data)
			.then(function () {
				requests++;
				console.log('DB requests total: ', requests);
				if (callback) {
					callback(data, context);
				}
			})
			.catch(function (error) {
				console.log('error updating documents');
				console.log(error);
			});
	}
	/************
	 * Get Data *
	 ************/

	static getAllDocumentsOnce(collection, callback = null, context = null) {
		this.checkRequestCountAndErrorIfExceeded();
		var query = firebase.firestore().collection(collection);

		return query
			.get()
			.then(function (query) {
				requests++;
				console.log('DB requests total: ', requests);
				let result = [];
				query.forEach((doc) => {
					var id = doc.id;
					result.push({ id, ...doc.data() });
				});
				if (callback) {
					callback(result, context);
				}
			})
			.catch(function (error) {
				console.log('error getting documents');
				console.log(error);
			});
	}

	static getAllDocumentsSnapshot(collection, callback = null, context = null) {
		this.checkRequestCountAndErrorIfExceeded();
		var query = firebase.firestore().collection(collection);

		return query.onSnapshot(function (qs) {
			requests++;
			console.log('DB requests total: ', requests);
			if (callback) {
				callback(qs, context);
			}
		});
	}

	// Don't use WHERE clauses for more than 10 entries in the condition array if using the in-array condition
	static getDocumentsWhere(collection, conditionfield, conditiontest, condition, callback = null, context = null) {
		this.checkRequestCountAndErrorIfExceeded();
		firebase
			.firestore()
			.collection(collection)
			.where(conditionfield, conditiontest, condition)
			.get()
			.then((query) => {
				requests++;
				console.log('DB requests total: ', requests);
				var results = [];
				query.forEach((doc) => {
					var id = doc.id;
					results.push({ id, ...doc.data() });
				});
				if (callback) {
					callback(results, context);
				}
			})
			.catch(function (error) {
				console.log('Error getting documents: ', error);
			});
	}

	static getDocument(collection, id, callback = null, context = null) {
		this.checkRequestCountAndErrorIfExceeded();
		firebase
			.firestore()
			.collection(collection)
			.doc(id)
			.get()
			.then((docRef) => {
				requests++;
				console.log('DB requests total: ', requests);
				if (callback) {
					var id = docRef.id;
					var document = { id, ...docRef.data() };
					callback(document, context);
				}
			})
			.catch((error) => {
				alert('error getting document');
				console.log('Error getting document:', error);
				if (callback) {
					callback(null, context);
				}
			});
	}

	static getUserData(id, callback = null, context = null) {
		this.checkRequestCountAndErrorIfExceeded();
		firebase
			.firestore()
			.collection('users')
			.doc(id)
			.get()
			.then((docRef) => {
				if (callback) {
					var id = docRef.id;
					var document = { id: id, data: docRef.data() };
					callback(document, context);
				}
			})
			.catch((error) => {
				alert('error getting document');
				console.log('Error getting document:', error);
				if (callback) {
					callback(null, context);
				}
			});
	}

	static getDocuments(collection, ids, callback = null, context = null) {
		this.checkRequestCountAndErrorIfExceeded();
		if (!!ids && ids.length !== 0) {
			var setup = firebase.firestore().collection(collection);

			var promises = [];
			for (var i = 0; i < ids.length; i += 10) {
				console.log('i: ', i);
				var p = new Promise((resolve, reject) => {
					setup
						.where(firebase.firestore.FieldPath.documentId(), 'in', ids.slice(i, i + 10))
						.get()
						.then((query) => {
							let result = [];
							console.log('resolved: ', i, query);
							query.forEach((doc) => {
								var id = doc.id;
								result.push({ id, ...doc.data() });
							});
							console.log(result);
							resolve(result);
						});
				}).catch((error) => {
					console.log('Error getting documents:', error);
					if (callback) {
						callback([], context);
					}
					reject(error);
				});
				promises.push(p);
			}

			Promise.all(promises).then((values) => {
				console.log('promises all resolving: ', values);
				var result = [].concat.apply([], values);
				if (callback) {
					callback(result, context);
				}
			});
		} else {
			callback([], context);
		}
	}

	// Don't use WHERE clauses for more than 10 entries in the condition array if using the in-array condition
	static getDocumentsWhereSnapshot(collection, conditions, callback = null, context = null) {
		this.checkRequestCountAndErrorIfExceeded();
		/* conditions:
		 * [ {
		 * 	field:
		 * 	test:
		 * 	condition:
		 * },
		 * ] */
		var query = firebase.firestore().collection(collection);

		for (var i = 0; i < conditions.length; i++) {
			query = query.where(conditions[i].field, conditions[i].test, conditions[i].condition);
		}

		query.onSnapshot((querySnapshot) => {
			requests++;
			console.log('DB requests total: ', requests);
			var results = [];
			querySnapshot.forEach((doc) => {
				var id = doc.id;
				results.push({ id, ...doc.data() });
			});
			if (callback) {
				callback(results, context);
			}
		});
	}

	static getsubmittedPRCheckInArtifacts(callback = null, context = null) {
		this.checkRequestCountAndErrorIfExceeded();
		firebase
			.firestore()
			.collection('artifacts')
			.where('type', '==', 'report')
			.where('tags', 'array-contains', 'PROJECT TEASER')
			.where('submitted', '==', true)
			.get()
			.then((query) => {
				requests++;
				console.log('DB requests total: ', requests);
				var results = [];
				query.forEach((doc) => {
					var id = doc.id;
					results.push({ id, ...doc.data() });
				});
				if (callback) {
					callback(results, context);
				}
			})
			.catch(function (error) {
				console.log('Error getting documents: ', error);
			});
	}
}
