import { Dashboard, Feedback, FileCopy, Person, PostAdd, Publish, Warning, Explore, TurnedIn } from '@material-ui/icons';

const data = [
	{
		id: 'upload',
		text: 'Add Artifact',
		icon: <Publish />,
		path: '/upload',
		restricted: false
	},
	{
		id: 'gallery',
		text: 'Studio',
		icon: <Dashboard />,
		path: '/studio',
		restricted: false
	},
	// {
	// 	id: 'dashboard',
	// 	text: 'Dashboard',
	// 	icon: <BarChart />,
	// 	path: '/dashboard',
	// 	restricted: false
	// },
	{
		id: 'assignments',
		text: 'Check-ins',
		icon: <FileCopy />,
		path: '/checkins',
		restricted: false
	},
	{
		id: 'explore',
		text: 'Explore',
		icon: <Explore />,
		path: '/explore',
		restricted: false
	},
	{
		id: 'portfolioCreator',
		text: 'Portfolio',
		icon: <TurnedIn />,
		path: '/create-portfolio',
		restricted: false
	},
	{
		id: 'profile',
		text: 'Profile',
		icon: <Person />,
		path: '/profile',
		restricted: false
	},
	{
		id: 'admin',
		text: 'Admin Panel',
		icon: <Warning />,
		path: '/adminpanel',
		restricted: true
	}//,
	/*
	{
		id: 'feedback',
		text: 'Share Feedback',
		icon: <Feedback />,
		link: 'https://forms.gle/eQ4R9LkR9pt2s3s38',
		restricted: false
	}
	*/
];

export default data;
