// Maps PR Check-In Artifact ID --> Portfolio ID
const portfolioMap = {
	CJe2dFjG7id4nnhHQQjQ: 'Y7W2DYid2MNfIVcmASaT',
	TfLirdatGcXhgKuJmXFK: 'MXZajgIDlfg4XQlK73fn',
	osfnhgbkohDREZ5cSpOI: 'l33t0Dwu6x2KLjtzGfHa',
	Q6G7lmWd7W5ngEuVVQmk: 'DLxcEIbC4M3os9sLvy7G',
	'8FsKm6RGeD6Q8RWfOgQN': 'U3Dh9sAUrI3cGLWySysx',
	RtSU2qhawljrANMntXDf: 'keg5otcwSioR8NMHXHDC',
	Fe96ZwQQf7yVpBtg7MGX: 'lif9HHdtMLf8bGDjZdH1',
	Wbd0b5RZjpm9IAv6m682: 'eaWzZTQzxI5vtzGGJ1u6',
	A4hyFHWdXbQrdenI3jWa: 'E7v3k5YZtmwxxUvD1lNa',
	'8jSji24V6h7RA2WAVZ8A': 'yDvFfj53To1aSGq6sDG5',
	xNIBKbecm8vH8q5QsRGY: 'e8Ywr3QdZlu1zfoCHyj5',
	ksu5OT0k2Vyh3vakA1W8: '0BIPuLNHwu0accxlt8GT',
	ZvuA3GFqwC7NYSMxXvw4: 'gDvDdFuYosRS3wglJWD2',
	nOBjVjGEzUbH6r35fkHU: 'Xxa3z9Xeh1KF8xfFbSnA',
	GPuwUwifCyt8kxbquHDW: 'qF0oppTQXoIto9I8swrm',
	Jyu3HBFVDMPQrU7ytJ4Q: 'WfgdCIr7hAk1aWCFfbUr',
	fwrKkIGaJ5l5LKTQbi7z: 'J8AkJjm4ucEZcDVkiaDs',
	TbW5idfpPOYYSKZrgPb4: 'b6X2TDrw2TQ3D38ZmfBn',
	HpUUl5N6p5QtU3NyaeJ4: 'Xm31vayx29S6InGsOUY7',
	hyyn1jq5Gl4qw6hbdKAE: 'FJhOyVpV15N9g8klGFux',
	'5BmoCA5aCmbGfF5RkhFf': 'UrMersachZgfPmzy4oMn',
	aUHTKiDdbdrYuFxPJeLi: 'KYSb5dfP5RjwhbG9DSQt',
	bylOJzsSREfwg5OCCb5A: '5GZ6gZuR91sq2Jf3HjsJ',
	// THE FOLLOWING ARE DEV ENV ARTIFACTS
	'5tAh84Qz6nDiI0tvYj4A': 'kofYXIr24Thgf1zgCcxx',
};
export default portfolioMap;
