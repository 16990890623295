import { Portfolio } from "../components/Portfolio";
import Main from '../layouts/Main';
import React, { Component } from 'react';
import * as firebase from 'firebase';
import Database from '../scripts/Database';


class PortfolioPage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			userInfo: null
		};
	}

	componentDidMount() {
		firebase.auth().onAuthStateChanged((firebaseUser) => {
			if (firebaseUser && firebaseUser.uid) {
				Database.getUserData(
					firebaseUser.uid,
					(user, context) => {
						if (user) {
							context.setState({ userInfo: { ...user.data, id: user.id } });
						}
					},
					this
				);
			} 
		});
	}

	render() {
		return (
			<Portfolio layoutId={this.props.layoutId} viewerTeams={this.state.userInfo ? this.state.userInfo.teams : []}/>
		)
	}
}

export default (props) => (
	<PortfolioPage layoutId={props.match.params.id}/>
);
