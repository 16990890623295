import React, { Component } from 'react';
import Moment from 'react-moment';
import TextEditor from '../components/TextEditor';


class Comment extends Component {
	constructor(props) {
		super(props);
	}
	
	render() {
			return (
				<div className='comment'>
					<div className='comment-metadata'> 
						<span className='comment-author'> {this.props.comment.authorName} </span>
						|
						<span className='comment-date'> <Moment fromNow>{this.props.comment.createdAt}</Moment> </span>
					</div>
					<div className='comment-text'> 
						{this.props.comment.richtextFeedback ? (
							<TextEditor 
								id='pane-text-artifact'
								readOnly = {true}
								initialText = {JSON.parse(this.props.comment.richtextFeedback)}
								/>
						) : ( 
							this.props.comment.text)
						}
					</div> 
				</div>
			);
	}
}

Comment.propTypes = {
	comment: PropTypes.object.isRequired,
};


export default Comment;
