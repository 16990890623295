import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Editor, EditorState, ContentState, convertToRaw} from 'draft-js';
import { DraftailEditor, BLOCK_TYPE, INLINE_STYLE } from "draftail";

class TextEditor extends Component {
  constructor(props) {
    super(props);

    var initialText = this.props.initialText;
    if (typeof this.props.initialText === "string") {
      initialText = convertToRaw(ContentState.createFromText(this.props.initialText));
    } 

    this.state = {
      initial: initialText ? initialText : "",
      editorState: EditorState.createEmpty()
    };
    this.onChange = editorState => this.setState({editorState});
  }

  onSave = (content) => {
    if (this.props.onChange) {
      this.props.onChange(JSON.stringify(content));
    }
  }


  render() {
    return (
        <div id={this.props.id}>
          <DraftailEditor
            key={this.props.key}
            rawContentState={this.state.initial || null}
            onSave={this.onSave}
            readOnly={this.props.readOnly}
            blockTypes={[
              { type: BLOCK_TYPE.HEADER_ONE },
              { type: BLOCK_TYPE.HEADER_TWO },
              { type: BLOCK_TYPE.HEADER_THREE },
              { type: BLOCK_TYPE.HEADER_FOUR },
              { type: BLOCK_TYPE.BLOCKQUOTE },
              { type: BLOCK_TYPE.CODE },
              { type: BLOCK_TYPE.ORDERED_LIST_ITEM },
              { type: BLOCK_TYPE.UNORDERED_LIST_ITEM }
            ]}
            inlineStyles={[
              { type: INLINE_STYLE.BOLD }, 
              { type: INLINE_STYLE.ITALIC },
              { type: INLINE_STYLE.UNDERLINE },
              { type: INLINE_STYLE.STRIKETHROUGH }

            ]}
          />
        </div>
    );
  }
}

TextEditor.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool.isRequired,
  initialText: PropTypes.object,
  key: PropTypes.number
};

export default TextEditor;
