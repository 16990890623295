import React, { Component } from 'react';
import { Amplitude } from '@amplitude/react-amplitude';

import Main from '../layouts/Main';
import Gallery from '../components/Gallery';

import { updateQueryParams, readQueryParams } from '../scripts/URLQueries';

class Explore extends Component {
	render() {
		return (
			<Main selectedSidebarItem='explore'>
				<Amplitude
					eventProperties={(inheritedProps) => ({
						...inheritedProps,
						scope: [ ...inheritedProps.scope, 'explore-page' ],
					})}
				>
					<div className='explore-wrapper'>
						<Gallery
							sourceIsExplore
							readQueryParams={readQueryParams}
							updateQueryParams={(field, valueList) => {
								updateQueryParams(field, valueList, this);
							}}
						/>
					</div>
				</Amplitude>
			</Main>
		);
	}
}

export default Explore;
