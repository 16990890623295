import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Amplitude } from '@amplitude/react-amplitude';
import { logEvent } from 'amplitude-js';

import { Link } from 'react-router-dom';
import SidebarItem from './SidebarItem';
import { Menu } from '@material-ui/icons';

import { UserContext } from '../../context/UserContext';
import data from '../../data/sidebar';
import kaleidoscope from '../../../public/images/kaleidoscope.png';
import { Feedback } from '@material-ui/icons';

class Sidebar extends Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);

		this.state = {
			menuCollapsed: JSON.parse(localStorage.getItem('menuCollapsed'))
		};
	}

	toggleMenu = () => {
		localStorage.setItem('menuCollapsed', !this.state.menuCollapsed);
		this.setState({ menuCollapsed: !this.state.menuCollapsed });
	};

	render() {
		const { selectedSidebarItem } = this.props;
		const { menuCollapsed } = this.state;
		const { user } = this.context;
		return (
			<div id='sidebar'>
				<div className='sidebar-top'>
					<span className='sidebar-item'>
						<span className='icon'>
							<button className='clickable-icon' onClick={this.toggleMenu}>
								<Menu />
							</button>
						</span>
						{!menuCollapsed && (
							<Link className='index-link label' key={'Kaleidoscope'} to={user ? '/studio' : '/'}>
								<h6>Kaleidoscope</h6>
								<img className='image header' src={kaleidoscope} />
							</Link>
						)}
					</span>
					{data.map((item) => {
						if (!item.restricted || (user && user.role === 'instructor')) {
							return (
								<Amplitude
									eventProperties={(inheritedProps) => ({
										...inheritedProps,
										scope: [ ...inheritedProps.scope, 'sidebar' ],
										item_id: item.id
									})}
								>
									{({ logEvent }) => (
										<SidebarItem
											id={item.id}
											text={item.text}
											icon={item.icon}
											link={item.link}
											path={item.path}
											selected={selectedSidebarItem === item.id}
											collapsed={menuCollapsed}
											onClick={() => {
												logEvent('click sidebar item');
											}}
										/>
									)}
								</Amplitude>
							);
						}
					})}
				</div>
				{!menuCollapsed && (
					<div className='sidebar-footer'>
						<span className='sidebar-item'>
							<Amplitude
								eventProperties={(inheritedProps) => ({
									...inheritedProps,
									scope: [ ...inheritedProps.scope, 'sidebar' ]
								})}
							>
								{({ logEvent }) => (
									<div>
										<a href="https://forms.gle/eQ4R9LkR9pt2s3s38" target='_blank' onClick={this.props.onClick} id='feedback-link'>
											Send App Feedback
										</a>

										<button
											id='signout-button'
											onClick={() => {
												logEvent('sign out');
												this.props.handleSignOut();
											}}
										>
											Sign Out
										</button>
									</div>
								)}
							</Amplitude>
						</span>
					</div>
				)}
			</div>
		);
	}
}

Sidebar.propTypes = {
	selectedSidebarItem: PropTypes.string,
	handleSignOut: PropTypes.func.isRequired
};

export default Sidebar;
