import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Amplitude } from '@amplitude/react-amplitude';
import { logEvent } from 'amplitude-js';

import { UserContext } from '../context/UserContext';
import Database from '../scripts/Database';
import Main from '../layouts/Main';

import { replaceValuesInListOfArtifacts } from '../scripts/Utilities';
import { makeTeam } from '../scripts/Students';
import InputText from '../components/InputText';

class AdminPanel extends Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);

		this.state = {
			publicmessage: '',
			submitmessage: ''
		};
	}

	cleanIds(id_csv) {
		var id_list = id_csv.replace(/\s/g, '').split(',');
		return id_list;
	}

	makePublic = (publicbool) => {
		var id_csv = document.getElementById('ids-for-public').value;
		var id_list = this.cleanIds(id_csv);

		replaceValuesInListOfArtifacts(id_list, { public: publicbool }, this, () => {
			var message = 'artifacts set to private';
			if (publicbool) {
				message = 'artifacts set to public';
			}
			this.setState({
				publicmessage: message
			});
		});
	};

	setSubmitted = (submitBool) => {
		var id_csv = document.getElementById('ids-for-submitted').value;
		var id_list = this.cleanIds(id_csv);
		console.log(id_list);

		Database.getDocuments(
			'artifacts',
			id_list,
			(results, context) => {
				results.map((result) => {
					if (result.type == 'report') {
						console.log('report: ', result);
						var subartifactIds = result.artifactEntries.map((ae) => {
							console.log(ae.artifactID);
							return ae.artifactID;
						});
						console.log('subartifactIds: ', subartifactIds);
						id_list = id_list.concat(subartifactIds);
					}
				});

				console.log(id_list);

				replaceValuesInListOfArtifacts(id_list, { submitted: submitBool }, this, () => {
					console.log('change complete');
					var message = 'artifacts set to not submitted';
					if (submitBool) {
						message = 'artifacts set to submitted';
					}
					this.setState({
						submitmessage: message
					});
				});
			},
			this
		);
	};

	render() {
		const { user } = this.context;
		console.log('user:', user);
		if (user && user.role === 'instructor') {
			return (
				<Amplitude
					eventProperties={(inheritedProps) => ({
						...inheritedProps,
						scope: [ ...inheritedProps.scope, 'admin-panel' ]
					})}
				>
				{({ logEvent }) => (
					<div>
						<h1 style={{color : 'red' }}> WARNING: THINGS YOU DO HERE MAY MODIFY THE DATABASE </h1>

						<div className='admin-section'>	
							<h2> Set List of Artifacts to Public </h2>		
							<h3> Comma-separated ids </h3>		
							<textarea  id='ids-for-public' placeholder='id, id, id...'/>
							<button onClick={() => {logEvent('make public'); this.makePublic(true);}}> Make Public </button>
							<button onClick={() => {logEvent('make private'); this.makePublic(false);}}> Make Private </button>
							<div> {this.state.publicmessage} </div>
						</div>

						<div className='admin-section'>	
							<h2> Set List of Artifacts to "Submitted" </h2>		
							<h3> Comma-separated ids </h3>		
							<h4> If these artifacts are reports, the sub-artifacts will also be marked as "submitted" </h4>		
							<textarea  id='ids-for-submitted' placeholder='id, id, id...'/>
							<button onClick={() => {logEvent('make submitted'); this.setSubmitted(true);}}> Mark Submitted </button>
							<button onClick={() => {logEvent('remove submitted'); this.setSubmitted(false);}}> Remove Submitted </button>
							<div> {this.state.submitmessage} </div>
						</div>

						<div className='admin-section'>
							<h2> Create A Student Group </h2>
							<p>
								You can create multiple teams at a time with this section. You cannot edit student groups
								after they are created, but you can message Janaki to help make any changes.
							</p>
							<p>
								The first line of your input should be a team name. The subsequent lines should be a
								newline-separated list of student emails. Put two newlines between information for different
								teams. For example, the following input would create two teams, "Team Sarah and Molly" with
								Sarah and Molly, and "TA Team" with Kevin, Srishti, and Janaki.
							</p>
							<p>
								Team Sarah and Molly<br />ssterman@berkeley.edu<br />molecule@berkeley.edu<br />
								<br />TA Team<br />rutian@berkeley.edu<br />srishtigoswamy@berkeley.edu<br />janaki.vivrekar@berkeley.edu
							</p>
							<InputText
								id='student-group-input'
								placeholder='Enter student group information here, as described above.'
								onSave={makeTeam}
								saveText={'Enter'}
							/>
						</div>
					</div>
				)}
				</Amplitude>
			);
		} else {
			return <div> </div>;
		}
	}
}

export default (props) => (
	<Main selectedSidebarItem='admin'>
		<AdminPanel {...props} />
	</Main>
);
