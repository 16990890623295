import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TabbedButtons extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedOption: this.props.selectedOption || this.props.optionLabels[0]
		};
	}

	handleSwitchSelectedOption = (event) => {
		const newSelectedOption = event.target.id;
		this.setState({ selectedOption: newSelectedOption });
		this.props.onSelectOption(newSelectedOption);
	};

	render() {
		return (
			<div className='tabbed-buttons'>
				{this.props.optionLabels.map((optionLabel) => (
					<button
						id={optionLabel}
						className={optionLabel === this.state.selectedOption ? 'selected tab' : 'tab'}
						onClick={this.handleSwitchSelectedOption}
					>
						{optionLabel}
					</button>
				))}
			</div>
		);
	}
}

TabbedButtons.propTypes = {
	optionLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
	selectedOption: PropTypes.string,
	onSelectOption: PropTypes.func.isRequired
};
export default TabbedButtons;
