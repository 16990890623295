import React, { Component } from 'react';
import PropTypes from 'prop-types';

import GitCommit from '../components/GitCommit';
import Report from '../components/Report';
import LinkArtifact from '../components/Link';
import TextArtifact from '../components/TextArtifact';
import TextEditor from '../components/TextEditor';
import TeamMembersFauxArtifact from '../components/TeamMembersFauxArtifact';

import FigmaEmbed from 'react-figma-embed';
import ReactPlayer from 'react-player';

import { Link, GitHub, LibraryAddCheck } from '@material-ui/icons';
import FigmaLogo from '../../public/images/figma-1.svg';


class ArtifactContent extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		// FIXME: Do any other artifacts need to be made un-interactive? Possibly links, but not sure if that makes sense.
		const interactiveStyle = this.props.disableInteraction ? {'pointer-events': 'none'} : null;
		switch (this.props.artifact.type) {
			case 'image':
				return <img className='paneimg' src={this.props.artifact.url} alt={this.props.artifact.annotations}/>;
			case 'text':
				if (this.props.artifact.richtext && this.props.artifact.richtext != {} ) {
					return (<div className='pane-text'>
								<TextArtifact
									id='pane-text-artifact'
									artifact={this.props.artifact}
									readOnly={this.props.hideEditBar}
									defaultText={this.props.artifact.richtext? this.props.artifact.richtext : this.props.artifact.text}
								/>	
							</div>);
				} else {
					return <div className='pane-text'> {this.props.artifact.text} </div>;
				}
			case 'commit':
				return (
					<div className='pane-text'>
						<div>
							<GitHub style={{ alignSelf: 'flex-end', 
								height: this.props.hideMetadata ? '1em' : '3em',
								width: this.props.hideMetadata ? '1em' : '3em' }} />
							<GitCommit artifact={this.props.artifact} />
						</div>
					</div>
				);
			case 'report':
				return (
					<>
					<LibraryAddCheck />
					<Report 
						report={this.props.artifact}
						hideMetadata={this.props.hideMetadata}
						hideEditBar={this.props.hideEditBar} />
					</>
				) 

			case 'figma':
				return (
					<>
					{this.props.hideMetadata
					? (
						<>
						<img src={FigmaLogo} alt="Figma Logo" style={{width:'1em'}}/>
						<LinkArtifact artifact={this.props.artifact} style={{fontSize:'4pt'}}/>
						</>
					) : (
						<>
						<FigmaEmbed className='panefigma' url={this.props.artifact.linkURL} style={interactiveStyle}/>
						<LinkArtifact artifact={this.props.artifact} />
						</>
					)
					}
					</>
				)
			case 'video':
				return (
					<div className='player-wrapper' style={interactiveStyle}>
						{this.props.hideMetadata 
						? // show only play icon by setting light to true.
							<ReactPlayer 
								url={this.props.artifact.linkURL}
								width='100%'
								height='100%'
								light='true'/> 
						: (
							<div>
							<ReactPlayer 
								url={this.props.artifact.linkURL}
								width='100%'
								height='360px'/> 
							<LinkArtifact artifact={this.props.artifact} />
							</div>
						  )
					}
						
					</div>
				)
			case 'link':
				return (
					<div className="pane-link">
						{this.props.hideMetadata
						?
							<>
							<Link style={{ alignSelf: 'flex-end', 
								height: '1em',
								width: '1em' }} />
							<LinkArtifact artifact={this.props.artifact} style={{fontSize:'4pt'}}/>
							</>
						:
							<>
							<Link style={{ alignSelf: 'flex-end', 
								height: '4em',
								width: '4em' }} />
							<LinkArtifact artifact={this.props.artifact} />
							</>
						}
						</div>
				)
			case 'faux-team':
				return (
					<TeamMembersFauxArtifact teamId={this.props.artifact.teamId} />
				)
			default:
				return <div> Nothing to see here </div>;
		}
	}
}

ArtifactContent.propTypes = {
	artifact: PropTypes.object.isRequired,
	hideMetadata: PropTypes.bool,
	hideEditBar: PropTypes.bool
};

export default ArtifactContent;
