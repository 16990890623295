import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Add, Check, Close } from '@material-ui/icons';

class SelectButton extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: this.props.selected,
			Icon: this.props.selected ? Check : Add
		};
	}

	hover = () => {
		if (this.state.selected) {
			this.setState({ Icon: Close });
		}
	};

	stopHover = () => {
		if (this.state.selected) {
			this.setState({ Icon: Check });
		}
	};

	click = (e) => {
		this.setState({
			selected: !this.state.selected,
			Icon: this.state.selected ? Add : Check
		});
		this.props.onClick(e);
	};

	render() {
		const { Icon } = this.state;
		return (
			<button
				className='icon-button'
				onClick={this.click}
				onMouseEnter={this.hover}
				onMouseLeave={this.stopHover}
			>
				<Icon style={{ fontSize: '2em' }} />
			</button>
		);
	}
}

SelectButton.propTypes = {
	onClick: PropTypes.func.isRequired,
	selected: PropTypes.bool.isRequired
};
export default SelectButton;
