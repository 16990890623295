import React, {Component} from 'react';
import Database from '../scripts/Database';

export default class TeamMembersFauxArtifact extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isDataLoaded: false,
			team: null,
			contributors: []
		};
	}

	getData = () => {
		const {teamId} = this.props;
		Database.getDocumentsWhere('teams', '__name__', '==', teamId, (data, context) => {
			if (data.length == 0) {
				console.log(`Critical error: team ${teamId} was not found!`);
				return;
			}
			const team = data[0];
			Database.getDocuments('users', team.contributors, (users, context) => {
				context.setState({
					isDataLoaded: true,
					team: team,
					contributors: users
				})
			}, context);
		}, this);
	};

	render() {
		const {isDataLoaded, contributors} = this.state;
		if (!isDataLoaded) {
			this.getData();
		}
		return (
			<div className='team-faux-artifact'>
				{contributors.map(user => (
					<div>
						<img
							key={user.id}
							className='studio-profile-image'
							src={user.imageURL}
							alt={`profile picture of ${user.name}`}
							title={user.name}
						/>
						<p>{user.name}</p>
					</div>
				))}
			</div>
		);
	}
}
