import React, { Component } from 'react';
import { Amplitude } from '@amplitude/react-amplitude';

import Main from '../layouts/Main';
import Gallery from '../components/Gallery';

import { updateQueryParams, readQueryParams } from '../scripts/URLQueries';

class GalleryPage extends Component {
	render() {
		return (
			<Main selectedSidebarItem='gallery'>
				<Amplitude
					eventProperties={(inheritedProps) => ({
						...inheritedProps,
						scope: [ ...inheritedProps.scope, 'studio-page' ],
					})}
				>
				<Gallery
					readQueryParams={readQueryParams}
					updateQueryParams={(field, valueList) => {
						updateQueryParams(field, valueList, this);
					}}
					disableClickArtifact={true}
				/>
				</Amplitude>
			</Main>
		);
	}
}

export default GalleryPage;
