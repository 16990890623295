import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Check, Close } from '@material-ui/icons';

class Tag extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedIcon: Check
		};
	}

	renderUnselectIcon = () => {
		this.setState({ selectedIcon: Close });
	};

	renderSelectedIcon = () => {
		this.setState({ selectedIcon: Check });
	};

	render() {
		const { tag } = this.props;
		if (this.props.onClick) {
			return (
				<button
					className={this.props.selected ? 'tag selected clickable' : 'tag clickable'}
					onClick={() => this.props.onClick(tag)}
					onMouseEnter={this.renderUnselectIcon}
					onMouseLeave={this.renderSelectedIcon}
				>
					<div className='tag-content'>
						{tag}
						{this.props.selected ? (
							<div className='icon'>{<this.state.selectedIcon fontSize='inherit' />}</div>
						) : null}
					</div>
				</button>
			);
		} else {
			return(
				<div className='tag'>
					<div className='tag-content'>
						{tag}
						{this.props.selected ? (
							<div className='icon'>{<this.state.selectedIcon fontSize='inherit' />}</div>
						) : null}
					</div>	
				</div>	
			);
		}
	}
}

Tag.propTypes = {
	onClick: PropTypes.func.isRequired,
	selected: PropTypes.bool.isRequired,
	tag: PropTypes.string.isRequired
};
export default Tag;
