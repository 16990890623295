import React, { Component } from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';

class Loading extends Component {
	render() {
		return (
			<div id='loading-inner-wrapper'>
				<ScaleLoader css={{ margin: 'auto' }} radius={0} />
			</div>
		);
	}
}

export default Loading;
