import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Main from '../layouts/Main';
import AssignmentEntry from '../layouts/AssignmentEntry';
import Database from '../scripts/Database';
import { UserContext } from '../context/UserContext';
import Loading from '../components/Loading';
import StudentAssignments from './StudentAssignments';

class Assignments extends Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);
		this.state = {
			templates: []
		};
	}

	componentDidMount() {
		console.log('mounted');
		Database.getAllDocumentsSnapshot('templates', this.templatesCallback, this);
	}

	templatesCallback(qs, context) {
		var list = [];
		console.log(qs);
		qs.forEach((doc) => {
			console.log(doc);
			var data = doc.data();
			data.id = doc.id;
			list.push(data);
		});
		context.setState({ templates: list });
		console.log(list);
	}

	render() {
		const { user } = this.context;

		if (user) {
			const userIsInstructor = user.role === 'instructor';
			if (userIsInstructor) {
				return (
					<div>
						<Link to={'/create-template'}>
							<button> + Create New Template </button>
						</Link>
						{this.state.templates.map((t) => {
							return <AssignmentEntry template={t} />;
						})}
					</div>
				);
			} else {
				return <StudentAssignments />;
			}
		} else {
			return <Loading />;
		}
	}
}

export default (props) => (
	<Main selectedSidebarItem='assignments'>
		<Assignments {...props} />
	</Main>
);
