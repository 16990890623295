import React, { Component } from 'react';
import Moment from 'react-moment';
import { ChatBubble, Close, KeyboardBackspace } from '@material-ui/icons';
import { Amplitude } from '@amplitude/react-amplitude';
import Main from '../layouts/Main';
import Database from '../scripts/Database';
import InputText from '../components/InputText';
import TextEditor from '../components/TextEditor';
import TagSelectorDropdown from '../components/TagSelectorDropdown';
import ArtifactContent from '../components/ArtifactContent';
import CommentsBlock from '../components/CommentsBlock';
import Annotation from '../components/Annotation';
import Modal from 'react-modal';
import Gallery from '../components/Gallery';
import GalleryStack from '../components/GalleryStack';
import { UserContext } from '../context/UserContext';
import Loading from '../components/Loading';
import testData from '../data/testData';
import Checkbox from '../components/Checkbox';
import { replaceValuesInListOfArtifacts, deleteArtifact, extractTags, generatePlainTextFromRichText } from '../scripts/Utilities';
import Tag from '../components/Tag';
import PlusButton from '../components/PlusButton';

Modal.setAppElement('#root');

class ArtifactDetail extends Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);

		this.state = {
			artifact: null,
			selectedTags: [],
			allTags: {},
			associatedArtifacts: [],
			isDataLoaded: false,
			userCannotEditArtifact: true,
			allowDelete: false,
			artifactTeam: null,
			userIsTeamMember: false,
			artifactIsPublic: false,
			modalIsOpen: false
		};
	}

	getData(forceDataUpdate = false) {
		// To load fake test data, uncomment the block below and comment out the other code in this function.
		/*this.setState({
			artifact: testData[0],
			allTags: [ 'test', 'tags', 'more', 'to', 'do', 'testing' ]
		});*/
		if ((!this.state.isDataLoaded || forceDataUpdate) && this.context.user) {
			const { teams } = this.context.user;
			// Get the artifact
			Database.getDocument(
				'artifacts',
				this.props.match.params.id,
				(artifact, context) => {
					// Check that artifact exists and user has permissions to view
					if (
						(artifact && teams.includes(artifact.team)) ||
						this.context.user.role == 'instructor' ||
						artifact.public
					) {
						// Get the team the artifact belongs to
						Database.getDocument(
							'teams',
							artifact.team,
							(result, context) => {
								context.setState({ artifactTeam: result });
								Database.getDocumentsWhere(
									'artifacts',
									'team',
									'==',
									result.id,
									(result, context) => {
										context.setState({
											allTags: extractTags(result)
										});
									},
									context
								);
							},
							context
						);
						
						Database.getDocuments(
							'artifacts',
							artifact.with,
							(artifacts, context) => {
								var flagged = false;
								if (artifact.flaggedForFeedback) {
									flagged = true;
								}
								context.setState({
									artifact: artifact,
									selectedTags: artifact.tags,
									associatedArtifacts: artifacts,
									isDataLoaded: true,
									flagged: flagged,
									userIsTeamMember: this.context.user.teams.includes(artifact.team),
									artifactIsPublic: artifact.public
								});
							},
							context
						);
					} else {
						context.setState({ userCannotViewArtifact: true });
					}
				},
				this
			);
		}
	}

	componentDidUpdate() {
		if (this.state.artifact && this.props.match.params.id != this.state.artifact.id) {
			this.getData(true);
		}
	}

	toggleFlagForFeedback = (logEvent) => {
		var data = { flaggedForFeedback: !this.state.flagged };
		logEvent('toggle feedback flag', {
			toggle_feedback_on: data.flaggedForFeedback
		});
		Database.replaceValuesInDocument('artifacts', this.state.artifact.id, data, this.flagCallback, this);
	};

	flagCallback = (data, context) => {
		context.setState({
			flagged: data.flaggedForFeedback
		});
	};

	onChangeTags = (logEvent, selectedTags) => {
		const tagDifference = selectedTags.length - this.state.artifact.tags.length;
		logEvent('update artifact tags', {
			tag_count_increased: tagDifference > 0,
			updated_tag_count: selectedTags.length
		});
	
		Database.replaceValuesInDocument('artifacts', this.state.artifact.id, {
			tags: selectedTags
		});
		
		this.setState({ artifact: { ...this.state.artifact, tags: selectedTags } });
	};

	allowDelete = () => {
		if (document.getElementById('delete-input').value == 'DELETE') {
			this.setState({
				allowDelete: true
			});
		}
	};

	handleDeleteArtifact = (logEvent) => {
		logEvent('click delete artifact');
		Database.getDocumentsWhere(
			'artifacts',
			'type',
			'==',
			'report',
			(results, context) => this.deleteCallback(logEvent, results, context),
			this
		);
	};

	deleteCallback = (logEvent, results, context) => {
		var reportIds = [];
		results.forEach((artifact) => {
			artifact.artifactEntries.map((ae) => {
				if (ae.artifactID == context.state.artifact.id) {
					reportIds.push(artifact.id);
				}
			});
		});

		if (reportIds.length > 0) {
			alert(
				'This artifact is used in a report and cannot be deleted.  To delete this artifact, remove the report(s) first: ' +
					reportIds.toString()
			);
		} else {
			var r = confirm('Do you REALLY want to delete this?  Only delete artifacts that were uploaded mistakenly.');
			if (r) {
				logEvent('delete artifact');
				deleteArtifact(context.state.artifact, context.state.artifactTeam);

				// redirect
				context.props.history.push('/studio');
			}
		}
	};

	togglePublic = (logEvent) => {
		logEvent('toggle artifact public');
		Database.replaceValuesInDocument('artifacts', this.state.artifact.id, { public: !this.state.artifactIsPublic }, () => {
			this.setState({
				artifactIsPublic: !this.state.artifactIsPublic
			});},
			this
		);
	};

	renderArtifactView(logEvent) {
		const { artifact } = this.state;

		if (artifact) {
			return (
				<section id='artifact-column'>
					<div className='artifact-content'>
						<h2>Artifact</h2>
							<ArtifactContent 
								artifact={this.state.artifact}
								hideEditBar={(!this.state.userIsTeamMember)} />
					</div>
					<div className="metadata-section"> 
						<div id='artifact-annotations' className='artifact-section'>
							<h3>Annotations</h3>
							{this.renderAnnotations(logEvent)}
						</div>
						<div id='artifact-tags' className='artifact-section'>
							<h3>Tags</h3>
							{this.renderTags(logEvent)}
						</div>
						<div id='artifact-associated'>
							<div className='artifact-metadata-row'>
								<h3>Associated Artifacts</h3>
								{this.state.userIsTeamMember || this.context.user.role == 'instructor' ? (
									<PlusButton onClick={() => this.setState({ modalIsOpen: true })} />
								) : null}
							</div>
							{this.renderAssociatedArtifacts(logEvent)}
						</div>
						<div id='artifact-creator' className='artifact-section'>
							<h3>Uploaded by</h3>
							<p>{artifact.creator.name}</p>
						</div>
						{this.state.artifactTeam ? (
							<div id='artifact-team' className='artifact-section'>
								<h3>Team</h3>
								<p>{this.state.artifactTeam.name}</p>
							</div>
						) : null }
						{(this.context.user.id == this.state.artifact.creator.id ||
							this.context.user.role == 'instructor') && (
							<div id='artifact-delete' className='artifact-section'>
								<h3> Delete Artifact </h3>
								<label htmlFor='delete-input'> To allow deletion, type 'DELETE' </label>
								<input onChange={this.allowDelete} id='delete-input' />
								<button
									id='delete-artifact'
									disabled={!this.state.allowDelete}
									onClick={() => this.handleDeleteArtifact(logEvent)}
								>
									Delete this artifact
								</button>
							</div>
						)}
					</div>
				</section>
			);
		}
	}

	renderArtifactMetadata(logEvent) {
		const { artifact } = this.state;
		if (artifact) {
			const date = new Date(artifact.creationTime);
			return (
				<section id='metadata-column'>
					<div className='artifact-metadata-row'>
						<div id='artifact-date'>
							<h6>
								{date.toLocaleTimeString([], { timeStyle: 'short' })} ·{' '}
								{date.toLocaleDateString([], { year: 'numeric', month: 'short', day: 'numeric' })}
							</h6>
						</div>
						{this.state.userIsTeamMember || this.context.user.role == 'instructor' ? (
							<h6 id='artifact-public'>
								<Checkbox
									checked={this.state.artifactIsPublic}
									id={'is-public'}
									label={'Add to Explore'}
									onChange={() => this.togglePublic(logEvent)}
								/>
							</h6>
						) : null}
					</div>
					<div id='artifact-feedback' className='artifact-section'>
						<h3>Feedback {this.state.artifact.feedback ? <ChatBubble style={{ color: '#FFA500' }} /> : null}</h3>
						<CommentsBlock artifact={this.state.artifact} />
					</div>
				</section>
			);
		}
	}

	renderAssociatedArtifacts(logEvent) {
		const { artifact, associatedArtifacts } = this.state;
		if (this.state.userIsTeamMember || this.context.user.role == 'instructor') {
			return (
				<div>
					{!associatedArtifacts || (associatedArtifacts && associatedArtifacts.length === 0) ? (
						<p>No associated artifacts. Add associations by clicking the + button above.</p>
					) : (
						
						<GalleryStack
							artifacts={associatedArtifacts.map((a) => ({ ...a, selected: true}))}
							onToggleSelect={(a, selected) => this.toggleAssociatedArtifacts(logEvent, a, selected)}
							disableClickArtifact={false}
							scale={90}/>
					)}
					<Modal
						isOpen={this.state.modalIsOpen}
						onAfterOpen={() => null}
						shouldCloseOnOverlayClick={true}
						onRequestClose={this.closeModal}
						contentLabel='Artifact Selection Modal'
						className='Modal'
						overlayClassName='Overlay'
					>
						<div className='closemodal'>
							<button className='clickable-icon' onClick={this.closeModal}>
								<Close />
							</button>
						</div>
						<Gallery
							team={this.state.artifactTeam}
							onToggleSelect={(a, selected) => this.toggleAssociatedArtifacts(logEvent, a, selected)}
							preSelectedArtifactIds={this.state.associatedArtifacts.map((a) => a.id)}
							hiddenArtifactIds={[ artifact.id ]}
							disableClickArtifact
							selectableGrid={true}

						/>
					</Modal>
				</div>
			);
		} else {
			var filtered = associatedArtifacts.filter((a) => a.public);
			if (!filtered || (filtered && filtered.length === 0)) {
				return <p>No associated artifacts.</p>;
			} else {
				return <GalleryStack
							artifacts={filtered}
							disableClickArtifact={false}
							scale={90}/>;
			}
		}
	}

	toggleAssociatedArtifacts = (logEvent, a, selected) => {
		logEvent('update associated artifact', {
			update_type: selected ? 'add' : 'remove',
			updated_associated_artifact: a
		});
		if (selected) {
			this.setState({
				associatedArtifacts: [ ...this.state.associatedArtifacts, a ]
			});
			Database.addValuesToArrayInDocument('artifacts', this.state.artifact.id, [a.id], 'with');
			Database.addValuesToArrayInDocument('artifacts', a.id, [this.state.artifact.id], 'with');
		} else {
			this.setState({
				associatedArtifacts: this.state.associatedArtifacts.filter((artifact) => artifact.id !== a.id)
			});
			Database.removeValuesFromArrayInDocument('artifacts', this.state.artifact.id, [a.id], 'with'); 
			Database.removeValuesFromArrayInDocument('artifacts', a.id, [this.state.artifact.id], 'with'); 
		}
	};

	closeModal = () => {
		this.setState({ modalIsOpen: false });
	};

	updateAssociatedArtifacts = (result) => {
		this.setState({ associatedArtifacts: result });
	};

	renderAnnotations(logEvent) {
		const { artifact } = this.state;

		if (this.state.userIsTeamMember) {
			return (
				<Annotation 
					artifact={this.state.artifact} 
					logEvent={logEvent}
					defaultText={artifact.richAnnotations? artifact.richAnnotations : artifact.annotations}/>
			);
		} else {
			return <div> {artifact.annotations} </div>;
		}
	}

	updateAnnotations = (logEvent, updatedText) => {
		const { artifact } = this.state;
		logEvent('update artifact annotations', {
			previous_annotation_length: artifact.annotations ? artifact.annotations.length : 0,
			new_annotation_length: updatedText.length
		});
		Database.replaceValuesInDocument('artifacts', artifact.id, { annotations: updatedText });
	};

	renderTags(logEvent) {
		const { artifact, allTags } = this.state;
		if (this.state.userIsTeamMember) {
			return (
				<TagSelectorDropdown
					defaultValue={(artifact.tags || []).filter((tagName) => {
						return tagName; // some early uploads have an empty string as tag
					})}
					isClearable={false}
					options={Object.keys(allTags)}
					onChange={(selectedTags) => this.onChangeTags(logEvent, selectedTags)}
					prompt={'Add tags to this artifact.'}
					menuPortalTarget={document.getElementById('artifact-view')}
					componentUpdateKey={artifact.id}
					creatable={true}
				/>
			);
		} else {
			var tags = (artifact.tags || []).filter((tagName) => {
				return tagName; // some early uploads have an empty string as tag
			});
			return (
				<div className='tag-container'>
					{tags.map((t) => {
						return <Tag tag={t} onClick={null} selected={false} />;
					})}
				</div>
			);
		}
	}

	render() {
		const { artifact } = this.state;
		if (this.context.user) {
			if (!this.state.userCannotViewArtifact) {
				this.getData(); // Fetch and set artifact, team's tags, and associated artifacts in state.

				return (
					<Amplitude
						eventProperties={(inheritedProps) => ({
							...inheritedProps,
							scope: [ ...inheritedProps.scope, 'artifact-detail' ],
							artifact: artifact,
							user_is_creator: artifact.creator.id === this.context.user.id,
							user_is_team_member: this.state.userIsTeamMember
						})}
					>
						{({ logEvent }) => (
							<div>
								{this.props.history.length > 1 && (
									<div className='section-header'>
										<div className='section-label-row' onClick={() => this.props.history.goBack()}>
											<div className='icon'>
												<KeyboardBackspace />
											</div>
										</div>
									</div>
								)}
								{this.state.artifact ? (
									<div id='artifact-view'>
										{this.renderArtifactView(logEvent)}
										{this.renderArtifactMetadata(logEvent)}
									</div>
								) : (
									<div className='loading'>
										<Loading />
									</div>
								)}
							</div>
						)}
					</Amplitude>
				);
			} else {
				return (
					<div>
						<div>The artifact you are trying to view doesn't exist, or you do not have permissions to view it.</div>
						<div className='button clickable-icon' onClick={() => this.props.history.push('/studio')}>
							<KeyboardBackspace /> Back to Studio
						</div>
					</div> 
				);
			}
		} else {
			return <Loading />;
		}
	}
}

export default (props) => (
	<Main>
		<ArtifactDetail {...props} />
	</Main>
);
