import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Close, ArrowDropDown } from '@material-ui/icons';

import DefaultTags from '../data/defaultTags';

import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';

class TagSelectorDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = { 
			displayEnterHintText: false
		};
	}
	onChange = (tags) => {
		this.props.onChange((tags || []).map((tag) => tag.value.split(',').join('')).filter((tag) => tag));
	};

	getFormattedOptions(options) {
		if (options) {
			return options.map((tagName) => {
				return { label: tagName, value: tagName };
			});
		} else {
			return [];
		}
	}

	render() {
		const TagSelect = this.props.creatable ? CreatableSelect : Select;

		return (
			<div id='tag-options-bar'>
				{this.props.creatable 
					? 
						<div className='tag-container'>
							<div className='tag-instructions'> for example... </div>
						   {DefaultTags.map((t) => <span className='default-tag'> {t.label} </span>)}
						</div>
					:
						null
				}	
				<TagSelect
					autosize={true}
					components={{
						ClearIndicator: ClearIndicator,
						DropdownIndicator: DropdownIndicator,
						MultiValueRemove: MultiValueRemove
					}}
					className='tagSelectorDropdown'
					defaultValue={this.getFormattedOptions(this.props.defaultValue)}
					isClearable={this.props.isClearable}
					isMulti
					isSearchable
					menuPortalTarget={this.props.menuPortalTarget}
					onChange={this.onChange}
					options={this.getFormattedOptions(this.props.options).concat(DefaultTags)}
					placeholder={this.props.prompt || ''}
					styles={selectStyles}
					theme={{
						controlHeight: '2.3em',
						borderRadius: 0
					}}
					key={this.props.componentUpdateKey}
				/>
			</div>
		);
	}
}

const ClearIndicator = (props) => {
	return (
		<components.ClearIndicator {...props}>
			<Close />
		</components.ClearIndicator>
	);
};

const DropdownIndicator = (props) => {
	return (
		<components.DropdownIndicator {...props}>
			<ArrowDropDown />
		</components.DropdownIndicator>
	);
};

const MultiValueRemove = (props) => {
	return (
		<components.MultiValueRemove {...props}>
			<Close fontSize={'inherit'} />
		</components.MultiValueRemove>
	);
};

const selectStyles = {
	control: (base) => ({ ...base, minHeight: 0 }),
	multiValue: (base) => ({ ...base, borderRadius: '1em' }),
	multiValueRemove: (base) => ({
		...base,
		paddingRight: '0.5em',
		'&:hover': { backgroundColor: 'transparent', cursor: 'pointer' }
	}),
	multiValueLabel: (base) => ({ ...base, paddingLeft: '0.75em' })
};

TagSelectorDropdown.propTypes = {
	options: PropTypes.arrayOf(PropTypes.string).isRequired,
	defaultValue: PropTypes.arrayOf(PropTypes.string),
	menuPortalTarget: PropTypes.node,
	onChange: PropTypes.func.isRequired,
	prompt: PropTypes.string,
	componentUpdateKey: PropTypes.string,
	isClearable: PropTypes.bool.isRequired
};

export default TagSelectorDropdown;
