import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Amplitude } from '@amplitude/react-amplitude';

import Main from '../layouts/Main';
import Checkbox from '../components/Checkbox';
import Dropdown from '../components/Dropdown';
import GalleryGrid from '../components/GalleryGrid';
import TabbedButtons from '../components/TabbedButtons';
import TagSelectorDropdown from '../components/TagSelectorDropdown';
import Database from '../scripts/Database';
import { UserContext } from '../context/UserContext';
import { replaceValuesInListOfArtifacts, extractTags, formatDateForLayoutName } from '../scripts/Utilities';
import Modal from 'react-modal';
import PlusButton from '../components/PlusButton';
import { Close } from '@material-ui/icons';
import Gallery from '../components/Gallery';
import SortTypes from '../data/SortTypes';

import { FilterList, ArrowDropDown, ArrowDropUp, Sort } from '@material-ui/icons';

import ArtifactTypes from '../data/ArtifactTypes';
import testData from '../data/testData';
import Loading from '../components/Loading';

// https://dev.to/clintdev/simple-firebase-image-uploader-display-with-reactjs-3aoo
const SupportedArtifactTypes = [
	ArtifactTypes.IMAGE,
	ArtifactTypes.TEXT,
	ArtifactTypes.COMMIT,
	ArtifactTypes.REPORT,
	ArtifactTypes.LINK,
	ArtifactTypes.VIDEO,
	ArtifactTypes.FIGMA
];

const sortTypesList = Object.values(SortTypes);

class PortfolioCreator extends Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);

		// set default values for filters
		var selectedTags = [];
		var types = SupportedArtifactTypes.map((type) => {
			return type.value;
		});
		var hasFeedbackOnly = false;
		var tagsOr = true;
		var showSubmittedOnly = false;
		var sort = SortTypes.NEWEST;
		var teamId;

		// props should override URL query for types
		if (this.props.initialTypes) {
			types = this.props.initialTypes;
		}

		this.state = {
			includedArtifacts: [],
			artifacts: {},
			zoomValue: 85,
			isDataLoaded: false,
			selectedTeam: null,
			teams: [],
			showAll: false,
			portfolioifyModalIsOpen: false,
			isLayoutSaved: false,
			savedLayoutId: null,
			contributors: {},
			layoutData: {},
			layoutId: null,
			portfolioLayout: null
		};
	}

	getData() {
		while (!this.context.user);
		if (!this.state.isDataLoaded) {
			if (this.context.user.role == 'instructor') {
				Database.getAllDocumentsOnce('teams', this.getTeamsCallback, this);
			} else {
				Database.getDocuments('teams', this.context.user.teams, this.getTeamsCallback, this);
			}
		}
	}

	getTeamsCallback = (teams, context) => {
		teams.sort((t1, t2) => {
			// Alphabetically sorted.
			return t1.name.localeCompare(t2.name);
		});
		var selectedTeam = teams[0]; // takes 'most active' by default.
		
		if (this.context.user.currentSelectedTeam) {
			var foundTeam = teams.find((t) => {
				return t.id === this.context.user.currentSelectedTeam.value;
			});
			if (foundTeam) {
				selectedTeam = foundTeam;
			}
		}

		if (teams && teams.length > 0) {
			Database.getDocumentsWhere(
				'artifacts',
				'team',
				'==',
				selectedTeam.id,
				(artifacts, context) => {
					const teamID = selectedTeam.id;
					context.setState({
						teams: teams,
						selectedTeam: selectedTeam,
						artifacts: { [teamID]: artifacts },
						tags: extractTags(artifacts),
						layoutData: this.generateDefaultLayout(artifacts),
						isDataLoaded: true
					});
				},
				context
			);
			this.getContributorsFromTeam(selectedTeam);
			this.getLayoutFromDB(selectedTeam.portfolioLayoutId);
		}
	};

	getArtifactsFromTeam = (team) => {
		Database.getDocumentsWhere(
			'artifacts',
			'team',
			'==',
			team.id,
			(artifacts, context) => {
				context.setState({
					artifacts: { ...this.state.artifacts, [team.id]: artifacts },
					tags: extractTags(artifacts),
					layoutData: this.generateDefaultLayout(artifacts),
				});
			},
			this
		);
	};

	getContributorsFromTeam = (team) => {
		Database.getDocuments(
			'users',
			team.contributors,
			(users, context) => {
				context.setState({
					contributors: { ...this.state.contributors, [team.id]: users }
				});
			},
			this
		);
	}


	onChangeTeam(logEvent, selectedTeamId) {
		logEvent('change selected team', {
			newTeam: selectedTeamId,
			oldTeam: this.state.selectedTeam.id
		});
		var newSelectedTeam = this.state.teams.find((team) => {
			return selectedTeamId.value === team.id;
		});
		Database.replaceValuesInDocument('users', this.context.user.id, {
			currentSelectedTeam: selectedTeamId
		});

		this.setState({
			includedArtifacts: [],
			selectedTeam: newSelectedTeam,
			tags: extractTags(this.state.artifacts[newSelectedTeam.id]),
			savedLayoutId: null
		});

		Database.getDocument('teams', newSelectedTeam.id, (doc, context) => {
				var pid = doc.portfolioLayoutId;
				if (pid) {
					this.getLayoutFromDB(pid);
				} else {
					context.setState({portfolioLayout: null})
				}
			}, 
			this);

		// only happens if you haven't already downloaded the stuff
		if (!(newSelectedTeam.id in this.state.artifacts)) {
			this.getArtifactsFromTeam(newSelectedTeam);
			this.getContributorsFromTeam(newSelectedTeam);
		}
	}

	getMaxY(data) {
		var height = 0;
		var shownIDs = this.state.includedArtifacts.map((a) => a.id);
		Object.keys(data).map((key) => {
			var entry = data[key];
			if (entry && shownIDs.includes(key) ) { 
				var bottom = entry.y + entry.height;
				if (bottom > height) {
					height = bottom;
				}
				// var left = entry.x + entry.width;
				// if (left > width) {
				// 	width = left;
				// }
			}
		});

		return height;
	}

	toggleIncludedArtifacts = (logEvent, a) => {
		var selected = this.state.includedArtifacts.filter((b) => b.id == a.id).length == 0;
		
		logEvent('update included artifact', {
			update_type: selected ? 'add' : 'remove',
			updated_included_artifact: a
		});

		var newLayoutData = this.state.layoutData;
		if (selected) {
			var newLayoutInstance = {
				x: 0,
			    y: this.getMaxY(newLayoutData) + 20,
			    width: 180,
			    height: 180,
			    zIndex: 1,
			};
			newLayoutData[a.id] = newLayoutInstance;

			this.setState({
				includedArtifacts: [ ...this.state.includedArtifacts, a ],
				layoutData: newLayoutData,
			});
		} else {
			this.setState({
				includedArtifacts: this.state.includedArtifacts.filter((artifact) => artifact.id !== a.id),
				layoutData: newLayoutData,
			});
		}
	};


	renderTeamImages() {
		return (
			<div className='team-images'> 
				{this.state.selectedTeam && this.state.contributors[this.state.selectedTeam.id] && !this.state.showAll ? 
					this.state.contributors[this.state.selectedTeam.id].map((user) => {
						return (
								<img
									key={user.id}
									className='studio-profile-image'
									src={user.imageURL}
									alt={`profile picture of ${user.name}`}
									title={user.name}
								/>
						);
					})
					: null
				}
			</div>
		);
	}
	scaleView = () => {
		var value = document.getElementById('zoom').value;
		this.setState({ zoomValue: value });
	};

	openModal = () => {
		this.setState({ modalIsOpen: true });
	};

	closeModal = () => {
		this.setState({ modalIsOpen: false });
	};

	saveLayoutToDb = (layoutData, artifacts, teamID, userId, name, callback) => {		
		var now = new Date();
		var name = name ? name : now.toString();
		var newlayout = {
			layoutData: layoutData,
			visibleArtifacts: artifacts,
			timeCreated: now.toString(),
			type: 'portfolio',
			team: teamID,
			user: userId,
			name: name,
			filters: {
				selectedTags: ['in portfolio:' + name],
				portfolio: true
			}
		};

		console.log('saving...');
	
		artifacts.map((a) => {
			Database.addValuesToArrayInDocument(
				'artifacts', 
				a.id, 
				['in portfolio:' + name], 'tags'
			); 
		});

		Database.addDocument('layouts', newlayout, (data, context) => {
			// Set layout id so that the system knows that it's already been saved
			context.setState({
				layoutId: data.id
			}, () => {
				if (callback) {
					callback(data, context);
				}
			});
		}, this);
	}

	loadLayout = (layoutData, artifacts) => {
		this.setState({
			includedArtifacts: artifacts,
			layoutData: layoutData
		});
	}


	updateLayoutData = (newLayoutData, newLayoutId) => {
	    this.setState({
			layoutData: newLayoutData,
			layoutId: newLayoutId
	    });
	}

	generateDefaultLayout = (artifacts) => {
		var layout = {};
		artifacts.map((artifact, i) => {
			layout[artifact.id] = {
				x: (i % 5) * 200,
			    y: Math.floor(i/5) * (200),
			    width: 180,
			    height: 180,
			    zIndex: 1,
			    hidden: false,
			};
		});
		return layout;		
	}

	resetLayoutFunction = () => {
	 	this.setState({
			includedArtifacts: [],
			layoutData: this.generateDefaultLayout(this.state.artifacts[this.state.selectedTeam.id]),
			layoutId: null
		});
	}

	setTeamPortfolioId = (layoutId) => {
		Database.replaceValuesInDocument("teams", this.state.selectedTeam.id, {
			portfolioLayoutId: layoutId
		});
	}

	onPortfolioify = () => {
		// If has a layoutId, has already been saved, so don't re-save
		if (this.state.layoutId !== null) {
			this.setState({
				// portfolioifyModalIsOpen: true,
				isLayoutSaved: true,
				savedLayoutId: this.state.layoutId
			});
			this.setTeamPortfolioId(this.state.layoutId);
			this.redirectToPortfolio(this.state.layoutId);
			this.getLayoutFromDB(this.state.layoutId);
			return;
		}

		this.setState({
			portfolioifyModalIsOpen: true,
			isLayoutSaved: false,
			savedLayoutId: null,
			portfolioLayout: null
		});
		
		const context = this;
		console.log('portfolioify');
		// Faux artiifacts don't need to be filtered out, since they're part of the layout
		this.saveLayoutToDb(this.state.layoutData, this.state.includedArtifacts, this.state.selectedTeam.id, this.context.user.id, null, (docRef) => {
			context.setTeamPortfolioId(docRef.id);
			context.redirectToPortfolio(docRef.id);
			context.getLayoutFromDB(docRef.id);

			context.setState({
				isLayoutSaved: true,
				savedLayoutId: docRef.id,
				portfolioifyModalIsOpen: false
			});
		});
	
	}

	getLayoutFromDB = (id) => {
		Database.getDocumentsWhere('layouts', '__name__', '==', id, (data, context) => {
				console.log("in db call for name: ", data, context);
				if (this.state.isUnmounted) return;
				const layout = data.length ? data[0] : {};
				context.setState({
					portfolioLayout: layout,
					isLayoutFetched: true
				}, null);
			}, this);
	}

	redirectToPortfolio = (id) => {
		console.log('redirect....');
		const win = window.open('/portfolio/' + id, '_blank');
		if (win != null) {
			win.focus();
		} else {
			this.props.history.push('/portfolio/' + id);
		}
	}

	closePortfolioifyModal = () => {
		this.setState({
			portfolioifyModalIsOpen: false
		});
	}


	renderArtifacts(logEvent) {
		if (this.state.selectedTeam) {
			return (			
				<GalleryGrid
					artifacts={this.state.includedArtifacts.map((artifact) => ({
						...artifact,
						selected: true
					}))}
					scale={100}
					onToggleSelect={(a) => this.toggleIncludedArtifacts(logEvent, a)}
					selectableGrid
					gridScale={1}
					disableClickArtifact={true}
					team={this.state.selectedTeam}
					user={this.context.user} 
					save={this.saveLayoutToDb}
					loadLayoutFunction={this.loadLayout}
					layoutData={this.state.layoutData}
					updateLayoutData={this.updateLayoutData}
					resetLayoutFunction={this.resetLayoutFunction}
					source={'portfolio'}
					usePortfolioStyles={true} />
			);
		}
	}


	render() {
		this.getData(); // Fetch and set artifacts and tags in state.
		const teamOptions = this.state.teams.map((team) => {
			return { label: team.name, value: team.id };
		});

		const teamPortfolioLayoutId = this.state.savedLayoutId || (this.state.selectedTeam && this.state.selectedTeam.portfolioLayoutId) || null;

		return (
				<div id='portfolio-gallery'>
					<Amplitude
						eventProperties={(inheritedProps) => ({
							...inheritedProps,
							scope: [ ...inheritedProps.scope, 'PortfolioCreator' ]
						})}
					>
						{({ logEvent }) => (
							<div>
								<div className='section-header'> 
									<div>
										<h3>Add Artifacts</h3>
										<PlusButton onClick={() => this.setState({ modalIsOpen: true })} />
									</div>

									<div className='team-info'>
										{ teamOptions.length > 0 && (
											<div className='team-select'>
												<Dropdown
													isSearchable
													isDisabled={this.state.showAll}
													options={teamOptions}
													defaultValue={{
														label: this.state.selectedTeam.name,
														value: this.state.selectedTeam.id
													}}
													onChange={(selectedTeamId) => this.onChangeTeam(logEvent, selectedTeamId)}
												/>										
											</div>
										)}
										{this.renderTeamImages()}
									</div>

									{teamPortfolioLayoutId ?
										<div>
										<div style={{display:'block'}}>
											<Link target="_blank" to={`/portfolio/${teamPortfolioLayoutId}`} 
												style={{border: 'none', outline: 'none'}}>
											<button id='launch-portfolio-button'>Launch Latest Portfolio</button></Link>
										</div>
										<span style={{textColor:"#d3d3d34a", fontSize: "10px"}}>Current Portfolio Layout: {
											this.state.portfolioLayout ? 
												this.state.portfolioLayout.name !== '' ? this.state.portfolioLayout.name
												: formatDateForLayoutName(this.state.portfolioLayout.timeCreated)
											: ''
										} </span>
										</div>
									: null}
									<button id='portfolio-button' onClick={() => this.onPortfolioify()}> Portfolio-ify </button>

								</div>
								
								{this.renderArtifacts(logEvent)}

								<Modal
									isOpen={this.state.modalIsOpen}
									onAfterOpen={() => null}
									shouldCloseOnOverlayClick={true}
									onRequestClose={this.closeModal}
									contentLabel='Artifact Selection Modal'
									className='Modal'
									overlayClassName='Overlay'
								>
									<div className='closemodal'>
										<button className='clickable-icon' onClick={this.closeModal}>
											<Close />
										</button>
									</div>
									<Gallery
										team={this.state.selectedTeam}
										onToggleSelect={(a, selected) => this.toggleIncludedArtifacts(logEvent, a, selected)}
										preSelectedArtifactIds={this.state.includedArtifacts.map((a) => a.id)}
										disableClickArtifact
										selectableGrid={true}
										includeFauxArtifacts={true}
									/>
								</Modal>

								<Modal
									isOpen={this.state.portfolioifyModalIsOpen}
									onAfterOpen={() => {}}
									shouldCloseOnOverlayClick={true}
									onRequestClose={this.closePortfolioifyModal}
									contentLabel='Portfolio-ify'
									className='Modal'
									overlayClassName='Overlay'
								>
									<div className='portfolioify-modal-inner'>
										<p>Saving your portfolio...</p>
										<div className='portfolioify-loading'>
											<Loading />
										</div>
									</div>
								</Modal>
							</div>
						)}
					</Amplitude>
				</div>
		);
	}
}

export default withRouter((props) => (
	<Main selectedSidebarItem=''>
		<PortfolioCreator {...props} />
	</Main>
));