import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import kaleidoscope from '../../public/images/kaleidoscope.gif';

const oldRoute = "/documentation-practices/";

class PageNotFound extends Component {

	
	// -1 if sub-string is not found, otherwise is location.
	needsRedirect = (urlPath) => {
		return urlPath.indexOf(oldRoute);
	}

	
	fixOldUrl = (urlPath, index) => {

		// index is -1 if sub-string is not found, otherwise is location.
		var newPath = "";
		if (index >= 0) {
			newPath = urlPath.replace(oldRoute, "/");
		}
		if(index < 0) { // didn't contain old path, so ignore it.
			return urlPath;
		}
		return newPath;

	}

	render() {
		const currURL = this.props.location.pathname;

		var needsRedirect = this.needsRedirect(this.props.location.pathname);
		if (needsRedirect === 0) {
			var newPath = this.fixOldUrl(this.props.location.pathname, needsRedirect);
			this.props.history.push(newPath);
		}
		return (

			
			<div className='not-found'>
				<Helmet title='404' />
				<h1>Page not found</h1>
				<img className='image landing' src={kaleidoscope} />
				<p>Sorry, it looks like something went wrong!</p>
				<div className='not-found-buttons'>
					<button className='not-found-action' onClick={() => this.props.history.push('/')}>
						Return to studio
					</button>
					<button
						className='not-found-action'
						onClick={() => window.open('https://forms.gle/eQ4R9LkR9pt2s3s38')}
					>
						Share your feedback
					</button>
				</div>
			</div>
		);
	}
}

export default PageNotFound;
