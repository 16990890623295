import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Database from '../scripts/Database';
import * as firebase from 'firebase';
import Github from '../scripts/Github';
import amplitude from 'amplitude-js';
import { AmplitudeProvider, Amplitude } from '@amplitude/react-amplitude';
import AMPLITUDE_KEY from '../../amplitude/index';
import kaleidoscope from '../../public/images/kaleidoscope.gif';
import Loading from '../components/Loading';

const validBerkeleyEmailPattern = RegExp(/.*@berkeley\.edu/);

class Index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			displayBerkeleyEmailRequirement: false,
			shouldRedirect: false,
		};
	}

	integrationsCallback = (result, context) => {
		console.log('integrationsCallback result: ', result);
		context.props.history.push('/upload?token=' + result.token);
	};

	componentDidMount() {
		firebase.auth().onAuthStateChanged((firebaseUser) => {
			if (firebaseUser) {
				var isBerkeleyEmail = validBerkeleyEmailPattern.test(firebaseUser.email);
				//var isBerkeleyEmail = true; // test only
				if (isBerkeleyEmail) {
					this.checkIfNewUser(firebaseUser);
				} else {
					//logged in but NOT a valid user of our system.
					this.setState({ displayBerkeleyEmailRequirement: true });
					firebaseUser
						.delete()
						.then(function () {
							// User deleted.
							console.log('delete non-Berkeley user account.');
						})
						.catch(function (error) {
							// An error happened.
						});
				}
			} else {
				console.log('Index firebase user is null');
			}
		});
	}

	checkIfNewUser(firebaseUser) {
		Database.getUserData(
			firebaseUser.uid,
			(userResponse, context) => {
				var user = userResponse;
				// if user doesn't exist in OUR database
				if (!user || !user.data) {
					// Create user object
					user = {
						id: firebaseUser.uid,
						name: firebaseUser.displayName,
						imageURL: this.saveBaseImageURL(firebaseUser.photoURL),
						email: firebaseUser.email,
						timeCreated: new Date(),
						role: 'student',
						bytesUploaded: 0,
					};
					// Create entry for user in Database
					Database.addDocument(
						'teams',
						{
							name: `${user.name}'s Personal Studio`,
							contributors: [firebaseUser.uid],
							timeCreated: new Date(),
						},
						(teamData, context) => {
							Database.addDocumentWithCustomID(
								'users',
								firebaseUser.uid,
								{
									...user,
									teams: [teamData.id],
								},
								(result, context) => {
									// maybe set shouldRedirect to true here, instead.
									context.props.history.push('/studio');
								},
								context
							);
						},
						context
					);
				} else {
					// if user already exists in database
					console.log('user already exists! redirect to studio');
					// maybe set shouldRedirect to true here, instead.
					//context.props.history.push('/studio');
					// already logged in AND are a user of our system.
					// Explicitly set flag to redirect user to a logged-in experienece
					this.setState({ shouldRedirect: true });
				}
			},
			this
		);
	}

	saveBaseImageURL(imageURL) {
		return imageURL.split('=')[0];
	}

	handleSignInClick = (logEvent) => {
		var provider = new firebase.auth.GoogleAuthProvider();
		firebase
			.auth()
			.signInWithPopup(provider)
			.then((result) => {
				// The signed-in user info.
				console.log('successfully signed in to firebase');
				if (result && result.user && result.user.uid) {
					logEvent('sign in', { user_id: result.user.uid });
				}
			})
			.catch(function (error) {
				// Handle Errors here.
				var errorCode = error.code;
				var errorMessage = error.message;
				// The email of the user's account used.
				var email = error.email;
				// The firebase.auth.AuthCredential type that was used.
				var credential = error.credential;
				console.log(errorCode);
				console.log(errorMessage);
			});
	};

	render() {
		const searchParams = new URLSearchParams(location.search);
		const code = searchParams.get('code');
		const token = searchParams.get('token');

		if (code) {
			Github.requestTokenViaGatekeeper(code, this.integrationsCallback, this);
			return <Loading />;
		}

		const { shouldRedirect } = this.state;
		if (shouldRedirect) {
			return <Redirect to='/studio' />;
		} else {
			return (
				<div className='centered-signin-content'>
					<h3>Kaleidoscope</h3>
					<img className='image big-landing' src={kaleidoscope} />
					<button id='signin-button' onClick={() => this.props.history.push('/showcase')}>
						Visit CS 160 Showcase ⟩
					</button>
					<AmplitudeProvider amplitudeInstance={amplitude.getInstance()} apiKey={AMPLITUDE_KEY}>
						<Amplitude
							eventProperties={{
								scope: ['index'],
							}}
						>
							{({ logEvent }) => (
								<button id='signin-button' onClick={() => this.handleSignInClick(logEvent)}>
									Sign In with Google ⟩
								</button>
							)}
						</Amplitude>
					</AmplitudeProvider>
					{this.state.displayBerkeleyEmailRequirement ? (
						<sub>Note: You must sign in with a @berkeley.edu email.</sub>
					) : (
						<span>Note: You must sign in with a @berkeley.edu email.</span>
					)}
				</div>
			);
		}
	}
}

export default Index;
