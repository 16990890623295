import React, { Component } from 'react';

import Main from '../layouts/Main';
import storage from '../../firebase/index';
import Database from '../scripts/Database';
import TemplateRequirement from '../components/TemplateRequirement';
import { UserContext } from '../context/UserContext';

class TemplateCreator extends Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);

		this.state = {
			id: null,
			requirementsData: [],
			templateName: '',
			published: false,
			templateSaving: false
		};
	}

	componentDidMount() {
		console.log('mount: ', this.props.location.state);
		if (this.props.location.state && this.props.location.state.templateID) {
			this.setState({
				id: this.props.location.state.templateID
			});
			Database.getDocument('templates', this.props.location.state.templateID, this.getTemplateCallback, this);
		}
	}

	renderRequirement(reqdata, published) {
		console.log('render: ', published);
		return (
			<TemplateRequirement
				updateRequirementData={this.updateRequirementData}
				deletereq={this.deleterequirement}
				index={reqdata.index}
				editable={!published}
				source={'template'}
				requirement={reqdata}
			/>
		);
	}

	getTemplateCallback(template, context) {
		context.setState({
			templateName: template.name,
			requirementsData: template.requirements,
			published: template.published
		});
	}

	addRequirement = (e) => {
		var reqdatajoined = this.state.requirementsData.concat({
			tag: '',
			type: '',
			description: '',
			index: this.state.requirementsData.length
		});

		this.setState({
			requirementsData: reqdatajoined
		});
	};

	updateRequirementData = (field, value, index) => {
		var newReqData = this.state.requirementsData.slice();
		var thisData = newReqData[index];
		thisData[field] = value;
		newReqData[index] = thisData;
		this.setState({
			requirementsData: newReqData
		});
	};

	deleterequirement = (i) => {
		console.log('deleting: ', i);
		// remove the requirement
		var updatedData = this.state.requirementsData.slice();
		updatedData.splice(i, 1);
		console.log('len remaining', updatedData.length);
		console.log(updatedData);
		// update the indices
		// [0,1,2,3]
		// [0,1,3]
		for (var j = i; j < updatedData.length; j++) {
			console.log('j, index at j: ', j, updatedData[j]);
			updatedData[j].index = j;
		}
		console.log('updated:', updatedData);
		this.setState({
			requirementsData: updatedData
		});
	};

	// onchange = (e, i) => {
	// 	console.log(e);
	// 	var newData = {...this.state.requirementsData[i], index: i};
	// 	switch (e.target.className) {
	// 		case 'requirementDescription':
	// 			newData.description = e.target.value;
	// 			break;
	// 		case 'requirementArtifactType':
	// 			newData.type = e.target.value;
	// 			break;
	// 		case 'requirementTag':
	// 			newData.tag = e.target.value;
	// 			break;
	// 		default:
	// 			break;
	// 	}
	// 	this.updateRequirementData(newData, i);
	// }

	validateRequirements = () => {
		if (!this.state.templateName) {
			alert('Template must have a name');
			return false;
		}
		for (var i = 0; i < this.state.requirementsData.length; i++) {
			var d = this.state.requirementsData[i];
			if (!(d.description != '' && d.tag != '' && d.type != '')) {
				alert('Cannot save requirements with empty fields');
				return false;
			}
		}
		return true;
	};

	changeTemplateData = (data, callback) => {
		if (this.state.id) {
			console.log('Updating a template');
			console.log(this.state.id, data);
			Database.replaceValuesInDocument('templates', this.state.id, data, callback, this);
		} else {
			console.log('Adding a new template');
			var joinedcallback = (docRef, context) => {
				this.newCallback(docRef, context);
				callback(docRef, context);
			};
			Database.addDocument('templates', data, joinedcallback, this);
		}
	};

	newCallback(docRef, context) {
		console.log('Document written with ID: ', docRef.id);
		console.log('context: ', context);
		console.log(context.setState);
		context.setState({
			id: docRef.id
		});
	}

	saveTemplate = () => {
		var data = {
			requirements: this.state.requirementsData,
			name: this.state.templateName,
			published: this.state.published
		};
		if (this.validateRequirements()) {
			this.setState({
				templateSaving: true
			});
			this.changeTemplateData(data, this.saveCallback);
		}
	};

	saveCallback(data, context) {
		console.log('context', context);
		context.setState({
			templateSaving: false
		});
		alert('Template saved');
	}

	publishTemplate = () => {
		var data = {
			requirements: this.state.requirementsData,
			name: this.state.templateName,
			published: true
		};
		if (this.validateRequirements()) {
			this.changeTemplateData(data, this.publishTemplateCallback);
		}
	};

	publishTemplateCallback(docRef, context) {
		console.log('context', context);
		context.setState({
			published: true
		});
	}

	updateName = (e) => {
		this.setState({
			templateName: e.target.value
		});
	};

	render() {
		const { user } = this.context;
		console.log('user:', user);
		if (user && user.role === 'instructor') {
			return (
				<div id='template-creator'>
					<div id='templateManager'>
						{!this.state.published ? (
							<h2>
								{' '}
								Assignment Name{' '}
								<input
									type='text'
									id='templateName'
									onChange={this.updateName}
									value={this.state.templateName}
								/>
							</h2>
						) : (
							<h2> {this.state.templateName} </h2>
						)}

						<div>
							{!this.state.published ? (
								<div>
									<button id='save' onClick={this.saveTemplate} disabled={this.state.templateSaving}>
										{' '}
										Save{' '}
									</button>
									<button id='publish' onClick={this.publishTemplate}>
										{' '}
										Publish{' '}
									</button>
								</div>
							) : (
								<div> </div>
							)}
						</div>
					</div>
					<div>
						{!this.state.published ? (
							<button onClick={this.addRequirement}> Add a Requirement </button>
						) : (
							<div> </div>
						)}
					</div>
					<div id='requirementsContainer'>
						{this.state.requirementsData.map((rd) => this.renderRequirement(rd, this.state.published))}
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
}

export default (props) => (
	<Main selectedSidebarItem='templateCreator'>
		<TemplateCreator {...props} />
	</Main>
);
