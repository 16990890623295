import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autosize from 'autosize';

class InputText extends Component {
	constructor(props) {
		super(props);
		this.state = {
			comparisonText: this.props.defaultText,
			text: this.props.defaultText,
			componentUpdateKey: this.props.componentUpdateKey,
			validInput: true,
		};
	}

	componentDidMount() {
		autosize(this.textarea);
	}

	componentDidUpdate() {
		if (this.state.componentUpdateKey !== this.props.componentUpdateKey) {
			this.setState({
				comparisonText: this.props.defaultText,
				text: this.props.defaultText,
				componentUpdateKey: this.props.componentUpdateKey
			});
		}
	}

	handleChange = (e) => {
		var text = e.target.value;
		this.setState({ text: text });
		this.props.onChange && this.props.onChange(text);

		// if it's a good url, enable the upload button
		// but don't render warning if not, because not done typing
		if (this.props.onBlur && this.props.onBlur(text)) {
			this.setState( {validInput: true} );
		}
	};

	handleSave = () => {
		this.props.onSave(this.state.text);
		this.setState({ comparisonText: this.state.text });
	};

	handleOnBlur = (e) => {
		var text = e.target.value;
		if (!this.props.onBlur) {
			return;
		}
		var validURL = this.props.onBlur(text);
		if (validURL) {
			this.setState( {validInput: true} );
		} else {
			this.setState( {validInput: false} );
		}
	}

	handleOnFocus = (e) => {
		this.setState( {validInput: true} );
	}

	render() {
		return (
			<div className='input-text'>
				{this.props.singleLine ? (
					<input
						type='text'
						id={this.props.id}
						placeholder={this.props.placeholder || ''}
						onChange={this.handleChange}
						value={this.state.text}
						onBlur={this.handleOnBlur}
						onFocus={this.handleOnFocus} />
				) : (
					<textarea
						type='text'
						ref={(c) => (this.textarea = c)}
						id={this.props.id}
						placeholder={this.props.placeholder || ''}
						onChange={this.handleChange}
						value={this.state.text}
						onBlur={this.handleOnBlur}
						onFocus={this.handleOnFocus} />
				)}
				{this.state.validInput || this.state.text === undefined || this.state.text === "" ? (
					<div/>
				) : (
					<p style={{ color: 'red' }}>Whoops! That URL doesn't look right!</p>
				)}
				{this.props.onSave && (
					<>
						<button
							disabled={this.state.text === this.state.comparisonText || this.props.disabled }
							className='input-save'
							onClick={this.handleSave}
						>
						{(this.props.disabled && this.props.warning && this.state.text !== this.state.comparisonText) ? <span className='warning'> {this.props.warning} </span> 
							: this.props.saveText && this.props.saveText != '' ? this.props.saveText : 'Save'}
						</button>
						
					</>
				)}
			</div>
		);
	}
}

InputText.propTypes = {
	id: PropTypes.string.isRequired,
	defaultText: PropTypes.string,
	placeholder: PropTypes.string,
	saveText: PropTypes.string,
	onSave: PropTypes.func,
	onChange: PropTypes.func,
	componentUpdateKey: PropTypes.string,
	singleLine: PropTypes.bool,
	onBlur: PropTypes.func,
	disabled: PropTypes.bool,
	warning: PropTypes.string,
};
export default InputText;
