import React, { Component } from 'react';
import Moment from 'react-moment';
import Database from '../scripts/Database';
import { Amplitude } from '@amplitude/react-amplitude';

import Comment from '../components/Comment';
import TextEditor from '../components/TextEditor';
import { UserContext } from '../context/UserContext';
import { generatePlainTextFromRichText } from '../scripts/Utilities';

class Annotation extends Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);

		this.state = {
            editorKey: 0,
            comparisonText: this.props.defaultText,
		};
	}

	componentDidMount() {
		this.setState({
			editorPermissions: true
		});
	}


    saveAnnotations = (logEvent) => {
		const { artifact } = this.props;
		var annotationText = generatePlainTextFromRichText(this.state.richAnnotations);

		logEvent('update artifact annotations', {
			previous_annotation_length: artifact.annotations ? artifact.annotations.length : 0,
			new_annotation_length: annotationText.length
		});
		Database.updateDocument('artifacts', artifact.id, 
				{ annotations: annotationText,
				  richAnnotations : this.state.richAnnotations
                 });
        this.setState({comparisonText: this.state.richAnnotations});
    };

	render() {
        var {logEvent, artifact} = this.props;
		return (
            <>
            <TextEditor 
                key={this.state.editorKey.toString()}
                id='annotations'
                initialText={artifact.richAnnotations ? JSON.parse(artifact.richAnnotations) : artifact.annotations}
                readOnly = {false} // should only be able to edit if it's your comment.
                onChange={(content) => {
                    this.setState({ richAnnotations: content });
                }}
            />
            <button 
                onClick={() => this.saveAnnotations(logEvent)}
                disabled={this.state.richAnnotations === this.state.comparisonText}
                > Save Annotation </button>
        </>
		);
	}
}

Annotation.propTypes = {
    artifact: PropTypes.object.isRequired,
    logEvent: PropTypes.object.isRequired,
    defaultText: PropTypes.string,
};

export default Annotation;
