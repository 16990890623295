import React, { Component } from 'react';

import Main from '../layouts/Main';
import MediaVis from '../components/MediaVis';
import Database from '../scripts/Database';

export default class Dashboard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			userInfo: null,
			artifacts: []
		};
	}

	// is there a way to pass this from a parent rather than request it again?
	componentDidMount() {
		Database.getAllDocumentsSnapshot('artifacts', this.artifactsCallback, this);
	}

	artifactsCallback(qs, context) {
		var artifactList = [];
		qs.forEach((doc) => {
			var data = doc.data();
			data.id = doc.id;
			artifactList.push(data);
		});
		context.setState({ artifacts: artifactList });
		console.log(context.state.artifacts);
	}

	render() {
		return (
			<Main selectedSidebarItem='dashboard'>
				<div>
					<h1>
						Choose a project:
						<select name='project' id='choose_project'>
							<option value='1'>Project 1</option>
							<option value='2'>Project 2</option>
							<option value='3'>Project 3</option>
							<option value='4'>Project 4</option>
						</select>
					</h1>
				</div>

				<div>
					<h1> New Content Since Last time </h1>
				</div>

				<MediaVis artifacts={this.state.artifacts} />

				<div>
					<h1> Collaboration </h1>
				</div>
			</Main>
		);
	}
}
