// <!-- The core Firebase JS SDK is always required and must be listed first -->
// <script src="https://www.gstatic.com/firebasejs/7.14.6/firebase-app.js"></script>

import firebase from 'firebase/app';
import 'firebase/storage';

import dotenv from "dotenv";

dotenv.config();

console.log(`process.env: `, process.env.NODE_ENV);

var firebaseConfig;
if (process.env.NODE_ENV == "production"){
  firebaseConfig = {
    apiKey: 'AIzaSyBMC4R-t_bNLn812WgyYkbmW9U1fIh9nSs',
    authDomain: 'documentation-e0542.firebaseapp.com',
    databaseURL: 'https://documentation-e0542.firebaseio.com',
    projectId: 'documentation-e0542',
    storageBucket: 'documentation-e0542.appspot.com',
    messagingSenderId: '780196881538',
    appId: '1:780196881538:web:d6698dce00b9f49556809b'
  };
} else if (process.env.NODE_ENV == "development") {
firebaseConfig = {
    apiKey: "AIzaSyC1awdB28utZGUpuJMX3fqvHT1calA3s34",
    authDomain: "banana-29d05.firebaseapp.com",
    databaseURL: "https://banana-29d05.firebaseio.com",
    projectId: "banana-29d05",
    storageBucket: "banana-29d05.appspot.com",
    messagingSenderId: "597031700699",
    appId: "1:597031700699:web:38d7b10555311fe92764fe",
    measurementId: "G-V4HWM6GKVT"
  };

}

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const storage = firebase.storage();

export { firebase, storage as default };
