import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import * as firebase from 'firebase';
import Helmet from 'react-helmet';
import amplitude from 'amplitude-js';
import { AmplitudeProvider, Amplitude } from '@amplitude/react-amplitude';

import Sidebar from '../components/Sidebar/Sidebar';
import Database from '../scripts/Database';
import { UserContext } from '../context/UserContext';
import Loading from '../components/Loading';
import CloseIcon from '@material-ui/icons/Close';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import AMPLITUDE_KEY from '../../amplitude/index';

class Main extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userInfo: null,
			shouldRedirect: false,
			showBanner: true
		};
	}

	UNSAFE_componentWillMount() {
		window.scrollTo(0, 0);
	}

	componentDidMount() {
		firebase.auth().onAuthStateChanged((firebaseUser) => {
			if (firebaseUser && firebaseUser.uid) {
				Database.getUserData(
					firebaseUser.uid,
					(user, context) => {
						if (user) {
							context.setState({ userInfo: { ...user.data, id: user.id } });
							// maybe chagne shouldRedirect to false here.
							context.setState({ shouldRedirect: false });
						}
					},
					this
				);
			} else {
				// Explicitly set flag to redirect user to sign in
				this.setState({ shouldRedirect: true });
			}
		});
	}

	handleSignOut = (event) => {
		firebase.auth().signOut();
	};

	handleBanner(showBanner) {
		this.setState( {showBanner : showBanner} );
	}

	render() {
		console.log(AMPLITUDE_KEY);

		const { shouldRedirect, userInfo } = this.state;

		if (shouldRedirect) {
			return <Redirect to='/' />;
		} else if (userInfo) {
			return (
				<AmplitudeProvider
					amplitudeInstance={amplitude.getInstance()}
					apiKey={AMPLITUDE_KEY}
					userId={userInfo.id}
				>
					<Amplitude
						eventProperties={{
							scope: [ 'main' ]
						}}
						userProperties={userInfo}
					>
						<UserContext.Provider value={{ user: userInfo }}>
							{this.state.showBanner ?
								<div className='banner' 
									style={ {
									fontSize:'14pt',
									backgroundColor: "#ffe3b1", 
									width:'100%', 
									display: 'flex',
									textColor: '#FFFFF',
									textDecoration: 'underline',
									justifyContent: 'center'}}>
										<a href="https://docs.google.com/forms/d/e/1FAIpQLScmlmmKmM5exMzYSRnZEzyWhXXIzvWn-DZNU8NCODXGIoDTeQ/viewform" 
											target='_blank'>
												Click here to allow the research team to use your Kaleidoscope data (no extra work on your part!)
										</a> 
								<CloseIcon onClick={() => {this.handleBanner(false)}}/>
								</div>
							:
							<AssignmentLateIcon
								style={ {float: 'right', color: '#FFA500'} }
								onClick={() => {this.handleBanner(true)}}/>
							}
							
							<div id='wrapper'>
								<Sidebar
									selectedSidebarItem={this.props.selectedSidebarItem}
									handleSignOut={this.handleSignOut}
								/>
								<div id='main-content'>
									<Helmet titleTemplate='%s | Kaleidoscope' defaultTitle='Kaleidoscope' />

									<div id='main'>{this.props.children}</div>
								</div>
							</div>
						</UserContext.Provider>
					</Amplitude>
				</AmplitudeProvider>
			);
		} else {
			return (
				<div id='loading-wrapper'>
					<Loading />
				</div>
			);
		}
	}
}

Main.propTypes = {
	children: PropTypes.oneOfType([ PropTypes.arrayOf(PropTypes.node), PropTypes.node ]),
	fullPage: PropTypes.bool,
	selectedSidebarItem: PropTypes.string
};

Main.defaultProps = {
	children: null,
	fullPage: false,
	selectedSidebarItem: null
};

export default Main;
