import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import Pane from './Pane';
import StackGrid from 'react-stack-grid';

class GalleryStack extends Component {
	renderPanes(data) {
		return data.map((artifact) => {
			return (
				<Pane
					artifact={artifact}
					onToggleSelect={this.props.onToggleSelect}
					selected={artifact.selected}
					selectableGrid={this.props.selectableGrid}
					disableClickArtifact={this.props.disableClickArtifact}
					hideMetadata={this.props.hideMetadata}
					zoom={this.props.scale}
					hideSidebar={true}
				/>
			);
		});
	}

	updateGrid = () => {
		this.grid.updateLayout();
	};

	render() {
		const { artifacts, scale } = this.props;
		const numDesiredArtifactsInARow = 12 * (100 - scale) / 100 + 4;
		const columnWidthPercent = 100 / numDesiredArtifactsInARow;
		return (
			<section className='gallery-grid'>
				<StackGrid
					style={{ zIndex: 0 }}
					columnWidth={`${columnWidthPercent}%`}
					monitorImagesLoaded={true}
					gutterWidth={10}
					gutterHeight={10}
					gridRef={(grid) => (this.grid = grid)}
				>
					{this.renderPanes(artifacts)}
				</StackGrid>
			</section>
		);
	}
}

GalleryStack.propTypes = {
	artifacts: PropTypes.array.isRequired, // Each artifact has optional "selected" field (bool)
	selectableGrid: PropTypes.bool.isRequired,
	scale: PropTypes.number.isRequired,
	onToggleSelect: PropTypes.function,
	selectedArtifacts: PropTypes.array,
	disableClickArtifact: PropTypes.bool,
	hideMetadata: PropTypes.bool
};

export default GalleryStack;