import dotenv from "dotenv";

dotenv.config();

var GATEKEEPERURL;
if (process.env.NODE_ENV == 'production'){
	GATEKEEPERURL = 'https://kaleidoscope-gatekeeper.herokuapp.com/authenticate/';
} else if (process.env.NODE_ENV == 'development') {
	GATEKEEPERURL = 'https://cs160-documentation-tool.herokuapp.com/authenticate/';
}

export default class Github {

	static getUser(token, callback, context) {
		fetch(`https://api.github.com/user`, 
				{
				  headers: {
				    Authorization: `token ${token}`
				  }
				}
			 )
		    .then(res => res.json())
		    .then(
		        (result) => {
		        	callback(result, context)
		        },
		        (error) => {
		          alert("github fetch error")
		        }
		)
	}

	static getCommit(user, repo, id, callback, context) {
		fetch("https://api.github.com/repos/" + user + "/" + repo + "/commits/" + id)
		      .then(res => res.json())
		      .then(
		        (result) => {
		        	callback(result, context)
		        },
		        (error) => {
		          alert("github fetch error")
		        }
		)
	}


	static requestTokenViaGatekeeper(code, callback, context) {
		fetch(GATEKEEPERURL + code)
		      .then(res => res.json())
		      .then(
		        (result) => {
		        	console.log('result: ', result);
		        	console.log('result: ', result.token);
		        	callback(result, context)
		        },
		        (error) => {
		          alert("gatekeeper fetch error")
		        }
			)
	}

	static getRepos(token, pagenumber, repos, callback, context) {
		fetch(`https://api.github.com/user/repos?affiliation=owner,collaborator&page=${pagenumber}`, 
				{
				  headers: {
				    Authorization: `token ${token}`
				  }
				}
			 )
		    .then((res) => {		        	
				return res.json();
			})
		    .then(
		        (result) => {
		        	callback(result, repos, pagenumber, context)
		        },
		        (error) => {
		          alert("github fetch error")
		        }
		)
	}

	static getCommits(token, repo, owner, pagenumber, callback, context) {
		fetch(`https://api.github.com/repos/${owner}/${repo}/commits?page=${pagenumber}`, 
				{
				  headers: {
				    Authorization: `token ${token}`
				  }
				}
			 )
		    .then(res => res.json())
		    .then(
		        (result) => {
		        	callback(result, context)
		        },
		        (error) => {
		          alert("github fetch error")
		        }
		)
	}

}