import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Amplitude } from '@amplitude/react-amplitude';

import { UserContext } from '../context/UserContext';

import Main from '../layouts/Main';
import Database from '../scripts/Database';
import TemplateRequirement from '../components/TemplateRequirement';

class ReportCreator extends Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);
		this.state = {
			templateID: this.props.location.state ? this.props.location.state.templateID : null,
			templateData: { requirements: [], name: '' },
			artifactEntries: [],
			reportID: null,
			isTemplateLoaded: false
		};
	}

	getTemplate() {
		if (this.props.location.state && this.props.location.state.templateID) {
			Database.getDocument(
				'templates',
				this.state.templateID,
				(query, context) => {
					context.setState({
						templateData: query,
						isTemplateLoaded: true
					});
				},
				this
			);
		}
	}

	// Create and save the report itself
	saveReport = () => {
		// save
		var complete = this.state.templateData.requirements.length == this.state.artifactEntries.length;
		if (complete) {
			var artifactEntries = this.state.artifactEntries.map((entry) => {
				return { index: entry.index, artifactID: entry.artifact.id };
			});
			var data = {
				creator: this.context.user,
				team: this.props.location.state.selectedTeam.id,
				creationTime: Date.now(),
				type: 'report',
				template: this.state.templateID,
				artifactEntries: artifactEntries,
				annotations: '',
				tags: [ this.state.templateData.name ],
				with: []
			};
			Database.addDocument('artifacts', data, this.saveReportCallback, this);
			// this.saveReportCallback({id: 'blah'});
		} else {
			alert('Cannot save until all requirements are filled out.');
		}
	};

	// add new tags to all the subartifacts
	saveReportCallback = (docRef, context) => {
		console.log('SAVE CALLBACK');

		// add tags to report artifacts and save
		var promises = [];
		context.state.artifactEntries.map((entry) => {
			var reqtag = context.state.templateData.requirements[entry.index].tag;
			// save requirement tag, and template name
			Database.addValuesToArrayInDocument(
				'artifacts',
				entry.artifact.id,
				[ reqtag, context.state.templateData.name ],
				'tags',
				(res, context) => {
					console.log('goto id: ', docRef.id);
					context.props.history.push(`/artifact/${docRef.id}`);
				},
				context
			);
		});
	};

	updateArtifactList = (a, i) => {
		var newlist = this.state.artifactEntries.slice();
		var exists = false;

		// if there is already an entry for this index, update it
		newlist.map((entry) => {
			if (entry.index == i) {
				entry.artifact = a;
				exists = true;
			}
		});

		// if there wasn't an entry, make a new one
		if (!exists) {
			var newentry = { artifact: a, index: i };
			newlist = newlist.concat([ newentry ]);
		}

		// update state
		this.setState({
			artifactEntries: newlist
		});

		console.log(newlist);
	};

	render() {
		if (!this.state.isTemplateLoaded && !this.state.isTeamsLoaded) {
			this.getTemplate();
		}

		if (this.state.templateID) {
			return (
				<div>
				<Amplitude
					eventProperties={(inheritedProps) => ({
						...inheritedProps,
						scope: [ ...inheritedProps.scope, 'create-report' ],
						templateID : this.state.templateID
					})}
				>
					{({ logEvent }) => (
						<div>
							<div id='templateInfo'>
								<h1> {this.state.templateData.name} </h1>
								<h2> {this.props.location.state.selectedTeam.name} </h2>
								<button onClick={() => {this.saveReport(); logEvent('create report');}}> Save </button>
							</div>
							{this.state.templateData.requirements.map((req) => {
								return (
									<TemplateRequirement
										team={this.props.location.state.selectedTeam}
										requirement={req}
										editable={false}
										source={'report'}
										updateArtifactList={this.updateArtifactList}
									/>
								);
							})}
					</div>
				)}
				</Amplitude>
				</div>
			);
		} else {
			return (
				<div>
					You navigated here without a template ID! Please go to the assignments dashboard and select a
					template.
				</div>
			);
		}
	}
}

export default withRouter((props) => (
	<Main selectedSidebarItem='assignments'>
		<ReportCreator {...props} />
	</Main>
));
