import dotenv from "dotenv";

dotenv.config();

var AMPLITUDE_KEY;

if (process.env.NODE_ENV == "development") {
    // Test Project
    AMPLITUDE_KEY = '887da8f3bcc80a0b46f058e2a6cc26e7';
} else if (process.env.NODE_ENV == "production") {
    // Production Project
    AMPLITUDE_KEY = 'f919a63cc463c203565902f332593202';
}

export default AMPLITUDE_KEY;



