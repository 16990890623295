import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Amplitude } from '@amplitude/react-amplitude';

import Checkbox from '../components/Checkbox';
import Dropdown from '../components/Dropdown';
import GalleryGrid from '../components/GalleryGrid';
import GalleryStack from '../components/GalleryStack';
import TabbedButtons from '../components/TabbedButtons';
import TagSelectorDropdown from '../components/TagSelectorDropdown';
import Database from '../scripts/Database';
import { UserContext } from '../context/UserContext';
import { replaceValuesInListOfArtifacts, extractTags } from '../scripts/Utilities';

import { FilterList, ArrowDropDown, ArrowDropUp, Sort } from '@material-ui/icons';

import ArtifactTypes from '../data/ArtifactTypes';
import SortTypes, { sortTypeFromString } from '../data/SortTypes';
import testData from '../data/testData';
import Loading from './Loading';
import { isThisHour } from 'date-fns/esm';

// https://dev.to/clintdev/simple-firebase-image-uploader-display-with-reactjs-3aoo
const SupportedArtifactTypes = [
	ArtifactTypes.IMAGE,
	ArtifactTypes.TEXT,
	ArtifactTypes.COMMIT,
	ArtifactTypes.REPORT,
	ArtifactTypes.LINK,
	ArtifactTypes.VIDEO,
	ArtifactTypes.FIGMA
];

const sortTypesList = Object.values(SortTypes);

class Gallery extends Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);

		// set default values for filters
		var selectedTags = [];
		var types = SupportedArtifactTypes.map((type) => {
			return type.value;
		});
		var hasFeedbackOnly = false;
		var tagsOr = true;
		var showSubmittedOnly = false;
		var sort = SortTypes.NEWEST;
		var teamId;

		// Read in the URL query parameters
		if (this.props.readQueryParams) {
			var queryParams = this.props.readQueryParams(location.search);
			selectedTags = queryParams.tags === null ? selectedTags : queryParams.tags;
			tagsOr = queryParams.tagsOr === null ? tagsOr : queryParams.tagsOr;
			types = queryParams.types === null ? types : queryParams.types;
			hasFeedbackOnly = queryParams.feedback === null ? hasFeedbackOnly : queryParams.feedback;
			showSubmittedOnly = queryParams.submitted === null ? showSubmittedOnly : queryParams.submitted;
			sort = sortTypeFromString(queryParams.sort);
			teamId = queryParams.teamId;
		}

		// props should override URL query for types
		if (this.props.initialTypes) {
			types = this.props.initialTypes;
		}

		this.state = {
			queryParams: queryParams,
			artifacts: {},
			filterExpanded: true,
			filterTagsOr: tagsOr,
			selectedArtifactTypes: types,
			selectedTags: selectedTags,
			sort: sort,
			tags: {},
			zoomValue: 85,
			hasFeedbackOnly: hasFeedbackOnly,
			showSubmittedOnly: showSubmittedOnly,
			isDataLoaded: false,
			selectedTeam: null,
			teams: [],
			showAll: false,
			instructorMessage: '',
			initialTeamId: teamId, // FIXME: This might be better as a prop, if possible to pass it in that way.
			contributors: {},
			layoutData: {},
			tagDropdownKey: 0
		};
	}

	generateDefaultLayout = (artifacts) => {
		var layout = {};
		artifacts.map((artifact, i) => {
			layout[artifact.id] = {
				x: (i % 5) * 200,
			    y: Math.floor(i/5) * (200),
			    width: 180,
			    height: 180,
			    zIndex: 1,
			    hidden: false,
			};
		});
		return layout;		
	}

	getData() {
		// To load fake test data, uncomment the block below and comment out the other code in this function.
		/*this.setState({
			artifacts: testData
		});*/
		if (!this.state.isDataLoaded && this.context.user) {
			// If you're in a modal, your team is fixed:
			if (this.props.team) {
				Database.getDocumentsWhere(
					'artifacts',
					'team',
					'==',
					this.props.team.id,
					(artifacts, context) => {
						const teamID = context.props.team.id;
						context.setState({
							selectedTeam: context.props.team,
							artifacts: { [teamID]: artifacts },
							tags: extractTags(artifacts),
							isDataLoaded: true
						});
					},
					this
				);
				// if you're in the explore tab, get all public artifacts
			} else if (this.props.sourceIsExplore) {
				Database.getDocumentsWhere(
					'artifacts',
					'public',
					'==',
					true,
					(query, context) => {
						let result = [];
						var tags = [];
						query.forEach((doc) => {
							var id = doc.id;
							result.push({ id, ...doc });
							tags.push(...doc.tags);
						});

						var mapTags = {};
						tags.map((t) => {
							if (t in mapTags) {
								mapTags[t] += 1;
							} else {
								mapTags[t] = 1;
							}
						});

						context.setState({
							selectedTeam: { id: 'public' },
							artifacts: { public: result },
							tags: mapTags,
							isDataLoaded: true
						});
					},
					this
				);
				// If you're not in a modal, have to choose the team
			} else {
				if (this.context.user.role == 'instructor') {
					Database.getAllDocumentsOnce('teams', this.getTeamsCallback, this);
				} else {
					Database.getDocuments('teams', this.context.user.teams, this.getTeamsCallback, this);
				}
			}
		}
	}

	getTeamsCallback = (teams, context) => {
		teams.sort((t1, t2) => {
			// Alphabetically sorted.
			return t1.name.localeCompare(t2.name);
		});
		var selectedTeam = teams[0]; // takes 'most active' by default.
		
		// Handle team id passed through from querystring
		var { initialTeamId } = this.state;
		var foundInitialTeam = false;
		if (initialTeamId) {
			var foundTeam = teams.find(t => t.id === initialTeamId);
			if (foundTeam) {
				selectedTeam = foundTeam;
				foundInitialTeam = true;
			}
		}

		if (!foundInitialTeam && this.context.user.currentSelectedTeam) {
			var foundTeam = teams.find((t) => {
				return t.id === this.context.user.currentSelectedTeam.value;
			});
			if (foundTeam) {
				selectedTeam = foundTeam;
			}
		}

		if (teams && teams.length > 0) {
			Database.getDocumentsWhere(
				'artifacts',
				'team',
				'==',
				selectedTeam.id,
				(artifacts, context) => {
					const teamID = selectedTeam.id;
					context.setState({
						teams: teams,
						selectedTeam: selectedTeam,
						artifacts: { [teamID]: artifacts },
						tags: extractTags(artifacts),
						isDataLoaded: true,
						layoutData: this.generateDefaultLayout(artifacts)
					});
				},
				context
			);
			this.getContributorsFromTeam(selectedTeam);
		}
	};

	exportIds = () => {
		var ids = this.getFilteredArtifacts().map((a) => {
			return [ a.id, a.creator.name, a.team ];
		});

		const element = document.createElement('a');
		const file = new Blob([ ids.join('\n') ], { type: 'text/csv' });
		element.href = URL.createObjectURL(file);
		element.download = 'filtered_ids_' + Date.now() + '.csv';
		document.body.appendChild(element); // Required for this to work in FireFox
		element.click();
	};

	setToPublic = () => {
		var ids = this.getFilteredArtifacts().map((a) => a.id);
		replaceValuesInListOfArtifacts(ids, { public: true }, this, () => {
			this.setState({
				instructorMessage: 'artifacts set to public'
			});
		});
	};

	getArtifactsFromTeam = (team) => {
		Database.getDocumentsWhere(
			'artifacts',
			'team',
			'==',
			team.id,
			(artifacts, context) => {
				this.resetLayoutFunction(team, artifacts);
				context.setState({
					artifacts: { ...this.state.artifacts, [team.id]: artifacts },
					tags: extractTags(artifacts)
				});
			},
			this
		);
	};

	getContributorsFromTeam = (team) => {
		Database.getDocuments(
			'users',
			team.contributors,
			(users, context) => {
				context.setState({
					contributors: { ...this.state.contributors, [team.id]: users }
				});
			},
			this
		);
	}

	toggleShowAllArtifacts = () => {
		var calls = [];
		this.state.teams.map((team) => {
			if (!(team.id in this.state.artifacts)) {
				calls.push(
					new Promise((resolve, reject) => {
						resolve(this.getArtifactsFromTeam(team));
					})
				);
			}
		});

		// avoid setState collisions in the callback
		Promise.all(calls).then(() => {
			this.setState({
				showAll: !this.state.showAll
			});
		});
	};

	onChangeTeam(logEvent, selectedTeamId) {
		logEvent('change selected team', {
			newTeam: selectedTeamId,
			oldTeam: this.state.selectedTeam.id
		});
		var newSelectedTeam = this.state.teams.find((team) => {
			return selectedTeamId.value === team.id;
		});
		Database.replaceValuesInDocument('users', this.context.user.id, {
			currentSelectedTeam: selectedTeamId
		});

		this.setState({
			selectedTeam: newSelectedTeam,
			tags: extractTags(this.state.artifacts[newSelectedTeam.id]),
			initialTeamId: undefined, // Ignore initial team setting from here on out
		});

		// only happens if you haven't already downloaded the stuff
		if (!(newSelectedTeam.id in this.state.artifacts)) {
			this.getArtifactsFromTeam(newSelectedTeam);
			this.getContributorsFromTeam(newSelectedTeam);
		}

		// update querystring
		const { updateQueryParams } = this.props;
		if (updateQueryParams) {
			var newQP = this.state.queryParams;
			newQP['teamId'] = newSelectedTeam.id;
			updateQueryParams(newQP, this);
		}
	}

	saveLayoutToDb = (layoutData, artifacts, teamID, userId, name) => {
		var now = new Date();
		var newlayout = {
			layoutData: layoutData,
			visibleArtifacts: artifacts,
			timeCreated: now.toString(),
			type: 'studio',
			team: teamID,
			user: userId,
			name: name ? name : now.toString(),
			filters: {
				filterTagsOr: this.state.filterTagsOr,
				selectedArtifactTypes: this.state.selectedArtifactTypes,
				selectedTags: this.state.selectedTags,
				sort: this.state.sort,
				hasFeedbackOnly: this.state.hasFeedbackOnly,
				portfolio: false
			}
		};
		Database.addDocument('layouts', newlayout);
	}

	updateLayoutData = (newLayoutData) => {
	    this.setState({
	    	layoutData: newLayoutData
	    })
	 }

	 resetLayoutFunction = (team, artifacts) => {
	 	var team_id = this.state.selectedTeam.id;
	 	if (team) {
	 		team_id = team.id;
	 	}

	 	var as = this.state.artifacts[team_id];
	 	if (artifacts) {
	 		as = artifacts;
	 	}
	 	this.setState({
			layoutData: this.generateDefaultLayout(as),
			portfolio: false
		});

		this.selectAllTypes();
		this.updateSelectedTags([]);
		this.setSort(SortTypes.NEWEST);
		this.setTagFilterType('or');
		this.setHasFeedbackOnly(false);
	 }

	renderTeamImages() {
		return (
			<div className='team-images'> 
				{this.state.selectedTeam && this.state.contributors[this.state.selectedTeam.id] && !this.state.showAll ? 
					this.state.contributors[this.state.selectedTeam.id].map((user) => {
						return (
								<img
									key={user.id}
									className='studio-profile-image'
									src={user.imageURL}
									alt={`profile picture of ${user.name}`}
									title={user.name}
								/>
						);
					})
					: null
				}
			</div>
		);
	}

	render() {
		this.getData(); // Fetch and set artifacts and tags in state.
		const { filterExpanded, zoomValue } = this.state;
		let filteredArtifacts = this.getFilteredArtifacts();
		filteredArtifacts = this.sortArtifacts(filteredArtifacts);
		if (this.props.includeFauxArtifacts) {
			filteredArtifacts = this.addFauxArtifacts(filteredArtifacts);
		}
		const teamOptions = this.state.teams.map((team) => {
			return { label: team.name, value: team.id };
		});

		return (
			<div id='Gallery'>
				<Amplitude
					eventProperties={(inheritedProps) => ({
						...inheritedProps,
						scope: [ ...inheritedProps.scope, 'Gallery' ]
					})}
				>
					{({ logEvent }) => (
						<div>
							<div className='section-header'> 
								<div
									className='section-label-row'
									onClick={() => {
										this.toggleFilters();
										logEvent('toggle filter');
									}}
								>
									<div className='icon'>
										<FilterList />
									</div>
								</div>
								<div className='team-info'>
									{!this.props.team &&
									teamOptions.length > 0 && (
										<div className='team-select'>
												<Dropdown
													isSearchable
													isDisabled={this.state.showAll}
													options={teamOptions}
													defaultValue={{
														label: this.state.selectedTeam.name,
														value: this.state.selectedTeam.id
													}}
													onChange={(selectedTeamId) => this.onChangeTeam(logEvent, selectedTeamId)}
												/>										
											{this.context.user && this.context.user.role == 'instructor' ? (
												<Checkbox
													checkedByDefault={false}
													id={'show-all-artifacts'}
													label={'Show Artifacts from All Teams'}
													onChange={() => {
														this.toggleShowAllArtifacts();
														logEvent('toggle show artifacts from all teams');
													}}
												/>
											) : null}
										</div>
									)}
									{this.renderTeamImages()}
								</div>
								<div>
									<input
										type='range'
										min={1}
										max={100}
										defaultValue={zoomValue}
										id='zoom'
										onInput={this.scaleView}
										onBlur={() => {
											logEvent('zoom change', {
												newZoom: document.getElementById('zoom').value
											});
										}}
									/>
								</div>
							</div>

							{filterExpanded ? this.renderFilterView(logEvent) : null}

							{filteredArtifacts ? this.renderArtifacts(filteredArtifacts) : <Loading />}
						</div>
					)}
				</Amplitude>
			</div>
		);
	}

	renderArtifacts(filteredArtifacts) {
			return (
				<>
				{ filteredArtifacts.length === 0 ? 
					<p> <b>
						There are no artifacts matching those filter settings. Try filtering by some other conditions, or{' '}
						<Link to='/upload'>upload a new artifact.</Link>
					</b> </p>
					: null
				}

				{this.props.selectableGrid ? (
					<GalleryStack
						artifacts={filteredArtifacts.map((artifact) => ({
							...artifact,
							selected: this.props.preSelectedArtifactIds
								? this.props.preSelectedArtifactIds.includes(artifact.id)
								: false
						}))}
						scale={this.state.zoomValue}
						onToggleSelect={this.props.onToggleSelect}
						selectableGrid
						disableClickArtifact={this.props.disableClickArtifact}
						hideMetadata={this.state.zoomValue <= 70} />
				) : (
					<GalleryGrid
						artifacts={filteredArtifacts.map((artifact) => ({
							...artifact,
							selected: this.props.preSelectedArtifactIds
								? this.props.preSelectedArtifactIds.includes(artifact.id)
								: false
						}))}
						scale={this.state.zoomValue}
						onToggleSelect={this.props.onToggleSelect}
						selectableGrid
						disableClickArtifact={this.props.disableClickArtifact}
						hideMetadata={this.state.zoomValue <= 70}
						team={this.state.selectedTeam}
						user={this.context.user}
						save={this.saveLayoutToDb} 
						layoutData={this.state.layoutData}
						loadLayoutFunction={this.loadLayout}
						updateLayoutData={this.updateLayoutData} 
						resetLayoutFunction={this.resetLayoutFunction}
						source={'studio'}/>
				)}
				</>
			);
	}

	loadLayout = (layoutData, artifacts, filters) => {
		this.setSelectedArtifactTypes(filters && filters.selectedArtifactTypes ? filters.selectedArtifactTypes : this.state.selectedArtifactTypes);
		this.updateSelectedTags(filters && filters.selectedTags ? filters.selectedTags : this.state.selectedTags);
		this.setSort(filters && filters.sort ? filters.sort : this.state.sort);
		this.setTagFilterType(filters && filters.filterTagsOr ? filters.filterTagsOr : this.state.filterTagsOr);
		this.setHasFeedbackOnly(filters && filters.hasFeedbackOnly ? filters.hasFeedbackOnly : this.state.hasFeedbackOnly);
		
		this.setState({
			layoutData: layoutData,
			tagDropdownKey: this.state.tagDropdownKey + 1
		});
	}

	renderFilterView(logEvent) {
		const dateFilterOptions = [ 'Include', 'Exclude' ];
		const { user } = this.context;
		return (
			<div id='filter-pane'>
				<div className='filter-section'>
					<div className='filter-title'>
						<h4>Tags</h4>
						<TabbedButtons
							optionLabels={[ 'or', 'and' ]}
							onSelectOption={(selectedFilterType) => {
								this.setTagFilterType(selectedFilterType);
								logEvent('change or/and filter');
							}}
						/>
					</div>
					{this.renderTags(logEvent)}
				</div>

				<div className='filter-section'>
					<div className='filter-title'>
						<h4>Artifact Type</h4>
						<div className='filter-buttons'>
							<button className='smolButton' onClick={this.selectAllTypes}>Select All</button>
							<button className='smolButton' onClick={this.deselectAllTypes}>Clear All</button>
						</div>
					</div>
					<div className='filter-controls'>
						{SupportedArtifactTypes.map((artifactType) => (
							<Checkbox
								key={artifactType.value}
								checked={this.state.selectedArtifactTypes.includes(artifactType.value)}
								id={artifactType.value}
								label={artifactType.label}
								onChange={(id, isChecked) => {
									logEvent('change selected types', {
										oldSelected: this.state.selectedArtifactTypes,
										changeToggle: [id, isChecked]
									});
									this.toggleArtifactTypeCheckbox(id, isChecked);
								}}
							/>
						))}
					</div>
				</div>

				<div className='filter-section'>
					<div className='filter-title'>
						<h4>Has Feedback</h4>
					</div>
					<div className='filter-controls'>
						<Checkbox
							checked={this.state.hasFeedbackOnly}
							id={'has-feedback'}
							label={'Show Artifacts With Feedback Only'}
							onChange={() => {
								logEvent('toggle has feedback only');
								this.toggleNeedsFeedback();
							}}
						/>
					</div>
				</div>

				<div className='filter-section'>
					<div className='filter-title'>
						<h4>Sort By</h4>
					</div>
					<div className='filter-controls'>
						<div className='icon'>
							<Sort />
						</div>
						<div className='gallery-sort in-filters'>
							<Dropdown
								isSearchable
								options={sortTypesList}
								defaultValue={this.state.sort}
								onChange={sort => {
									logEvent('change sort', {
										oldSort: this.state.sort,
										newSort: sort,
									});
									this.setSort(sort);
								}}
							/>
						</div>
					</div>
				</div>

				{user && user.role == 'instructor' ? (
					<div className='instructor-stuff'>
						<div className='filter-section'>
							<div className='filter-title'>
								<h4>Submitted</h4>
							</div>
							<div className='filter-controls'>
								<Checkbox
									checkedByDefault={this.state.showSubmittedOnly}
									id={'submitted'}
									label={'Show Submitted Only'}
									onChange={() => {
										logEvent('toggle submitted only');
										this.toggleShowSubmitted();
									}}
								/>
							</div>
						</div>

						<div className='filter-section'>
							<div className='filter-title'>
								<h4>Export IDs and Creators to CSV</h4>
							</div>
							<button
								onClick={() => {
									logEvent('export IDs');
									this.exportIds();
								}}
							>
								{' '}
								Export IDs of Filtered Artifacts{' '}
							</button>
						</div>

						<div className='filter-section'>
							<div className='filter-title'>
								<h4>Set all Filtered Artifacts to Public</h4>
							</div>
							<button
								onClick={() => {
									logEvent('set to public');
									this.setToPublic();
								}}
							>
								{' '}
								Set to Public{' '}
							</button>
						</div>

						<div> {this.state.instructorMessage} </div>
					</div>
				) : (
					<div />
				)}
			</div>
		);
	}

	deselectAllTypes = () => {

		var newSelectedArtifactTypes = [];
		// clear all checkboxes.
		this.setState({ selectedArtifactTypes: [] });

		const { updateQueryParams } = this.props;
		if (updateQueryParams) {
			var newQP = this.state.queryParams;
			newQP['types'] = newSelectedArtifactTypes;
			updateQueryParams(newQP, this);
		}
	}

	selectAllTypes = () => {
		// select all other checkboxes.
		var newSelectedArtifactTypes = SupportedArtifactTypes.map((type) => {
				return type.value;
			});
		this.setState({ selectedArtifactTypes: newSelectedArtifactTypes });
		const { updateQueryParams } = this.props;
		if (updateQueryParams) {
			var newQP = this.state.queryParams;
			newQP['types'] = newSelectedArtifactTypes;
			updateQueryParams(newQP, this);
		}
	}

	toggleArtifactTypeCheckbox = (id, selected) => {
		const { selectedArtifactTypes } = this.state;
		var newSelectedArtifactTypes = null;
		if (selected) {
			newSelectedArtifactTypes = [ ...selectedArtifactTypes, id ];
			this.setState({ selectedArtifactTypes: newSelectedArtifactTypes });
		} else {
			newSelectedArtifactTypes = [ ...selectedArtifactTypes ];
			newSelectedArtifactTypes.splice(selectedArtifactTypes.indexOf(id), 1);
			this.setState({ selectedArtifactTypes: newSelectedArtifactTypes });
		}
		const { updateQueryParams } = this.props;
		if (updateQueryParams) {
			var newQP = this.state.queryParams;
			newQP['types'] = newSelectedArtifactTypes;
			updateQueryParams(newQP, this);
		}
	};

	setSelectedArtifactTypes = (newSelectedArtifactTypes) => {
		this.setState({ selectedArtifactTypes: newSelectedArtifactTypes });
		const { updateQueryParams } = this.props;
		if (updateQueryParams) {
			var newQP = this.state.queryParams;
			newQP['types'] = newSelectedArtifactTypes;
			updateQueryParams(newQP, this);
		}
	};

	setHasFeedbackOnly = (newFeedbackValue) => {
		const { updateQueryParams } = this.props;
		if (updateQueryParams) {
			var newQP = this.state.queryParams;
			newQP['feedback'] = newFeedbackValue.toString();
			updateQueryParams(newQP, this);
		}
		this.setState({ hasFeedbackOnly: newFeedbackValue });
	};

	toggleNeedsFeedback = () => {
		const newFeedbackValue = !this.state.hasFeedbackOnly;
		const { updateQueryParams } = this.props;
		if (updateQueryParams) {
			var newQP = this.state.queryParams;
			newQP['feedback'] = newFeedbackValue.toString();
			updateQueryParams(newQP, this);
		}

		this.setState({ hasFeedbackOnly: newFeedbackValue });
	};

	toggleShowSubmitted = () => {
		this.setState({
			showSubmittedOnly: !this.state.showSubmittedOnly
		});

		const { updateQueryParams } = this.props;
		if (updateQueryParams) {
			var newQP = this.state.queryParams;
			newQP['submitted'] = (!this.state.showSubmittedOnly).toString();
			updateQueryParams(newQP, this);
		}
	};

	setSort = (sort) => {
		this.setState({ sort });

		const { updateQueryParams } = this.props;
		if (updateQueryParams) {
			var newQP = this.state.queryParams;
			if (sort.value) {
				newQP['sort'] = sort.value;
			} else {
				delete newQP['sort'];
			}
			updateQueryParams(newQP, this);
		}
	};

	renderTags(logEvent) {
		const tagOptions = this.state.tags ? Object.keys(this.state.tags) : [];
		return (
			<TagSelectorDropdown
				isClearable
				defaultValue={this.state.selectedTags}
				options={tagOptions}
				onChange={(selectedTags) => {
					logEvent('change selected tags', 
						{newSelectedTags: selectedTags}
					); 
					this.updateSelectedTags(selectedTags);
				}}
				prompt={'Select tags to filter the artifacts.'}
				key={'tag-dropdown-' + this.state.tagDropdownKey}
				menuPortalTarget={document.getElementById('filter-pane')} />
		);
	}

	updateSelectedTags = (selectedTags) => {
		const { updateQueryParams } = this.props;
		if (updateQueryParams) {
			var newQP = this.state.queryParams;
			newQP['tags'] = selectedTags;
			updateQueryParams(newQP, this);
		}
		this.setState({ selectedTags: selectedTags });
	};

	checkArtifactFilterByTag(artifact) {
		/*
		 * Returns whether given artifact satisfies the current tag filter.
		 */
		const { selectedTags, filterTagsOr } = this.state;
		if (selectedTags.length === 0) {
			return true; // if the user is not filtering by any tags
		} else {
			if (!artifact.tags) {
				return false; // Some artifacts don't have defined tags in our test table.
			}
			const tagFilter = (tag) => {
				return artifact.tags.includes(tag);
			};
			if (filterTagsOr) {
				return selectedTags.some(tagFilter);
			} else {
				return selectedTags.every(tagFilter);
			}
		}
	}

	checkArtifactFilterByType(artifact) {
		return this.state.selectedArtifactTypes.includes(artifact.type);
	}

	getFilteredArtifacts() {
		/*
		 * Returns an array of artifact objects that match the selected date and tag
		 * filters set by the user.
		 */

		const { isDataLoaded, artifacts, showAll, selectedTeam, teams } = this.state;

		if (!isDataLoaded) {
			return null;
		}

		var curArtifacts = [];

		if (showAll) {
			teams.map((team) => {
				if (artifacts[team.id]) {
					curArtifacts.push(...artifacts[team.id]);
				}
			});
		} else {
			curArtifacts = artifacts[selectedTeam.id];
		}

		if (curArtifacts) {
			const result = curArtifacts.filter((artifact) => {
				// Filter out hidden artifacts
				if (this.props.hiddenArtifactIds && this.props.hiddenArtifactIds.includes(artifact.id)) {
					return false;
				}

				// Filter by type
				if (!this.checkArtifactFilterByType(artifact)) {
					return false;
				}

				// Filter by tag
				if (!this.checkArtifactFilterByTag(artifact)) {
					return false;
				}

				if (this.state.hasFeedbackOnly) {
					if (!artifact.feedback) {
						return false;
					}
				}

				if (this.state.showSubmittedOnly) {
					if (!artifact.submitted) {
						return false;
					}
				}

				return true;
			});
			return result;
		}

		return [];
	}

	sortArtifacts = (artifacts) => {
		if (!artifacts) return artifacts;
		const { sort } = this.state;
		switch (sort.value) {
			case SortTypes.AUTHOR.value:
				return artifacts.sort((a,b) => {
					return a.creator.name.localeCompare(b.creator.name);
				});
			case SortTypes.NEWEST.value:
				return artifacts.sort((a,b) => b.creationTime - a.creationTime);
			case SortTypes.OLDEST.value:
				return artifacts.sort((a,b) => a.creationTime - b.creationTime);
			default:
				return artifacts;
		}
	};

	addFauxArtifacts = (artifacts) => {
		if (artifacts == null) {
			return artifacts;
		}
		const {selectedTeam: team} = this.state;
		let fauxArtifacts = [];
		if (team) {
			const fauxTeam = [{
				id: `faux-team-${Date.now()}`,
				type: 'faux-team',
				teamId: team.id,
				creator: {
					name: 'System'
				},
				creationTime: Date.now()
			}];
			fauxArtifacts = fauxArtifacts.concat(fauxTeam);
		}
		return fauxArtifacts.concat(artifacts);
	};

	handleClickTag = (tag) => {
		/*
		 * Toggles selection of `tag` by adding or removing it from the
		 * selectedTags list in state.
		 */
		const { selectedTags } = this.state;
		if (selectedTags.includes(tag)) {
			const newSelectedTags = [ ...selectedTags ];
			newSelectedTags.splice(selectedTags.indexOf(tag), 1); // Mutates to remove tag in place
			this.setState({ selectedTags: newSelectedTags });
		} else {
			this.setState({ selectedTags: [ tag, ...selectedTags ] });
		}
	};

	scaleView = () => {
		var value = document.getElementById('zoom').value;
		this.setState({ zoomValue: value });
	};

	setTagFilterType = (selectedFilterType) => {
		var result; 

		if ((typeof selectedFilterType) === 'boolean') {
			result = selectedFilterType;
		} else {
			result = selectedFilterType === 'or';
		}

		this.setState({ filterTagsOr: result });

		const { updateQueryParams } = this.props;
		if (updateQueryParams) {
			var newQP = this.state.queryParams;
			newQP['tagsOr'] = (result).toString();
			updateQueryParams(newQP, this);
		}
	};

	toggleFilters = () => {
		this.setState({ filterExpanded: !this.state.filterExpanded });
	};
}

Gallery.propTypes = {
	team: PropTypes.object,
	initialTypes: PropTypes.object,
	onToggleSelect: PropTypes.func,
	sourceIsExplore: PropTypes.bool,
	disableClickArtifact: PropTypes.bool,
	preSelectedArtifactIds: PropTypes.array,
	updateQueryParams: PropTypes.func,
	hiddenArtifactIds: PropTypes.array
};

export default withRouter(Gallery);
