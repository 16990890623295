// TODO: 
// update query params should take in an object formatted properly
// that object should be stored on the state in gallery
// every time a filter is updated, update the object, send it to updateQueryParams, then update the state

export function updateQueryParams(data, context) {
	var newParams = {};
	if (data) {
		for (const field in data) {
			if (data[field] && data[field].length) {
				newParams[field] = data[field];
			}
		}
		context.props.history.push({
			search: `?${new URLSearchParams(newParams).toString()}`
		});
	} else {
		context.props.history.push({
			search: ''
		});
	}
}

export function readQueryParams(query) {
	const searchParams = new URLSearchParams(query);

	const tagsString = searchParams.get('tags');
	var tags = null;
	if (tagsString != null) {
		// Commas are filtered out of tags by the TagSelectorDropdown, so this is okay.
		tags = tagsString.split(',');
	}

	const tagsOrString = searchParams.get('tagsOr');
	console.log('params: ', searchParams.get('tagsOr'));
	var tagsOr = null;
	if (tagsOrString === 'true') {
		tagsOr = true;
	} else if (tagsOrString == 'false') {
		tagsOr = false;
	}

	const typesString = searchParams.get('types');
	console.log('params: ', searchParams.get('types'));

	var types = null;
	if (typesString != null) {
		types = typesString.split(',');
	}

	const feedbackString = searchParams.get('feedback');
	var feedback = null;
	if (feedbackString === 'true') {
		feedback = true;
	} else if (feedbackString === 'false') {
		feedback = false;
	}

	const submittedString = searchParams.get('submitted');
	var submitted = null;
	if (submittedString === 'true') {
		submitted = true;
	} else if (submittedString === 'false') {
		submitted = false;
	}

	const sort = searchParams.get('sort');

	const teamId = searchParams.get('teamId');

	return { 
		tags: tags,
		tagsOr: tagsOr,
		types: types,
		feedback: feedback,
		submitted: submitted,
		sort,
		teamId,
	};
}