import Database from './Database';

export function makeTeam(teamText) {
	var userMap = {};
	Database.getAllDocumentsOnce('users', (result, context) => {
		result.map((user) => {
			userMap[user.email] = user;
		});
		teamText.split('\n\n').map((group) => {
			var teamInputData = group.split('\n');
			var teamName = teamInputData.shift();
			console.log(teamName);
			Database.addDocument(
				'teams',
				{
					name: teamName,
					contributors: teamInputData.map((email) => userMap[email].id),
					timeCreated: new Date()
				},
				(teamData, context) => {
					console.log(`Created Team ${teamName} with team ID ${teamData.id}`);
					teamInputData.map((email) => {
						// Update each student user's data with this new Team ID
						Database.addValuesToArrayInDocument(
							'users',
							userMap[email].id,
							[ teamData.id ],
							'teams',
							(result, context) => {
								// maybe set shouldRedirect to true here, instead.
								console.log(
									`Added ${email} (id ${userMap[email].id}) to Group ${teamName} (id ${teamData.id})`
								);
							},
							context
						);
					});
				},
				context
			);
		});
	});
}
