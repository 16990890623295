import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class SidebarItem extends Component {
	render() {
		const { icon, link, path, text, selected } = this.props;
		if (link) {
			return (
				<a href={link} target='_blank' onClick={this.props.onClick}>
					<div className='sidebar-nav-item' id={selected ? 'selected' : null}>
						<span className='icon'>{icon}</span>
						{!this.props.collapsed && <p className='label'>{text}</p>}
					</div>
				</a>
			);
		} else {
			return (
				<Link to={path} onClick={this.props.onClick}>
					<div className='sidebar-nav-item' title={text} id={selected ? 'selected' : null}>
						<span className='icon'>{icon}</span>
						{!this.props.collapsed && <p className='label'>{text}</p>}
					</div>
				</Link>
			);
		}
	}
}

SidebarItem.propTypes = {
	icon: PropTypes.object.isRequired,
	text: PropTypes.string.isRequired,
	path: PropTypes.string.isRequired,
	selected: PropTypes.bool.isRequired,
	collapsed: PropTypes.bool.isRequired
};

export default SidebarItem;
