import React, { Component } from 'react';


class LinkArtifact extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div>
				{ this.props.artifact.linkURL 
					?
						<a className='artifact-external-link' href={this.props.artifact.linkURL} style={this.props.style}> 
							{this.props.artifact.linkURL} 
						</a>
					: 
						null // this should..........never happen.
				}
			</div>
		);
	}
}

LinkArtifact.propTypes = {
	artifact: PropTypes.object.isRequired,
	style: PropTypes.style
};

export default LinkArtifact; 