import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { withRouter } from 'react-router-dom';
import { Amplitude } from '@amplitude/react-amplitude';

import { UserContext } from '../context/UserContext';

import AssignmentEntry from '../layouts/AssignmentEntry';
import Database from '../scripts/Database';
import Dropdown from '../components/Dropdown';
import GalleryStack from '../components/GalleryStack';


class StudentAssignments extends Component {

	static contextType = UserContext;
	
	constructor(props) {
		super(props);
		this.state = {
			templates: [],
			reports: [],
			isDataLoaded: false,
			teams: [],
			selectedTeam: null,
			isTeamsLoaded: false
		};
	}

	templatesCallback(qs, context) {
		var list = [];
		qs.forEach((doc) => {
			if (doc.data().published) {
				var data = doc.data();
				data.id = doc.id;
				list.push(data);
			}
		});
		context.setState({ 
			templates: list,
			isDataLoaded: true //have to set this in both callbacks or get in a long loop of re-fetching from db
		});

		var conditions = [
			{
				field: 'type',
				test: '==',
				condition: 'report'
			}, {
				field: 'team',
				test: 'in',
				condition: context.context.user.teams
			}
		];

		Database.getDocumentsWhereSnapshot('artifacts', conditions, context.reportsCallback, context);
	}

	getTeams() {
		if (this.context.user && !this.state.teams.length) {
			Database.getDocuments(
				'teams',
				this.context.user.teams,
				(teams, context) => {
					if (teams) {
						teams.sort((t1, t2) => {
							// Alphabetical sorting
							return t1.name.localeCompare(t2.name);
						});
						var selectedTeam = teams[0]; // takes 'most active' by default.
						if(this.context.user.currentSelectedTeam){ 
							var foundTeam = teams.find( (t) => {return t.id===this.context.user.currentSelectedTeam.value} );
							if (foundTeam) {
								selectedTeam = foundTeam;
							}
						}
						context.setState({
							teams: teams,
							selectedTeam: selectedTeam,
							isTeamsLoaded: true
						});
					}
				},
				this
			);
		}
	}

	reportsCallback(list, context) {
		list.sort((a, b) => (a.creationTime < b.creationTime ? 1 : -1));
		context.setState({ 
			reports: list,
			isDataLoaded: true //have to set this in both callbacks or get in a long loop of re-fetching from db
		});
	}

	renderPreviousCheckins(t) {
		var filteredList = this.state.reports.filter((report) => report.template === t.id && report.team === this.state.selectedTeam.id); 
		if (filteredList.length) {
			return (
				<GalleryStack
					artifacts={filteredList}
					scale={90}
					selectableGrid={false}
					hideMetadata={this.state.zoomValue <= 70}
				/>
			);
		} else {
			return (<div>No Check-in Artifacts for this template.</div>);
		}
	}

	goToReportCreator = (t) => {
		this.props.history.push({
			pathname: '/create-report/' + t.id,
			state: {
				templateID: t.id,
				selectedTeam: this.state.selectedTeam
			}
		});
	}

	render = () => {
		if (!this.state.isDataLoaded) {			
			Database.getAllDocumentsSnapshot('templates', this.templatesCallback, this);
		}
		if (!this.state.isTeamsLoaded) {
			this.getTeams(); 
		}

		var teamOptions = this.state.teams.map((team) => {
			return { label: team.name, value: team.id };
		});
		
		var defaultValue = null;
		if (this.state.selectedTeam) {
			defaultValue = { label: this.state.selectedTeam.name, value: this.state.selectedTeam.id };
		}

		return (
			<Amplitude
				eventProperties={(inheritedProps) => ({
					...inheritedProps,
					scope: [ ...inheritedProps.scope, 'student-assignments-page' ]
				})}
			>
			<div>
				<h1> Check-Ins </h1>
				<div><i> Fill out templates with artifacts from your studio to create check-ins for sharing with your team or instructors </i></div> 
				{this.state.selectedTeam &&
					teamOptions.length > 0 && (
				<div className='dropdownContainer'>
					<h2> Select your team: </h2>
					<Dropdown
						options={teamOptions}
						defaultValue={defaultValue}
						onChange={(selectedTeamId) => {
							Database.replaceValuesInDocument(
								'users',
								this.context.user.id,
								{'currentSelectedTeam': selectedTeamId}
							);
							this.setState({
								selectedTeam: this.state.teams.find((team) => {
									return selectedTeamId.value === team.id;
								})
							});
						}}
						style={{width: 'auto'}}
					/>
				</div>)}
				{this.state.selectedTeam && this.state.templates.map((t) => {
					return (
						<div className='assignmententry'>
							<h2 className='entry-name'> {t.name} </h2>
							<div className='reportsFromTemplate'>
								<h4> Existing check-ins from this template </h4>
								{this.renderPreviousCheckins(t)}
							</div>
							<button onClick={() => this.goToReportCreator(t)}> Create Check-in Artifact </button>
						</div>
					);
				})}
			</div>
			</Amplitude>
		);
	};
}


export default withRouter(StudentAssignments);
